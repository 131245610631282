import { React} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NoImage from "../PrivateBrandCatalog/assets/NoImage.png";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack } from "@mui/material";
import getItemHierarchy from "../../../Utils/GetItemHierarchy";
import { useSelector } from "react-redux";
import { trackMixPanelEvent } from "../../../mixpanel/mixpanel";
import { getImageUrl } from "../../../Utils/CommonUtils";

const ItemDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 14rem;

  .MuiButton-startIcon{
    margin-right: 2px;
    margin-left: 0px;
  }
  .MuiButton-outlined{
    margin: 0.5rem 0.5rem 0 0;
    color: #34a07c; 
    width: auto;
    font-size: 0.75rem !important;
    align-items: center;
  }
  .add-btn{
    color: #34a07c;
    border: 1px solid #34a07c;
    :hover{
      border: 1px solid #34a07c;
    }
  }
  .rmv-btn{
    color: #EF5350;
    border: 1px solid #EF5350;
    :hover{
      border: 1px solid #EF5350;
    }
  }

  @media (max-width: 1200px) {
    width: 11rem;
  }
  @media (max-width: 1000px) {
    width: 9rem;
  }
  @media (max-width: 770px) {
    width: 14rem;
  }
`;
const ItemCard = styled.div`
  display: flex;
  flex-direction: row;
  /* -webkit-box-pack: start; */
  justify-content: center;
  /* -webkit-box-align: center; */
  align-items: center;
  /* padding: 1rem 1.5rem; */
  background-color: white;
  /* border: 0.3px solid rgba(144, 144, 144, 0.3); */
  border-radius: 0.25rem;
  /* box-shadow: rgba(135, 134, 134, 0.35) 0px 3px 3px; */
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px; */
  cursor: pointer;
`;
const SubCatCardImg = styled.img`
  max-width: 90%;
  width: auto;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10% 5%;
`;
const ItemNameContainer = styled.div`
  margin-top: 0.7rem;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  min-height: 3rem;
  font-weight: 500;
  cursor: pointer;
`;

const ProductCard = (props) => {
    const navigate= useNavigate();

    const { isAdmin=false } = useSelector(state => state.userManagement);
    
    const {
      item,
      itemIsAdded,
      handleUnpublishBtnClick,
      handlePdtToList
    } = props;

    const handleCardClick = (item) => {
       const [encodedPSA,encodedSubcat,encodedCountry]= getItemHierarchy(item);
       navigate(`/privateBrandCatalog/${encodedCountry}/${encodedPSA}/${encodedSubcat}/${item.itemOriginID}`);
    };

    return (
        <ItemDetailContainer
          key={item.itemOriginID}
        >
            <ItemCard 
              onClick={() => {
                handleCardClick(item);
              }}
            >
               <SubCatCardImg
                alt="img"
                src={getImageUrl(item?.imageUrl?.[0])}
              >
              </SubCatCardImg>
            </ItemCard>
            <ItemNameContainer
              onClick={() => {
                handleCardClick(item);
              }}
            >
              {item.shortDescription}
            </ItemNameContainer>
            <Stack direction={"row"} justifyContent={"flex-start"} spacing={2} sx={{width:"100%"}}>
              {isAdmin && 
                  <Button variant="outlined" size="small" className="rmv-btn" onClick={()=>{handleUnpublishBtnClick(item.itemOriginID)}}>
                    Unpublish
                  </Button> 
               }
                <Button 
                  variant="outlined" startIcon={itemIsAdded? <RemoveIcon/>:<AddIcon />} size="small" className={itemIsAdded? "rmv-btn" : "add-btn"} 
                  onClick={()=>{
                    handlePdtToList(item.itemOriginID,itemIsAdded)
                    if(!itemIsAdded){
                      trackMixPanelEvent({module: 'PRIVATE_BRAND_CATALOG', action: 'ADD_TO_LIST', metadata: {itemId: item.itemOriginID}})
                    }
                  }}
                >
                  {itemIsAdded ? 'Remove' : 'Add To List'}
                </Button>
            </Stack>
        </ItemDetailContainer>
    );
 }
 
 export default ProductCard;