import React, { useEffect, useRef, useState, useContext } from "react";
import { Avatar } from "@mui/material";
import styled from "styled-components";
import { AiOutlineLogout, AiOutlineDelete } from "react-icons/ai";
import { MdOutlineCloudDone } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import { MdOutlineDownloadDone } from "react-icons/md";
import { Link } from "react-router-dom";
import CircularRotatingLines from "../../Utils/CircularLoader";
import { UserContext } from "../../App";
import CountryDropdown from "../../Utils/CountryDropdown";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { resValidation } from "../../Utils/CommonUtils";
import { add_file, edit_user, get_user } from "../../config/environment";
import { useDispatch } from "react-redux";
import { setUserAccess, setUserCountryAndCompany, setUserAsAdmin, setUserFullName} from "../Assets/UserManagement/UserManagementSlice";
import { checkExpiryAndRedirect } from "../../Utils/CommonUtils";
import PersonIcon from "@mui/icons-material/Person";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomBtn = styled.button`
  width: 2.8rem;
  padding: 0.1rem 0rem;
  height: 1.2rem;
  border-radius: 2rem;
  font-size: 0.6rem;
  cursor: ${(props) =>
    props.wrongInputName || props.wrongInputAbout || props.wrongInputCompany
      ? ""
      : "pointer"};
  height: 2.5rem;
  position: relative;
  text-align: center;
`;

const LogoutBtn = styled(CustomBtn)`
  font-size: 15px;
  width: 6rem;
  border: 1px solid #d2d2d2;
  background-color: #f6f6f6;
  color: black;
  box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 0 1rem 0;
`;

const EditProfileBtn = styled(CustomBtn)`
  font-size: 15px;
  width: 6rem;
  border: ${(props) =>
    props.disabled
      ? "1px solid #b2b2b2"
      : "1px solid #147350"};
  background-color: ${(props) => (props.disabled ? "#b2b2b2" : "#147350")};
  color: white;
  box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
  border-radius: 5px;
  margin: 0 0 1rem 0;
`;

const ReadMoreSpan = styled.div`
  display: inline-block;
  color: #215bbe;
  cursor: pointer;
`;

const PostTextComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1rem;
  padding-right: 0.7rem;
  margin-bottom: 1rem;
  word-break: break-word;
  white-space: pre-line;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 16px;
  padding-top: 2px;
  max-width: 40rem;
  width: 60%;
  max-height: 35rem;
  height: 90%;
  overflow-y: scroll;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
`;

const AvatarPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const PostDetailComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem;
  padding-bottom: 1rem;
  width: 90%;
  max-height: 23rem;
  height: auto;
`;

const HeadingContainer1 = styled.div`
  margin: 1rem 0 0.5rem 0.7rem;
  font-weight: bold;
  font-size: 22px;
  color: #595555;
`;

const ModalSmallInput = styled.textarea`
  width: 95%;
  border: ${(props) =>
    (props.wrongInputName || props.wrongInputCompany)
      ? "1px solid #ea1b2c"
      : "1px solid #b5b5b5"};
  border-radius: 8px;
  padding: 0.8rem;
  resize: none;
  max-height: 1rem;
  color: grey;
  height: 7rem;
  &: focus-visible {
    outline-width: 0;
  }
`;

const ModalLargeInput = styled.textarea`
  width: 95%;
  border: ${(props) =>
    props.wrongInputAbout ? "1px solid #ea1b2c" : "1px solid #b5b5b5"};
  border-radius: 8px;
  padding: 0.8rem;
  resize: none;
  max-height: 100%;
  min-height: 7rem;
  color: grey;
  height: 7rem;
  &: focus-visible {
    outline-width: 0;
  }
`;

const countryList = [
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Hawaii",
    label: "Hawaii",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Laos",
    label: "Laos",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "South Korea",
    label: "South Korea",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "USA",
    label: "USA",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
];

var axios = require("axios");
var qs = require("qs");

const UserProfile = (props) => {
  let username = localStorage.getItem("username");

  const [profileOpen, setProfileOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [readMoreBig, setReadMoreBig] = useState(true);
  const [usernameEdit, setUsernameEdit] = useState(false);
  const [aboutEdit, setAboutEdit] = useState(false);
  const [companyEdit, setCompanyEdit] = useState(false);
  const [countryEdit, setCountryEdit] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [oldProfilePic, setOldProfilePic] = useState(null);
  const [newFullname, setNewFullname] = useState(""); 
  const [newuserEmail, setNewuserEmail] = useState("");
  const [newAbout, setNewAbout] = useState("");
  const [newCompany, setNewCompany] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [curFullname, setCurFullname] = useState(username); 
  const [curUserEmail, setCurUserEmail] = useState(username);
  const [curUsername, setCurUsername] = useState(username);
  const [about, setAbout] = useState("");
  const [curCompany, setCurCompany] = useState("");
  const [curCountry, setCurCountry] = useState("");
  const [curProfilePic, setCurProfilePic] = useState("");
  const [displayPic, setDisplayPic] = useState("");
  const [toggleDisplayPic, setToggleDisplayPic] = useState("");
  const [removed, setRemoved] = useState(false);
  const [wrongFormat, setWrongFormat] = useState(false);
  const [wrongInputName, setWrongInputName] = useState(false);
  const [wrongInputAbout, setWrongInputAbout] = useState(false);
  const [wrongInputCompany, setWrongInputCompany] = useState(false);
  const [wrongInputDialog, setWrongInputDialog] = useState(false);
  const [edited, setEdited] = useState(false);
  const debounceTimeoutRef = useRef(null);

  const { state } = useContext(UserContext);
  const dispatch = useDispatch();

  const fileInputRef = useRef();
  var specialCharCheck = /[!@#$%^&*_()+\=\[\]{};':"\\|,<>\/?]+/;
  var specialCharCheckName = /[!@#$%^&*_+\=\[\]{};':"\\|,<>\/?]+/;
  var MAX_CHAR_LIMIT = 50;
  const checkAccessToken = localStorage.getItem("access_token");

  const DialogPopup = (props) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={wrongInputDialog}
        autoHideDuration={3000}
        onClose={() => {
          setWrongInputDialog(false);
        }}
        key={"top" + "right"}
      >
        <Alert
          onClose={() => {
            setWrongInputDialog(false);
          }}
          severity={props.submit ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {props.dialogContentText}
        </Alert>
      </Snackbar>
    );
  };

  const UserNameProcessing = (username = "user") => {
    username = username?.trim();
    let wordsArray;
    if (username?.includes(" ")) {
      wordsArray = username?.split(" ");
    } else {
      wordsArray = [username];
    }
    let outputString = "";
    for (let i = 0; i < wordsArray.length; i++) {
      let userNamePart =
        wordsArray[i][0]?.toUpperCase() + wordsArray[i]?.slice(1).toLowerCase();
      outputString = outputString + " " + userNamePart;
    }
    return outputString;
  };

  const toggleEdit = () => {
    setEditModal((editModal) => !editModal);
  };

  const toggleDetails = () => {
    setUsernameEdit(false);
    setAboutEdit(false);
    setCompanyEdit(false);
    setCountryEdit(false);
  };

  const toggleSelected = () => {
    setNewuserEmail("")
    setNewFullname("")
    setNewProfilePic("");
    setNewCountry("");
    setNewCompany("");
    setNewAbout("");
  };

  const readMoreHandler = () => {
    setReadMore((readMore) => !readMore);
  };

  const readMoreBigHandler = () => {
    setReadMoreBig((readMoreBig) => !readMoreBig);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name.includes(" ")
          ? name?.split(" ")[0][0] + "" + name?.split(" ")[1][0]
          : name[0][0]
      }`,
    };
  }

  useEffect(() => {
    if (checkAccessToken && state) {
      getUserDetails();
    }
  }, [state]);

  const trimFileName = (fileName) => {
    const index = fileName.lastIndexOf('.');
    return fileName.substring(0, index).replace(/[^a-zA-Z0-9]/g, "_") + fileName.substring(index);
  };

  const editProfile = async () => {
    const isTokenActive = checkExpiryAndRedirect();
		if (!isTokenActive) {
			return;
		}
    setApiCalled(true);
    let myHeaders = new Headers();
    let resStatus, key;
    const fileName = trimFileName(newProfilePic[0].name); //newProfilePic[0].name.replace(/[^a-zA-Z0-9.]/g, "_");
    key = "7in_user/" + fileName;
    myHeaders.append("key", key);
    myHeaders.append("filetype", newProfilePic[0].type);
    try {
      if (oldProfilePic) {
        var data = qs.stringify({
          delete: oldProfilePic,
          profile: "profile",
          accessToken: checkAccessToken,
        });
        var config = {
          method: "post",
          url: add_file,
          headers: {
            key: key,
            filetype: newProfilePic[0].type,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            axios
              .request({
                method: "put",
                url: `${response.data.body.message}`,
                headers: {
                  "Content-Type": newProfilePic[0].type,
                },
                transformRequest: (data, headers) => {
                  delete headers.common['Authorization'];
                  return data;
                },
                data: newProfilePic[0],
              })
              .then(function (response) {
                updateUser();
              })
              .catch(function (error) {
                setApiCalled(false);
              });
          })
          .catch(function (error) {
            setApiCalled(false);
          });
      } else {
        const resp = await axios.request({
          method: "post",
          url: add_file,
          headers: {
            fileType: newProfilePic[0].type,
            key: key,
          },
          data: qs.stringify({
            accessToken: checkAccessToken,
          }),
        });

        axios
          .request({
            method: "put",
            url: `${resp.data.body.message}`,
            headers: {
              "Content-Type": newProfilePic[0].type,
            },
            transformRequest: (data, headers) => {
              delete headers.common['Authorization'];
              return data;
            },
            data: newProfilePic[0],
          })
          .then(function (response) {
            updateUser();
          })
          .catch(function (error) {
            setApiCalled(false);
          });
      }
    } catch (err) {}
  };

  const updateUser = () => {
    const isTokenActive = checkExpiryAndRedirect();
		if (!isTokenActive) {
			return;
		}
    setApiCalled(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    // var urlencoded = new URLSearchParams();
    // urlencoded.append(
    //   "userName",
    //   newUsername ? newUsername.trim() : curUsername.trim()
    // );
    // urlencoded.append("userAbout", newAbout ? newAbout : about);
    // urlencoded.append("company", newCompany ? newCompany : curCompany);
    // urlencoded.append("country", newCountry ? newCountry : curCountry);
    // urlencoded.append(
    //   "profilePicKey",
    //   newProfilePic
    //     ? `7in_user/${newProfilePic[0].name.replace(/[^a-zA-Z0-9.]/g, "_")}`
    //     : removed
    //     ? ""
    //     : curProfilePic
    // );
    // urlencoded.append("accessToken", checkAccessToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: qs.stringify({
        fullName: newFullname ? newFullname.trim() : curFullname.trim(),
        userEmail: newuserEmail ? newuserEmail.trim() : curUserEmail.trim(),
        userAbout: newAbout ? newAbout : about,
        company: newCompany ? newCompany : curCompany,
        country: newCountry ? newCountry : curCountry,
        profilePicKey: newProfilePic ? `7in_user/${trimFileName(newProfilePic[0].name)}` : removed ? "" : curProfilePic,
        accessToken: checkAccessToken
      }),
      redirect: "follow",
    };

    fetch(edit_user, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setApiCalled(false);
        getUserDetails();
        setEditModal(false);
      })
      .catch((error) => {
        setApiCalled(false);
        setEditModal(false);
        setProfileOpen(false);
      });
  };

  const getUserDetails = () => {
    setApiCalled(true);
    var data = qs.stringify({
      accessToken: checkAccessToken,
    });

    var config = {
      method: "post",
      url: get_user,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      setDisplayPic,
    };

    axios(config)
      .then(function (response) {
        let validatedRes = resValidation(response),
        respMsg = validatedRes.data.body.message;
        if(validatedRes) {
          setCurUsername(respMsg.userName);
          setCurFullname(respMsg.fullName);
          setCurUserEmail(respMsg.userEmail);
          setAbout(respMsg.userAbout);
          setCurCompany(respMsg.company);
          setCurCountry(respMsg.country);
          setOldProfilePic(respMsg.profilePicKey);
          setCurProfilePic(respMsg.profilePicKey);
          if (respMsg.profilePic) {
            setDisplayPic(respMsg.profilePic);
            setToggleDisplayPic(respMsg.profilePic);
          }
          dispatch(setUserCountryAndCompany({
            userCountry: respMsg.country,
            userCompany: respMsg.company
          }));
          dispatch(setUserAccess(respMsg.accessControl));
          dispatch(setUserAsAdmin(respMsg.isAdmin));
          dispatch(setUserFullName(respMsg.fullName));
        }
        setApiCalled(false);
        props.handleUserDetailUpdate();
      })
      .catch(function (error) {
        setApiCalled(false);
      });
  };

  document.addEventListener("click", function (e) {
    if (
      document.getElementById("editOptionModal") &&
      !document.getElementById("editOptionModal").contains(e.target)
    ) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = setTimeout(() => {
        setEditModal(false);
        setProfileOpen(false);
      }, 300);
    }
  });

  return (
    <div>
      <div
        className="login-icon-wrapper"
        id={`${editModal ? "" : "editOptionModal"}`}
        onClick={(e) => {
          e.stopPropagation();
          setEditModal(false);
          setProfileOpen(!profileOpen);
        }}
      >
        <PersonIcon />
        {curFullname?.trim()}
      </div>

      {profileOpen === true && (
        <div
          id={`${editModal ? "" : "editOptionModal"}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{
              width: "20rem",
              maxHeight: "25rem",
              top: "4rem",
              right: '1rem',
              overflowY: "scroll",
              border: "1px solid #bebebe",
              borderRadius: "5px",
              backgroundColor: "white",
              position: "absolute",
              zIndex: 999,
            }}
          >
            {apiCalled && <CircularRotatingLines />}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {displayPic ? (
                displayPic?.split("//").length <= 2 ? (
                  <Avatar
                    alt="Profile Picture"
                    src={displayPic}
                    style={{
                      padding: "0rem",
                      margin: "1rem 0rem 0rem 2rem",
                      top: "5px",
                      width: "3rem",
                      height: "3rem",
                      border: "1px solid #dedede",
                    }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(String(curFullname?.trim()).toUpperCase())}
                    style={{
                      padding: "0rem",
                      margin: "1rem 0rem 0rem 2rem",
                      top: "5px",
                      width: "3rem",
                      height: "3rem",
                    }}
                  />
                )
              ) : (
                <Avatar
                  {...stringAvatar(String(curFullname?.trim()).toUpperCase())}
                  style={{
                    padding: "0rem",
                    margin: "1rem 0rem 0rem 2rem",
                    top: "5px",
                    width: "3rem",
                    height: "3rem",
                  }}
                />
              )}
              <div
                style={{
                  margin: "2.25rem 0 0 1rem",
                  fontSize: "19px",
                  width: "100%",
                  height: "14px",
                  top: "103px",
                  lineHeight: "14.06px",
                }}
              >
                {UserNameProcessing(curFullname)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  margin: "2rem 1rem 0rem 2rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ width: "8rem" }}>Email:</div>
                <div style={{ width: "100%" }}>{curUserEmail}</div>
              </div>
              <div
                style={{
                  margin: "1rem 0rem 0rem 2rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {/* 123 */}
                <div style={{ width: "8rem" }}>Title:</div>
                <div style={{ width: "100%" }}>
                  {about?.length > 50 ? (
                    readMore ? (
                      <PostTextComponent>
                        {about.substring(0, 50)}
                        <ReadMoreSpan onClick={readMoreHandler}>
                          See more.
                        </ReadMoreSpan>
                      </PostTextComponent>
                    ) : (
                      <PostTextComponent>
                        {about}
                        <ReadMoreSpan onClick={readMoreHandler}>
                          See less.
                        </ReadMoreSpan>
                      </PostTextComponent>
                    )
                  ) : (
                    <PostTextComponent>{about}</PostTextComponent>
                  )}
                </div>
              </div>
              <div
                style={{
                  margin: "0rem 1rem 1rem 2rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ width: "8rem" }}>Company:</div>
                <div style={{ width: "100%" }}>{curCompany}</div>
              </div>
              <div
                style={{
                  margin: "0rem 1rem 0rem 2rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ width: "8rem" }}>Country/Region:</div>
                <div style={{ width: "100%" }}>{curCountry}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "1.2rem",
                  marginBottom: "1rem",
                }}
              >
                <Link
                  to="/logout"
                  className="links hideForMV"
                  style={{
                    margin: "0",
                    top: "0",
                  }}
                >
                  <LogoutBtn>
                    <AiOutlineLogout
                      style={{
                        textAlign: "center",
                        scale: "1",
                        paddingRight: "4px",
                      }}
                    />
                    <span
                      style={{
                        marginBottom: "3px",
                      }}
                    >
                      Logout
                    </span>
                  </LogoutBtn>
                </Link>
                <EditProfileBtn
                  onClick={(e) => {
                    e.stopPropagation();
                    setProfileOpen(false);
                    setEditModal(true);
                  }}
                >
                  Edit Profile
                </EditProfileBtn>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalWrapper
        show={editModal}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {apiCalled ? (
          <CircularRotatingLines />
        ) : (
          <ModalBody>
            <AvatarPostWrapper>
              <HeadingContainer1>Edit Profile</HeadingContainer1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {displayPic ? (
                  displayPic?.split("//").length <= 2 ? (
                    <Avatar
                      alt="Profile Picture"
                      src={displayPic}
                      style={{
                        padding: "0rem",
                        margin: "0rem 0rem 0rem 1rem",
                        top: "2px",
                        width: "5rem",
                        height: "5rem",
                        border: "1px solid #dedede",
                      }}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(
                        String(curFullname?.trim()).toUpperCase()
                      )}
                      style={{
                        padding: "0rem",
                        margin: "0rem 0rem 0rem 1rem",
                        top: "2px",
                        width: "5rem",
                        height: "5rem",
                      }}
                    />
                  )
                ) : (
                  <Avatar
                    {...stringAvatar(String(curFullname?.trim()).toUpperCase())}
                    style={{
                      padding: "0rem",
                      margin: "0rem 0rem 0rem 1rem",
                      top: "2px",
                      width: "5rem",
                      height: "5rem",
                    }}
                  />
                )}
                <div
                  style={{
                    margin: "1.2rem 0.5rem 0rem 2rem",
                  }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <LogoutBtn>
                    <input
                      onChange={(e) => {
                        if (
                          e.target.files[0]?.type &&
                          !(
                            e.target.files[0].type === "image/png" ||
                            e.target.files[0].type === "image/jpg" ||
                            e.target.files[0].type === "image/jpeg"
                          )
                        ) {
                          setWrongFormat(true);
                        } else if (e.target.files[0]?.type) {
                          setNewProfilePic(e.target.files);
                        }
                      }}
                      ref={fileInputRef}
                      type="file"
                      name="file"
                      hidden
                    />
                    <Dialog
                      open={wrongFormat}
                      onClose={() => {
                        setWrongFormat(false);
                      }}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Wrong file format
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Please select image files only of the following
                          formats - png, jpeg.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <LogoutBtn
                          style={{
                            backgroundColor: "#147350",
                            color: "white",
                          }}
                          onClick={() => {
                            setWrongFormat(false);
                          }}
                        >
                          Close
                        </LogoutBtn>
                      </DialogActions>
                    </Dialog>
                    {newProfilePic ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <MdOutlineCloudDone
                          style={{
                            textAlign: "center",
                            scale: "1",
                            paddingRight: "4px",
                            color: "#147350",
                          }}
                        />
                        Selected
                      </div>
                    ) : (
                      <>
                        <FiEdit
                          style={{
                            textAlign: "center",
                            scale: "1",
                            paddingRight: "4px",
                            cursor: "pointer",
                          }}
                        />
                        Edit Picture
                      </>
                    )}
                  </LogoutBtn>
                </div>
                <div
                  style={{
                    margin: "1.2rem 1rem 0rem 0.5rem",
                  }}
                  onClick={() => {
                    setDisplayPic(null);
                    setNewProfilePic(null);
                    setRemoved(true);
                  }}
                >
                  <LogoutBtn>
                    <AiOutlineDelete
                      style={{
                        textAlign: "center",
                        scale: "1",
                        paddingRight: "4px",
                      }}
                    />
                    Remove
                  </LogoutBtn>
                </div>
              </div>
              <PostDetailComponent>
                <div
                  style={{
                    margin: "1rem 1rem 2rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                >
                  <div style={{ width: "10rem", color: "black" }}>Name:</div>
                  {usernameEdit ? (
                    <>
                      <ModalSmallInput
                        placeholder={newFullname ? newFullname : curFullname}
                        wrongInputName={wrongInputName}
                        id="inputMsg"
                        pattern="/^[a-zA-Z]*$"
                        value={
                          edited
                            ? newFullname
                              ? newFullname
                              : newFullname
                            : !edited && newFullname
                            ? newFullname
                            : curFullname
                        }
                        onChange={(e) => {
                          if (newFullname.length <= e.target.value.length - 1) {
                            if (
                              /\d/.test(e.target.value) ||
                              specialCharCheckName.test(e.target.value) ||
                              e.target.value.length >= MAX_CHAR_LIMIT ||
                              !e.target.value.trim().length
                            ) {
                              setWrongInputName(true);
                              setWrongInputDialog(true);
                              setEdited(true);
                              setNewFullname(e.target.value);
                            } else {
                              setWrongInputName(false);
                              setWrongInputDialog(false);
                              setEdited(true);
                              setNewFullname(e.target.value);
                            }
                          } else {
                            if (
                              /\d/.test(e.target.value) ||
                              specialCharCheckName.test(e.target.value) ||
                              e.target.value.length >= MAX_CHAR_LIMIT ||
                              !e.target.value.trim().length
                            ) {
                              setWrongInputName(true);
                              setWrongInputDialog(true);
                            } else {
                              setWrongInputName(false);
                              setWrongInputDialog(false);
                            }
                            setEdited(true);
                            setNewFullname(e.target.value);
                          }
                        }}
                      />
                      <DialogPopup
                        dialogContentText={
                          newFullname.trim() === ""
                            ? "Name cannot be empty."
                            : /[\d]/.test(newFullname) ||
                              specialCharCheckName.test(newFullname)
                            ? "Name cannot contain numbers or special characters."
                            : newFullname.length >= MAX_CHAR_LIMIT
                            ? "Maximum Character Limit Exceeded"
                            : null
                        }
                      />
                    </>
                  ) : newFullname ? (
                    <div style={{ width: "100%", color: "black" }}>
                      {newFullname}
                    </div>
                  ) : (
                    <div style={{ width: "100%", color: "black" }}>
                      {curFullname
                        ? UserNameProcessing(curFullname)
                        : UserNameProcessing("user")}
                    </div>
                  )}
                  <div
                    onClick={() => {
                      setUsernameEdit((usernameEdit) => !usernameEdit);
                      setWrongInputName(false);
                      setWrongInputDialog(false);
                    }}
                  >
                    {usernameEdit ? (
                      newFullname ? (
                        !(
                          /\d/.test(newFullname) ||
                          specialCharCheckName.test(newFullname) ||
                          newFullname.length >= MAX_CHAR_LIMIT ||
                          !newFullname.trim().length
                        ) ? (
                          <MdOutlineDownloadDone
                            value={{
                              color: "#147350",
                              scale: 1.2,
                            }}
                            onClick={() => {
                              setEdited(false);
                            }}
                          />
                        ) : (
                          <RxCrossCircled
                            value={{
                              color: "#ea1b2c",
                              scale: 1.1,
                            }}
                            onClick={() => {
                              setNewFullname("");
                              setEdited(false);
                            }}
                          />
                        )
                      ) : (
                        <RxCrossCircled
                          value={{
                            color: "#ea1b2c",
                            scale: 1.1,
                          }}
                          onClick={() => {
                            setNewFullname("");
                            setEdited(false);
                          }}
                        />
                      )
                    ) : (
                      <FiEdit style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    margin: "0rem 1rem 0rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                >
                  <div style={{ width: "10rem", color: "black" }}>Title:</div>
                  {aboutEdit ? (
                    <>
                      <ModalLargeInput
                        placeholder={about}
                        wrongInputAbout={wrongInputAbout}
                        id="inputMsg"
                        pattern="/^[^a-zA-Z0-9 ]*$/"
                        value={
                          edited
                            ? newAbout
                              ? newAbout
                              : newAbout
                            : !edited && newAbout
                            ? newAbout
                            : about
                        }
                        onChange={(e) => {
                          if (newAbout.length <= e.target.value.length - 1) {
                            if (
                              /\d/.test() ||
                              e.target.value.length >= MAX_CHAR_LIMIT ||
                              !e.target.value.trim().length
                            ) {
                              setWrongInputAbout(true);
                              setWrongInputDialog(true);
                              setEdited(true);
                              setNewAbout(e.target.value);
                            } else {
                              setWrongInputAbout(false);
                              setWrongInputDialog(false);
                              setEdited(true);
                              setNewAbout(e.target.value);
                            }
                          } else {
                            if (
                              /\d/.test() ||
                              e.target.value.length >= MAX_CHAR_LIMIT ||
                              !e.target.value.trim().length
                            ) {
                              setWrongInputAbout(true);
                              setWrongInputDialog(true);
                            } else {
                              setWrongInputAbout(false);
                              setWrongInputDialog(false);
                            }
                            setEdited(true);
                            setNewAbout(e.target.value);
                          }
                        }}
                      />
                      <DialogPopup
                        dialogContentText={
                          newAbout.trim() === ""
                            ? "Title cannot be empty."
                            : newAbout.length >= MAX_CHAR_LIMIT
                            ? "Maximum Character Limit Exceeded"
                            : /[\d]/.test(newAbout)
                            ? "Please enter a valid title"
                            : null
                        }
                      />
                    </>
                  ) : newAbout ? (
                    <div style={{ width: "100%", color: "black" }}>
                      {newAbout?.length > 250 ? (
                        readMoreBig ? (
                          <PostTextComponent>
                            {newAbout.substring(0, 250)}
                            <ReadMoreSpan onClick={readMoreBigHandler}>
                              See more.
                            </ReadMoreSpan>
                          </PostTextComponent>
                        ) : (
                          <PostTextComponent>
                            {newAbout}
                            <ReadMoreSpan onClick={readMoreBigHandler}>
                              See less.
                            </ReadMoreSpan>
                          </PostTextComponent>
                        )
                      ) : (
                        <PostTextComponent>{newAbout}</PostTextComponent>
                      )}
                    </div>
                  ) : (
                    <div style={{ width: "100%", color: "black" }}>
                      {about?.length > 250 ? (
                        readMoreBig ? (
                          <PostTextComponent>
                            {about.substring(0, 250)}
                            <ReadMoreSpan onClick={readMoreBigHandler}>
                              See more.
                            </ReadMoreSpan>
                          </PostTextComponent>
                        ) : (
                          <PostTextComponent>
                            {about}
                            <ReadMoreSpan onClick={readMoreBigHandler}>
                              See less.
                            </ReadMoreSpan>
                          </PostTextComponent>
                        )
                      ) : (
                        <PostTextComponent>{about}</PostTextComponent>
                      )}
                    </div>
                  )}
                  <div
                    onClick={() => {
                      setAboutEdit((aboutEdit) => !aboutEdit);
                      setWrongInputAbout(false);
                      setWrongInputDialog(false);
                    }}
                  >
                    {aboutEdit ? (
                      newAbout ? (
                        !(
                          /\d/.test(newAbout) ||
                          specialCharCheck.test(newAbout) ||
                          newAbout.length >= MAX_CHAR_LIMIT ||
                          !newAbout.trim().length
                        ) ? (
                          <MdOutlineDownloadDone
                            sx={{
                              color: "#147350",
                              scale: 1.2,
                            }}
                            onClick={() => {
                              setEdited(false);
                            }}
                          />
                        ) : (
                          <RxCrossCircled
                            sx={{
                              color: "#ea1b2c",
                              scale: 1.1,
                            }}
                            onClick={() => {
                              setEdited(false);
                              setNewAbout("");
                            }}
                          />
                        )
                      ) : (
                        <RxCrossCircled
                          sx={{
                            color: "#ea1b2c",
                            scale: 1.1,
                          }}
                          onClick={() => {
                            setEdited(false);
                          }}
                        />
                      )
                    ) : (
                      <FiEdit style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    margin: "2rem 1rem 2rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                >
                  <div style={{ width: "10rem", color: "black" }}>Company:</div>
                  {companyEdit ? (
                    <>
                      <ModalSmallInput
                        placeholder="Type here..."
                        wrongInputCompany={wrongInputCompany}
                        id="inputMsg"
                        pattern="/^[^a-zA-Z0-9 ]*$/"
                        value={
                          edited
                            ? newCompany
                              ? newCompany
                              : newCompany
                            : !edited && newCompany
                            ? newCompany
                            : curCompany
                        }
                        onChange={(e) => {
                          if (newCompany.length <= e.target.value.length - 1) {
                            if (
                              /\d/.test() ||
                              specialCharCheck.test(e.target.value) ||
                              e.target.value.length >= MAX_CHAR_LIMIT ||
                              !e.target.value.trim().length
                            ) {
                              setWrongInputCompany(true);
                              setWrongInputDialog(true);
                              setEdited(true);
                              setNewCompany(e.target.value);
                            } else {
                              setWrongInputCompany(false);
                              setWrongInputDialog(false);
                              setEdited(true);
                              setNewCompany(e.target.value);
                            }
                          } else {
                            if (
                              /\d/.test() ||
                              specialCharCheck.test(e.target.value) ||
                              e.target.value.length >= MAX_CHAR_LIMIT ||
                              !e.target.value.trim().length
                            ) {
                              setWrongInputCompany(true);
                              setWrongInputDialog(true);
                            } else {
                              setWrongInputCompany(false);
                              setWrongInputDialog(false);
                            }
                            setEdited(true);
                            setNewCompany(e.target.value);
                          }
                        }}
                      />
                      <DialogPopup
                        dialogContentText={
                          newCompany.trim() === ""
                            ? "Company cannot be empty."
                            : newCompany.length >= MAX_CHAR_LIMIT
                            ? "Maximum Character Limit Exceeded"
                            : /[\d]/.test(newCompany) ||
                              specialCharCheck.test(newCompany)
                            ? "Company cannot contain special characters."
                            : null
                        }
                      />
                    </>
                  ) : newCompany ? (
                    <div style={{ width: "100%", color: "black" }}>
                      {newCompany}
                    </div>
                  ) : (
                    <div style={{ width: "100%", color: "black" }}>
                      {curCompany}
                    </div>
                  )}
                  <div
                    onClick={() => {
                      setCompanyEdit((companyEdit) => !companyEdit);
                      setWrongInputCompany(false);
                      setWrongInputDialog(false);
                    }}
                  >
                    {companyEdit ? (
                      newCompany ? (
                        !(
                          /\d/.test(newCompany) ||
                          specialCharCheck.test(newCompany) ||
                          newCompany.length >= MAX_CHAR_LIMIT ||
                          !newCompany.trim().length
                        ) ? (
                          <MdOutlineDownloadDone
                            sx={{
                              color: "#147350",
                              scale: 1.2,
                            }}
                            onClick={() => {
                              setEdited(false);
                            }}
                          />
                        ) : (
                          <RxCrossCircled
                            sx={{
                              color: "#ea1b2c",
                              scale: 1.1,
                            }}
                            onClick={() => {
                              setNewCompany("");
                              setEdited(false);
                            }}
                          />
                        )
                      ) : (
                        <RxCrossCircled
                          sx={{
                            color: "#ea1b2c",
                            scale: 1.1,
                          }}
                          onClick={() => {
                            setEdited(false);
                          }}
                        />
                      )
                    ) : (
                      <FiEdit style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    margin: "0rem 1rem 1rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div style={{ width: "10rem", color: "black" }}>
                    Country/Region:
                  </div>
                  {countryEdit ? (
                    <div
                      style={{
                        width: "100%",
                        margin: 0,
                      }}
                    >
                      <CountryDropdown
                        newCountry={newCountry}
                        setNewCountry={setNewCountry}
                        countryList={countryList}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  ) : newCountry ? (
                    <div style={{ width: "100%", color: "black" }}>
                      {newCountry}
                    </div>
                  ) : (
                    <div style={{ width: "100%", color: "black" }}>
                      {curCountry}
                    </div>
                  )}
                  <div
                    onClick={() =>
                      setCountryEdit((countryEdit) => !countryEdit)
                    }
                  >
                    {countryEdit ? (
                      newCountry ? (
                        <MdOutlineDownloadDone
                          sx={{
                            color: "#147350",
                            scale: 1.2,
                          }}
                        />
                      ) : (
                        <RxCrossCircled
                          sx={{
                            color: "#ea1b2c",
                            scale: 1.1,
                          }}
                        />
                      )
                    ) : (
                      <FiEdit style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <LogoutBtn
                    onClick={() => {
                      if (curProfilePic === "") {
                        setDisplayPic(displayPic);
                      } else {
                        setDisplayPic(toggleDisplayPic);
                      }
                      toggleDetails();
                      toggleSelected();
                      toggleEdit();
                    }}
                    style={{
                      marginRight: "0.6rem",
                    }}
                  >
                    Cancel
                  </LogoutBtn>
                  <EditProfileBtn
                    disabled={
                      wrongInputName || wrongInputAbout || wrongInputCompany
                    }
                    onClick={() => {
                      if (newProfilePic) {
                        toggleDetails();
                        toggleSelected();
                        editProfile();
                      } else {
                        toggleDetails();
                        updateUser();
                      }
                    }}
                  >
                    Apply
                  </EditProfileBtn>
                </div>
              </PostDetailComponent>
            </AvatarPostWrapper>
          </ModalBody>
        )}
      </ModalWrapper>
    </div>
  );
};

export default UserProfile;
