import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BMHeader from './BMHeader';
import NoImage from '../PrivateBrandCatalog/assets/NoImage.png';
import LoadingIndicator from '../Common/LoadingIndicator';
import { getBMProductDetails, getMyListForBM, persistMyListForBM } from './BMService';
import { addItemsDataToBMList, addItemToBMList, deleteItemFromBMList, populateBMList } from './BMCollectionSlice';
import './ProductDetailsPage.scss';
import AddToListButton from './AddToListButton';
import NoDataFallback from '../Common/NoDataFallback';
import CommonErrorFallback from '../Common/CommonErrorFallback';
import { trackMixPanelEvent } from '../../../mixpanel/mixpanel';
import debounce from 'lodash.debounce';
import { Button } from '@mui/material';
import SuccessImage from '../../../assets/images/SuccessCheckGreen.png'

const SuccessView = () => {
  const navigate = useNavigate();
  return (
    <div id="success-mail-container">
      <h4> Success! </h4>
      <img src={SuccessImage}></img>
      <p>
        We appreciate your interest in 7-Eleven branded merchandise!
        Our team will be in touch shortly to confirm your order and provide any additional details.
      </p>
      <Button
        variant='contained'
        size='medium'
        onClick={() => {
          navigate('/')}
        }
      >
        BACK TO HOMEPAGE
      </Button>
    </div>
  )
}
const BMListLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bmList, bmListData } = useSelector(state => state.bmCollection);
  const [isLoading, setIsLoading] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);
  const [isPersistingCart, setPersistingCart ] = useState(false);

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  useEffect(() => {
    trackMixPanelEvent({module: 'BRANDED_MERCHANDISE', action: 'MY_LIST_PAGE'})
    fetchBMListItems();
  }, []);

  const debouncedPersistMyList = useCallback(
    debounce((list, timestampISOString) => {
      setPersistingCart(true);
      persistMyListForBM(
        list.map((listItem) => ({
          itemMasterId: listItem.id,
          count: listItem.count,
        })),
        timestampISOString
      )
        .then((res) => {
          setPersistingCart(false);
        })
        .catch((err) => {
          setPersistingCart(false);
        });
    }, 800),
    []
  );

  useEffect(() => {
    const timestampOfLocalChange = localStorage.getItem('bm_my_list_timestamp') || new Date().toISOString();
    const localDate = new Date(timestampOfLocalChange);
    const timestampOfRemoteChange = new Date(localDate.getTime() + 1000).toISOString();
    debouncedPersistMyList(bmList, timestampOfRemoteChange);
  }, [bmList, debouncedPersistMyList]);

  const fetchBMListItems = async () => {
    let bmListValue = bmList;

    //try fetching my-list from backend...
    try {
      const { result: fetchedList, timestamp } = await getMyListForBM();
      
      const timestampLocally = localStorage.getItem('bm_my_list_timestamp') || new Date();
      if(!timestamp || timestampLocally && new Date(timestamp) < new Date(timestampLocally)) {
        throw new Error('local changes are more recent compared to remote changes.');
      }
      bmListValue = fetchedList;
    } catch(err) { 
      // failed to fetch from backend, or backend has old changes => fallback to local storage
      const bmListFromLocal = localStorage.getItem('bm_my_list');
      if (bmListFromLocal) {
        bmListValue = JSON.parse(bmListFromLocal);
      }
    } finally {
      dispatch(populateBMList(bmListValue));
    }

    if(!bmListValue?.length) {
      setApiFailReported(true);
      return;
    }
    
    const productIds = bmListValue?.map(item => item.id) || [];
    if(!productIds.length) {
      console.error('No valid product IDs found');
      setApiFailReported(true); 
      return;
    }
    
    const bmListProductIds = bmList.map((item) => item.id);
    
    setIsLoading(true);
    try {
      const productDetailsResponse = await getBMProductDetails({
        rowsPerPage: bmListValue.length,
        page: 1,
        productIds: productIds
      });
      const validatedRes = productDetailsResponse.data.data.rows;
      if (bmListProductIds.length > productDetailsResponse?.data?.data?.count) {
        const returnedIds = validatedRes.map((item) => String(item.itemMasterID));
        dispatch(deleteItemFromBMList({ validItemIds: returnedIds }));
      }else{
        dispatch(addItemsDataToBMList(validatedRes));
      }
    }
    catch (error) {
      console.error('Failed to fetch product details:', error);
      setApiFailReported(true);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (item) => {
    const collectionId = item?.itemHierarchies[0]?.itemHierarchyCode?.id;
    const collectionName = item?.itemHierarchies[0]?.itemHierarchyCode?.description;
    
    navigate(`/brandedMerchandise/collections/${collectionId}/${item.itemMasterID}`, {
      state: { collectionName, collectionId }
    });
  };

  const handleRemoveItem = (itemId) => {
    const updatedList = bmList.filter(item => `${item.id}` !== `${itemId}`);
    const updatedDataList = bmListData.filter(item => `${item.itemMasterID}` !== `${itemId}`);
    
    dispatch(addItemToBMList({ id: itemId, count: 0 }));
    dispatch(addItemsDataToBMList(updatedDataList));
    localStorage.setItem('bm_my_list', JSON.stringify(updatedList));
  };
  const getItemQuantity = (id) => {
    const quantity = bmList.map((item)=>{
      if(`${item.id}`===`${id}`){
        return item.count
      }
    })
    return quantity
  }
  return (
    <div className="bm-list-wrapper">
      {isLoading && <LoadingIndicator />}
      {isPersistingCart && <LoadingIndicator enableBackdrop={true}/> }
      { showSuccessScreen && <SuccessView/> }
      <BMHeader headerText="My List" visible={!showSuccessScreen} onBMListMailSent={() => {
        dispatch(populateBMList([])); // remove everything from list...
        setShowSuccessScreen(true);
      }} />
      {!showSuccessScreen && <div className="item-container-wrapper">
        {
        apiFailReported ? (
          <CommonErrorFallback />
        ) : !isLoading && bmListData.length === 0 ? (
          <div className="no-data-container">
            <NoDataFallback msg="Nothing to see here. The list is empty." />
          </div>
        ) : (
          <div className="item-container">
            {bmListData.map((item, index) => (
              <div
                key={item.itemMasterID}
                className="item-card"
                onClick={() => handleCardClick(item)}
              >
                <div className="item-image-container-wrapper">
                  <div className="item-image-container">
                    <img className="item-image" src={item.imageUrl.length? item.imageUrl : NoImage}/>
                  </div>
                  <div className='item-index-container'>{`${index+1}.`}</div>
                </div>
                <div className="item-details">
                  <div className="item-name">{item.shortDescription}</div>
                  <div className="price">Price USD: ${item.brandedMerchandisePrice.toFixed(2)}</div>
                  <div className="price">SRP USD: ${item.minSrp.toFixed(2)}</div>
                  <div className="moq">Minimum Order Quantity: {item.minimumOrderQty}</div>
                  <div className="print-quantity">MOQ: {item.minimumOrderQty}</div>
                  <div className='print-quantity'> Quantity: {getItemQuantity(item.itemMasterID)}</div>
                  <div>
                    <div className="add-to-list-wrapper">
                      <AddToListButton 
                        itemId={item.itemMasterID} 
                        onRemoveItem={handleRemoveItem} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>}
    </div>
  );
};

export default BMListLayout;