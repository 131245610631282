import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import LeaderModal from "./LeaderModal";
import "./Leaders.css";
import { useTranslation } from "react-i18next";
import l1 from "../../../assets/images/shinji_Abe.png";
import l2 from "../../../assets/images/ken_Wakabayashi.png";
import TakeshiKojimaHeadshot from "../../../assets/images/takeshi_Kojima.png";
import LeonidVolovnikHeadshot from "../../../assets/images/leonid_Volovnik.png";
import MichaelaGiffordHead from "../../../assets/images/Michaela_Gifford_Head_Shot.jpg";
// import PhotoComingSoon from "../../../assets/images/PhotoComingSoon.png"
import HiroOhta from "../../../assets/images/Hiro_Ohta.png";
import ContactUs from "../../Assets/Common/ContactUs";
import { trackMixPanelEvent } from "../../../mixpanel/mixpanel";
import MichaelBerger from "../../../assets/images/MichaelBerger.png";
import LarsGroganHeadshot from "../../../assets/images/LarsGrogan.png";
import { Container } from '@mui/material';
 
const leadersData = [
  { id: 1, image: l1, nameKey: "ceo_shinji", titleKey: "chairman", quotes: ["shin_quote1", "shin_quote2", "shin_quote3", "shin_quote4"], linkedinUrl: null },
  { id: 2, image: l2, nameKey: "ceo_ken", titleKey: "president_ceo", quotes: ["ken_quote1", "ken_quote2", "ken_quote3", "ken_quote4", "ken_quote5", "ken_quote6"], linkedinUrl: "https://www.linkedin.com/in/ken-wakabayashi-98b571189/" },
  { id: 3, image: LeonidVolovnikHeadshot, nameKey: "leonid", titleKey: "vp_cao", quotes: ["leonid_quote1", "leonid_quote2", "leonid_quote3", "leonid_quote4"], linkedinUrl: "https://www.linkedin.com/in/leonidv/" },
  { id: 4, image: MichaelaGiffordHead, nameKey: "michaela", titleKey: "vp_cfo", quotes: ["michaela_quote1", "michaela_quote2", "michaela_quote3"], linkedinUrl: "https://www.linkedin.com/in/michaelagifford/" },
  { id: 5, image: HiroOhta, nameKey: "hiro", titleKey: "vp_gbd", quotes: ["hiro_quote1", "hiro_quote2", "hiro_quote3", "hiro_quote4", "hiro_quote5"], linkedinUrl: "https://www.linkedin.com/in/hiro-ohta/" },
  { id: 6, image: MichaelBerger, nameKey: "michael", titleKey: "vp_orI", quotes: ["michael_quote1","michael_quote2","michael_quote3"], linkedinUrl: "https://www.linkedin.com/in/mike-berger-9299961/" },
  { id: 7, image: TakeshiKojimaHeadshot, nameKey: "takeshi", titleKey: "vp_inops", quotes: ["takeshi_quote1", "takeshi_quote2", "takeshi_quote3", "takeshi_quote4"], linkedinUrl: "https://www.linkedin.com/in/takeshi-kojima-9b9b65153/" },
  { id: 8, image: LarsGroganHeadshot, nameKey: "lars", titleKey: "sr_dir", quotes: ["lars_quote1", "lars_quote2", "lars_quote3"], linkedinUrl: "https://www.linkedin.com/in/lars-grogan-mba-gphr-4197233/" }
];

const Leaders = () => {
  useEffect(()=>{
    trackMixPanelEvent({ module: "LEADERSHIP" })
  },[])
  const { t } = useTranslation();
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (id) => setActiveModal(id);
  const closeModal = () => setActiveModal(null);

  return (
    <div className="leadership">
      <div className="page-header">
        <h1>{t("who_we_are")}</h1>
      </div>
      <nav>
        <NavLink className="text-link" to="/about" end>
          {t("our_brand_story")}
        </NavLink>
        <NavLink className="text-link" to="/leadership">
          {t("our_leadership")}
        </NavLink>
      </nav>
        <div className="leadership-list">
        <div className="leadership-item-2 first-row">
            {leadersData.slice(0, 2).map((leader) => (
              <div key={leader.id} className="leadership-item-container">
                <div className="leadership-detail-image">
                  <img src={leader.image} alt={t(leader.nameKey)} />
                  <div className="position">
                    <p className="leader_name">{t(leader.nameKey)}</p>
                    <p className="leader_title">{t(leader.titleKey)}</p>
                    <button className="openModalBtn" onClick={() => openModal(leader.id)}>
                      {t("view_profile")}
                    </button>
                  </div>
                  <LeaderModal
                    isOpen={activeModal === leader.id}
                    onClose={closeModal}
                    image={leader.image}
                    name={t(leader.nameKey)}
                    title={t(leader.titleKey)}
                    quotes={leader.quotes.map((q) => t(q))}
                    linkedinUrl={leader.linkedinUrl}
                  />
                </div>
              </div>
            ))}
        </div>
        </div>
      <Container className="custom-container">
        <div className="leadership-list-2">
          {leadersData.slice(2).map((leader) => (
            <div key={leader.id} >
              <div className="leadership-detail-image">
                <img src={leader.image} alt={t(leader.nameKey)} />
                <div className="position">
                  <p className="leader_name">{t(leader.nameKey)}</p>
                  <p className="leader_title">{t(leader.titleKey)}</p>
                  <button className="openModalBtn" onClick={() => openModal(leader.id)}>
                    {t("view_profile")}
                  </button>
                </div>
              </div>
              <LeaderModal
                isOpen={activeModal === leader.id}
                onClose={closeModal}
                image={leader.image}
                name={t(leader.nameKey)}
                title={t(leader.titleKey)}
                quotes={leader.quotes.map((q) => t(q))}
                linkedinUrl={leader.linkedinUrl}
              />
            </div>
          ))}
        </div>
        </Container>
      <section className="corp_contact-us">
        <ContactUs />
      </section>

    </div>
  );
};

export default Leaders;
