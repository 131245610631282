import { React, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PBCHeader from "./PBCHeader";
import FilterLayout from "./FilterLayout";
import ProductTable from "./ProductTable";
import PBCatalogSlice, { filterItemById } from "./PBCatalogSlice";
import { useDispatch, useSelector } from "react-redux";
import category1 from "./assets/FreshFoods_New_Small.jpeg";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { AiFillCloseCircle } from "react-icons/ai";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";
import { pSAData } from "./PBCconstant";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { addItemToList } from './PBCatalogSlice';
import { Stack } from "@mui/material";
import { pb_service } from "../../../config/environment";
import getHierarchyDetail from "../../../Utils/GetHierarchyDetail";
import { getImageUrl, resValidation } from "../../../Utils/CommonUtils";
import UnpublishConfirmation from "../Common/UnpublishConfirmation";
import { unpublishItem } from "./PBCatalogService";
import { useSnackbar } from "notistack";
import CommonErrorFallback from "../Common/CommonErrorFallback";
import axios from "axios";
import DisclaimerUtil from "../Common/DisclaimerUtil";
import LoadingIndicator from "../Common/LoadingIndicator";
import NoImage from "../PrivateBrandCatalog/assets/NoImage.png";
import ResolutionDisclaimer from "../Common/ResolutionDisclaimer";
import { trackMixPanelEvent } from "../../../mixpanel/mixpanel";

const Wrapper = styled.div`
  padding: 10rem 5rem 3rem 5rem;
  /* margin-bottom: 2rem; */
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media (max-width: 770px) {
    padding: 10rem 2rem 4rem 2rem;
  }
`;
const FilterItemWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding-bottom: 8rem;
`;
const ImageDetailWrapper = styled.div`
  width: 95%;
  /* margin-left: 3.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid gainsboro;
  border-radius: 0.4rem;
  padding: 2rem;
`;
const ItemNameContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  line-height: 2rem;
  word-wrap: break-word;
  .fi {
	margin: 0 .5rem 0 0;
  }
`;
const ImageDetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ImageButtonContainer = styled.div`
  width: 35%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .MuiButton-outlined{
    margin-top: 0.5rem;
    color: #34a07c; 
  }
  .add-btn{
    color: #34a07c;
    border: 1px solid #34a07c;
    :hover{
      border: 1px solid #34a07c;
    }
  }
  .rmv-btn{
    color: #EF5350;
    border: 1px solid #EF5350;
    :hover{
      border: 1px solid #EF5350;
    }
  }

  @media (max-width: 770px) {
    width: 50%;
    margin-bottom: 2rem;
  }
`;
const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .MuiButton-outlined{
    margin-top: 0.5rem;
    color: #34a07c;  
  }
  .add-btn{
    color: #34a07c;
    border: 1px solid #34a07c;
    :hover{
      border: 1px solid #34a07c;
    }
  }
  .rmv-btn{
    color: #EF5350;
    border: 1px solid #EF5350;
    :hover{
      border: 1px solid #EF5350;
    }
  }
`;
const LikeCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #7a6060;
  align-items: center;
  margin-right: 0.2rem;
`;
const TableContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 770px) {
    width: 100%;
  }
`;
const ItemImgContainer = styled.div`
  width: 80%;
  height: 80%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ItemDetailImg = styled.img`
  max-width: 90%;
  width: auto;
  height: 20rem; 
`;
const NotifyButton = styled.button`
  min-width: 48%;
  padding: 0.4rem 0.6rem;
  height: auto;
  background-color: #147350;
  color: white;
  border-style: none;
  border-radius: 0.3rem;
  cursor: pointer;
`;
const LikeButton = styled.button`
  padding: 0.3rem 0.5rem;
  height: auto;
  background-color: inherit;
  border-style: none;
  border: 1px solid black;
  border-radius: 0.3rem;
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LikeNotifyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const UnpublishButton = styled.button`
min-width: 48%;
  padding: 0.4rem 0.6rem;
  height: auto;
  background-color: #e37739;
  color: white;
  border-style: none;
  border-radius: 0.3rem;
  cursor: pointer;
`;
const UnpublishModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const UnpublishModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0rem;
  padding-top: 2px;
  width: 70%;
  max-width: 38rem;
  max-height: 85vh;
  /* overflow-y: scroll; */
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: #f2f2f2;
`;
const UnpublishModalHeader = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow-y: scroll; */
`;
const UnpublishModalForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  margin-top: 0;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 97%;
  width: auto;
  background: white;
  /* overflow-y: scroll; */
  input {
    padding: 0.6rem;
  }
  .MuiFilledInput-input {
    padding: 0.6rem;
  }
  .MuiInputBase-root {
    border-radius: 0.3rem;
  }
`;
const FormInputContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  /* .css-6hp17o-MuiList-root-MuiMenu-list {
    height: auto;
    min-height: 2rem;
    max-height: 3rem;
    overflow-x: auto;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(100, 116, 139, 0.8);
  } */
`;
const FormInputHeading = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
`;
const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  background: #ffeebf;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 0.5rem;
`;
const ConfirmTextWrapper = styled.div`
width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border-radius: 8px;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.8);
`;
const FormBtnWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  margin-top: 0.5rem;
  float: right;
  display: flex;
  justify-content: flex-end;
`;
const SubmitBtn = styled.button`
  padding: 0.4rem 1rem;
  height: auto;
  background-color: #147350;
  color: white;
  border-style: none;
  border-radius: 0.3rem;
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: 600;
`;
const CancelBtn = styled.button`
  padding: 0.4rem 1rem;
  height: auto;
  font-weight: 500;
  text-align: center;
  border: 1px solid #d2d2d2;
  background-color: #f6f6f6;
  color: black;
  border-radius: 5px;
  cursor: pointer;
`;

const pageType = "ProductDetail";

const ProductDetailLayout = ({ cardDetail }) => {
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);
  const [product, setProduct] = useState({});
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);
  const [unpublishResp, setUnpublishResp] = useState("");

  const dispatch = useDispatch();
  const {myList=[]} = useSelector(state => state.pbCatalog);
  const { countryName, psaName, subCat, itemID } = useParams();
  const decodedSubCat= decodeURIComponent(subCat);
  const decodedPsaName= decodeURIComponent(psaName);
  const navigate = useNavigate();
  const { enqueueSnackbar} = useSnackbar();
  const { isAdmin=false } = useSelector(state => state.userManagement);

  useEffect(() => {
    fetchProduct();
  }, []);
  
  const fetchProduct = () => {
    setIsLoadingProduct(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${pb_service}/api/itemmaster/originID/?itemOriginID=${itemID}`,
    };
    
    axios.request(config)
    .then((response) => {
      let validatedRes = resValidation(response);
      let pdt = validatedRes.data.data
      setProduct(pdt);
      localStorage.setItem("psa_id", getHierarchyDetail(pdt.itemHierarchies,1).id);
      setCategoryDetail([countryName, decodedPsaName, decodedSubCat,pdt.shortDescription]);
      setIsLoadingProduct(false);
      trackMixPanelEvent({module: 'PRIVATE_BRAND_CATALOG', action: 'PRODUCT_PAGE', metadata: {pbCountry: countryName, category: decodedPsaName, subcategory: decodedSubCat, product: pdt.shortDescription}})
    })
    .catch((error) => {
      console.error(error);
      setIsLoadingProduct(false);
      setApiFailReported(true);
    });

  }

  const activeUsername = localStorage.getItem("userpermission");

  const handleUnpublishBtnClick = () => {
    setShowUnpublishModal(true);
  };


  const handleSeeCategory = (subCat) => {
    const encodedString = encodeURIComponent(subCat);
    navigate(`/privateBrandCatalog/category/${encodedString}`);
  };

  const displayToastMsg = (toastObj) => {
    enqueueSnackbar(toastObj.message, {
        variant: toastObj.variant,
        preventDuplicate: true,
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });
  };

  const handleUnpublishConfirmation = (selectedBtn) => {
    setShowUnpublishModal(false);
    if (selectedBtn === "cancel") {
      return;
    }
    const id=parseInt(itemID);
    unpublishItem(id)
      .then((response)=>{
        setUnpublishResp("success");
        const isAdded=isItemAdded(id)
        if(isAdded){
          handleButtonClick(id, isAdded);
        };
        navigate(-1);
        return displayToastMsg({message: "Product unpublished successfully", variant: 'success'});
      })
      .catch((error)=>{
        console.error(error);
        setUnpublishResp("fail");
        return displayToastMsg({message: "Something went wrong", variant: 'error'});
      })
  };

  const isItemAdded = (itemId) => {
    return myList.some(item => item === itemId);
  };

  const handleButtonClick = (itemID,itemIsAdded) => {
    let updatedList;
    if (itemIsAdded) {
       updatedList = myList.filter(listItem => listItem !== itemID);
    } else {
       updatedList = [...myList, itemID];
    }
    dispatch(addItemToList(updatedList));
  };
const isAdded= isItemAdded(parseInt(itemID));

  return (
    <Wrapper>
		{isLoadingProduct && <LoadingIndicator />}
      <PBCHeader
        categoryDetail={categoryDetail}
        handleSeeCategory={handleSeeCategory}
        pageType={pageType}
      />
      <DisclaimerUtil></DisclaimerUtil>
      <FilterItemWrapper>
        {/* <FilterLayout /> */}
        {apiFailReported ?
        <CommonErrorFallback/> :
        <ImageDetailWrapper>
          {isLoadingProduct ? "" : 
		  <ItemNameContainer>
			<span className={`fi fi-${product?.country?.toLowerCase()}`}></span>{`${product.shortDescription}`}
		  </ItemNameContainer>}
          <ImageDetailContainer>
            <ImageButtonContainer>
              <ItemImgContainer><ItemDetailImg
                alt="image"
                src={getImageUrl(product?.imageUrl?.[0])}
              ></ItemDetailImg></ItemImgContainer>
              {isLoadingProduct ? "" :
              <Stack direction={"row"} justifyContent={"center"} spacing={1} sx={{width:"100%"}}>
                { isAdmin && 
                  <Button variant="outlined" size="small" className="rmv-btn" onClick={()=>{handleUnpublishBtnClick()}}>
                    Unpublish
                  </Button> 
                }
                <Button variant="outlined" startIcon={isAdded? <RemoveIcon/>:<AddIcon />} size="small" className={isAdded? "rmv-btn" : "add-btn"} 
                  onClick={()=>{
                    handleButtonClick(product.itemOriginID,isAdded)
                    if(!isAdded){
                      trackMixPanelEvent({ module: 'PRIVATE_BRAND_CATALOG', action: 'ADD_TO_LIST', metadata: {itemId: product.itemOriginID}})
                    }
                  }}>
              {isAdded ? 'Remove' : 'Add to List'}
              </Button>
              </Stack>}
            </ImageButtonContainer>
            <TableContainer>
              <ProductTable data={product} />
            </TableContainer>
          </ImageDetailContainer>
        </ImageDetailWrapper>}
      </FilterItemWrapper>
      <UnpublishConfirmation show={showUnpublishModal} handleUnpublishConfirmation={handleUnpublishConfirmation}/>
      <ResolutionDisclaimer />
    </Wrapper>
  );
};
export default ProductDetailLayout;
