import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../Common/CommonButton';
import { useSelector } from 'react-redux';
import '../BrandedMerchandise.scss';
import { BRANDED_MERCHANDISE } from '../../../../Constants/Constant';

const ManagementHeader = ({ pageType, setPageType }) => {
  const navigate = useNavigate();
  const { bmList = [] } = useSelector((state) => state.bmCollection);
  const { isAdmin = false } = useSelector((state) => state.userManagement);

  const getButtonText = () => {
    return pageType === 'collections' ? '+ CREATE COLLECTION' : '+ ADD PRODUCT';
  };

  const getTooltipText = () => {
    return pageType === 'collections' ? 'Create your Collection' : 'Add a Product';
  };

  return (
    <div className="mh-header-wrapper">
      <h2>{BRANDED_MERCHANDISE} Management</h2>
      <div className="subheading-wrapper">
        <div
          className={`subheading ${pageType === 'collections' ? 'active' : ''}`}
          onClick={() => setPageType('collections')}
        >
          COLLECTIONS
        </div>
        <div
          className={`subheading ${pageType === 'products' ? 'active' : ''}`}
          onClick={() => setPageType('products')}
        >
          PRODUCTS
        </div>
      </div>
      <div className="heading-button-wrapper">
        <div className="button-wrapper">
          <CommonButton
            buttonText={getButtonText()}
            tooltipText={getTooltipText()}
            routePath={pageType === 'collections' ?'/create-edit-collection':'/add-edit-product'}
          />
        </div>
      </div>
    </div>
  );
};

export default ManagementHeader;
 