import React, { useEffect } from "react";
import HorizontalSectionMenu from "../../../components/Assets/Common/HorizontalSectionMenu/HorizontalSectionMenu";
import CustomCard from "../../Assets/Common/CustomCard/CustomCard";
import "./Sustainability.scss";
import Planet from "../../../assets/images/PlannetOutline.png";
import People from "../../../assets/images/People.png";
import Product from "../../../assets/images/ProductOrder.png";
import { countryNames, sustainabilityMapUrl } from "../../../Constants/Constant";
import GlobalPresenceChart from "../../../components/Assets/Common/GlobalPresenceChart/GlobalPresenceChart";
import PLANET_Thailand from "../../../assets/images/PLANET_Thailand.png";
import PLANET_Australia from "../../../assets/images/PLANET_Australia.png";
import PLANET_Malaysia from "../../../assets/images/PLANET_Malaysia.png";
import PLANET_Scandinavia from "../../../assets/images/PLANET_Scandinavia.png";
import PLANET_Vietnam from "../../../assets/images/PLANET_Vietnam.png";
import PEOPLE_Philippines from "../../../assets/images/PEOPLE_Philippines.png";
import PEOPLE_Thailand from "../../../assets/images/PEOPLE_Thailand.png";
import PEOPLE_Australia from "../../../assets/images/PEOPLE_Australia.png";
import PEOPLE_Taiwan from "../../../assets/images/PEOPLE_Taiwan.png";
import PEOPLE_Sweden_1 from '../../../assets/images/PEOPLE_Sweden_1.png';
import PEOPLE_Sweden_2 from '../../../assets/images/PEOPLE_Sweden_2.png';
import { trackMixPanelEvent } from "../../../mixpanel/mixpanel";

const Sustainability = () => {
  useEffect(()=>{
    trackMixPanelEvent({module: 'SUSTAINABILITY'})
  },[])
  const sections = [
    { id: "planet", label: "PLANET" },
    { id: "people", label: "PEOPLE" },
    { id: "product", label: "PRODUCT" },
  ];

  const planetCardDetails = [
    {
      id: "thailand",
      img: PLANET_Thailand,
      heading: "Thailand",
      subHeading: "7 Go Green Plastic Reduction for Life and Environment",
      details:
        "The program encourages customers to decline plastic bags rewarding them with points that leads to the planting of 1M trees in the community.",
    },
    {
      id: "scandinavia",
      img: PLANET_Scandinavia,
      heading: "Scandinavia",
      subHeading:
        "ConveNudge Shifting Towards Sustainable and Healthy Food Choices",
      details:
        "Nudging campaign across 3 markets to encourage customers to purchase products that are better for the planet, people’s health and the business than alternative purchases.",
    },
    {
      id: "australia",
      img: PLANET_Australia,
      heading: "Australia",
      subHeading: "7-Eleven and The Salvation Army 'Store to Corp’ Program",
      details:
        "The program takes food waste that would otherwise end up in landfill and redirects them to support the work of The Salvation Army Australia.",
    },
    {
      id: "malaysia",
      img: PLANET_Malaysia,
      heading: "Malaysia",
      subHeading: "#7EGoGreen Project",
      details:
        "Promoting reduction, reuse and recycling through the BYOC (bring your own cup) initiative in stores and Waste2Life campaign in the community.",
    },
    {
      id: "vietnam",
      img: PLANET_Vietnam,
      heading: "Viet Nam",
      subHeading: "Eco Shopping Bags",
      details:
        "The program educates customers to reduce single use plastic at stores and promotes reuse by providing free and/or discount offerings for reusable shopping bags.",
    },
  ];

  const peopleCardDetails = [
    {
      id: "Philippines",
      img: PEOPLE_Philippines,
      heading: "Philippines",
      subHeading: "Everyday Play",
      details:
        "The program promotes child-friendly and healthy communities by increasing access to green spaces like public playgrounds. It aims to build one playground in each city and municipality where philippine Seven Corp operates.",
    },
    {
      id: "Thailand",
      img: PEOPLE_Thailand,
      heading: "Thailand",
      subHeading:
        "Boonnithi Project",
      details:
        "This project aims to improve the health and well-being of vulnerable populations by providing school restrooms, AED devices, and shelters for stray animals.",
    },
    {
      id: "Taiwan",
      img: PEOPLE_Taiwan,
      heading: "Taiwan",
      subHeading: "Good Neighbors Funfest: Embracing Diversity and Inclusion",
      details:
        "The project aims to boost community ties, focusing on parent-child interaction, elderly learning, and sustainability, engaging families, seniors, and disadvantaged groups.",
    },
    {
      id: "Australia",
      img: PEOPLE_Australia,
      heading: "Australia",
      subHeading: "Uniform upcycling program",
      details:
        "In partnership with 'Loop Upcycling', this program will upcycle 1,700 store uniforms into blankets, laptop covers and sleeping bags for migrants and refugees through The Salvation Army Australia.",
    },
    {
      id: "sweden_1",
      img: PEOPLE_Sweden_1,
      heading: "Sweden",
      subHeading: "ConveNudge 2.0",
      details:
        "The ‘ConveNudge 2.0’ project promotes healthier, sustainable consumption by reshaping product assortments and influencing customer behavior, focusing on low-income groups to democratize access to convenient, sustainable food choices.",
    },
    {
      id: "sweden_2",
      img: PEOPLE_Sweden_2,
      heading: "Sweden",
      subHeading: "Convenient Community — Improving Health Among Swedish Youth",
      details:
        "The ‘Convenient Community: Improving Health Among Swedish Youth’ project tackles youth mental health by promoting physical activity, reducing screen time, and fostering social connections. In partnership with Generation Pep and Friends, 7-Eleven Sweden aims to engage communities and build support networks through its stores.",
    },
  ];

  const screenWidth = window.innerWidth;
  let i=3;
  if (screenWidth <= 1050) {
    i=2
  } 

  const planetRows = Math.floor(planetCardDetails.length / i);
  const planetRemaining = planetCardDetails.length % i;
  const planetGroupedCards = planetCardDetails.slice(0, planetRows * i);
  const planetRemainingCards = planetCardDetails.slice(planetRows * i);

  const peopleRows = Math.floor(peopleCardDetails.length / i);
  const peopleRemaining = peopleCardDetails.length % i;
  const peopleGroupedCards = peopleCardDetails.slice(0, peopleRows * i);
  const peopleRemainingCards = peopleCardDetails.slice(peopleRows * i);

  return (
    <div className="sustainability-page-wrapper">
      <div className="sustainability-page-header">
        <div className="sustainability-headings sustainability-layout">
          <h2>7-Eleven International Sustainability</h2>
          <h5>
            With 7-Eleven’s global presence comes a responsibility to engage
            with global issues. As the global stewards of 7-Eleven, we challenge
            ourselves to advance our business and foster a positive societal and
            environmental impact.
          </h5>
        </div>
        <div className="page-section-header">
          <HorizontalSectionMenu sections={sections} />
        </div>
      </div>
      <div id="planet" className="section-content-planet sustainability-layout ">
        <div className="section-heading">
          <div className="heading-img-wrapper">
            <img src={Planet} alt="planet" />
            <h3>Planet</h3>
          </div>
          <h4>Protecting the earth by innovating for a sustainable tomorrow</h4>
          <h5>
            The communities we serve are the communities we’re from. We’re proud
            to support the many initiatives that come directly from our
            communities: for our people, our products, and our planet.
          </h5>
        </div>
        <div className="sustainability-section-content ">
          {planetGroupedCards.map((card) => (
            <CustomCard card={card} />
          ))}
        </div>
        {planetRemaining > 0 && (
          <div className={`sustainability-section-content remaining-${planetRemaining}`}>
            {planetRemainingCards.map((card) => (
              <CustomCard card={card} />
            ))}
          </div>
        )}
      </div>
      <div id="people" className="section-content-people sustainability-layout">
        <div className="section-heading">
          <div className="heading-img-wrapper">
            <img src={People} alt="people" />
            <h3>People</h3>
          </div>
          <h4>Strengthen community by investing in our neighborhoods</h4>
          <h5>
            The communities we serve are the communities we’re from. We’re proud
            to support the many initiatives that come directly from our
            communities: for our people, our products, and our planet.
          </h5>
        </div>
        <div className="sustainability-section-content">
          {peopleGroupedCards.map((card) => (
            <CustomCard card={card} />
          ))}
        </div>
      </div>
      <div id="product" className="section-content-product sustainability-layout">
        <div className="section-heading">
          <div className="heading-img-wrapper">
            <img src={Product} alt="product" />
            <h3>Product</h3>
          </div>
          <h4>
            Consume responsibly by increasing access to safe & sustainable
            products
          </h4>
        </div>
        <div className="sustainability-section-content" style={{justifyContent: 'center', display: 'flex'}}>
          <h5>Coming soon!!</h5>
        </div>
      </div>
      <div
        id="initiatives"
        className="section-content-initiatives sustainability-layout"
      >
        <div className="heading-img-wrapper">
          <img src={Planet} alt="planet" />
          <h3>
            Learn more about 7-Eleven sustainability initiatives around the
            world!
          </h3>
        </div>
        <GlobalPresenceChart
          countryNames={countryNames}
          urlList={sustainabilityMapUrl}
        />
      </div>
    </div>
  );
};

export default Sustainability;
