import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bmList: JSON.parse(localStorage.getItem('bm_my_list')) || [], 
  bmListData: [],
};

const BMCollectionSlice = createSlice({
  name: 'bmCollection',
  initialState,
  reducers: {
    populateBMList(state, action) {
      const myListData = action.payload;
      if(!myListData)
        return;
      state.bmList = myListData.map((listData) => ({
        id: `${listData.id}`,
        count: listData.count,
      }));
      localStorage.setItem("bm_my_list", JSON.stringify(state.bmList));
      localStorage.setItem("bm_my_list_timestamp", new Date().toISOString());
    },
    
    addItemToBMList(state, action) {
      const { id, count } = action.payload;
      const existingItemIndex = state.bmList.findIndex(item => `${item.id}` === `${id}`);

      if (existingItemIndex !== -1) {
        if (count === 0) {
          state.bmList.splice(existingItemIndex, 1);
        } else {
          state.bmList[existingItemIndex].count = count;
        }
      } else if (count > 0) {
        state.bmList.push({ id: `${id}`, count });
      }
      localStorage.setItem('bm_my_list', JSON.stringify(state.bmList));
      localStorage.setItem("bm_my_list_timestamp", new Date().toISOString());
    },

    deleteItemFromBMList(state, action) {
      const { validItemIds } = action.payload;
      state.bmList = state.bmList.filter(item => validItemIds.includes(item.id));
      localStorage.setItem('bm_my_list', JSON.stringify(state.bmList));
      localStorage.setItem("bm_my_list_timestamp", new Date().toISOString());
    },


    addItemsDataToBMList(state, action) {
      state.bmListData = action.payload;
    },
  },
});

export const { addItemToBMList, addItemsDataToBMList, populateBMList, deleteItemFromBMList } = BMCollectionSlice.actions;
export default BMCollectionSlice.reducer;
