import React from 'react';
import PropTypes from 'prop-types';
import './BadgeComponent.scss'; 
const Badge = ({ label }) => {
  const isNewUpdates = label === 'NEW UPDATES';
  return (
    <div className={`badge ${isNewUpdates ? 'badge-new-updates' : ''}`}>
      {label}
    </div>
  );
};

Badge.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Badge;
