import { React, useState, useEffect, useContext, useRef, forwardRef } from "react";
import {
  CognitoIdToken,
  CognitoAccessToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Assets.css";
import l1 from "../Assets/asset-images/digital-logo-icon.png";
import l2 from "../Assets/asset-images/brand-standard-icon.png";
import l3 from "../Assets/asset-images/cash-register-icon.png";
import l4 from "../Assets/asset-images/new-market-entry-icon.png";
import l5 from "../Assets/asset-images/Toolkit-icon.png";
import l6 from "../Assets/asset-images/workshop-icon.png";
import l7 from "../Assets/asset-images/7-ElevenGTLogo.png";
import l8 from "../Assets/asset-images/newsletter-icon.png"
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { UserContext, NameContext } from "../../App";
import { MdOutlineGroups } from "react-icons/md";
import { login_event, cognito_userpool_id, region } from "../../config/environment";
import { checkFeatureAccess } from "../../Utils/CommonUtils";
import { trackLoggedinUser, trackMixPanelEvent } from "../../mixpanel/mixpanel"
import { BRAND_STANDARDS, LOGOS_DIGITAL_ASSETS, NEWSLETTER, POP_SIGNAGE, SOCIAL_MEDIA_ASSETS, TRAINING_MATERIAL, WORKSHOP_MATERIALS } from "../../Constants/Constant";
var AWS = require("aws-sdk");
var qs = require("qs");
var axios = require("axios");
function Assets(props) {
  const { t } = useTranslation();

  const { state, dispatch } = useContext(UserContext);
  const { name, dispatchName } = useContext(NameContext);
  const isMounted = useRef(false);
  const [userDetails, setUserDetails] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [folderClicked, setFolderClicked] = useState(false);

  
  // const login_event = process.env.REACT_APP_LOGIN_EVENT;
  // const region = process.env.REACT_APP_REGION;
  // const cognito_userpool_id = process.env.REACT_APP_COGNITO_POOL_ID;
  const checkAccessToken = localStorage.getItem("access_token");
  //get session
  const idT = new CognitoIdToken({ IdToken: localStorage.getItem("id_token") });
  const accessT = new CognitoAccessToken({
    AccessToken: localStorage.getItem("access_token"),
  });
  const refreshT = new CognitoAccessToken({
    AccessToken: localStorage.getItem("refresh_token"),
  });
  const session = new CognitoUserSession({
    IdToken: idT,
    AccessToken: accessT,
    RefreshToken: refreshT,
  });

  if (session.accessToken.payload.auth_time) {
    localStorage.setItem("auth_time", session.accessToken.payload.auth_time);
  }
  if (session.accessToken.payload.exp) {
    localStorage.setItem("expiry_time", session.accessToken.payload.exp);
  }

  useEffect(() => {
    if(folderClicked && props.bucketError && !openAlert) 
      setOpenAlert(true);
  }, [folderClicked])
  //fetch user details using access_token

  AWS.config.region = region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: cognito_userpool_id,
  });

  var params = {
    AccessToken: localStorage.getItem("access_token"),
  };

  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  var userPermission = localStorage.getItem("userpermission");

  useEffect(() => {
    if (isMounted.current && !userDetails && !userPermission) {
      fetchUserDetails();
    } else isMounted.current = true;
  }, [isMounted.current, userPermission]);

  function fetchUserDetails() {
    cognitoidentityserviceprovider.getUser(params, function (err, data) {
      if (err) {
      } else {
        checkLogin();
        if (!userDetails) {
          setUserDetails(JSON.parse(JSON.stringify(data)));
          isMounted.current = false;
          props.setCallFetchBucket();
          let userDetails = JSON.parse(JSON.stringify(data))
          trackLoggedinUser(userDetails)
        }
      }
    });
  }

  const ToggleAlert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const checkLogin = async () => {
    try {
      var data = qs.stringify({
        'accessToken': checkAccessToken,
      });
      var config = {
        method: "post",
        url: login_event,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {})
        .catch(function (error) {});
    } catch (err) {}
  };

  if (userDetails) {
    let check_permission = userDetails.UserAttributes.find((param) => {
      if(param['Name']==='given_name') 
        return param['Value']; 
      else 
        return null;
      });
    let checkPermission = check_permission ? check_permission['Value'] : "user";
    localStorage.setItem(
      "userpermission",
      checkPermission
    );
    localStorage.setItem("username", userDetails.Username);
    // if (Object.keys(userDetails.UserAttributes).length >= 6) {
    //   localStorage.setItem(
    //     "userpermission",
    //     userDetails.UserAttributes[5].Value
    //   );
    //   localStorage.setItem("username", userDetails.Username);
    // } else {
    //   localStorage.setItem("userpermission", "user");
    //   localStorage.setItem("username", userDetails.Username);
    // }
  }

  //TODO: need to add user access check implemented through RBAC

  let renderAssets;
  if (checkAccessToken) {
    dispatch({ type: "USER", payload: true });
    dispatchName({ type: "username", payload: userDetails.Username });
    renderAssets = (
      <div className="dAMHeadingContainer">
        <div className="dAMHeading">
          <h1
            style={{
              fontSize: "18px",
              padding: "0.5rem 1rem",
              marginLeft: "0.9rem",
              width: "100%",
            }}
          >
            {t("DIGITAL_ASSETS")}
          </h1>
        </div>
        <div className="container1 assetsCont">
          <nav className="navlinksAssets">
            {
              <Snackbar
                id="postPopup"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openAlert}
                autoHideDuration={3000}
                onClose={() => {
                  setFolderClicked(false);
                  setOpenAlert(false);
                }}
                key={"top" + "right"}
              >
                <ToggleAlert
                  onClose={() => {
                    setFolderClicked(false);
                    setOpenAlert(false);
                  }}
                  severity={"error"}
                  sx={{ width: "100%" }}
                >
                  Some error occurred. Please try again in sometime.
                </ToggleAlert>
              </Snackbar>
            }
            <div onClick={()=> {
              if(props.bucketError) {
                  props.setCallFetchBucket(); 
                  setFolderClicked(true);
                }
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'LOGOS_DIGITAL_ASSETS'})
              }} 
              className="navlinksAssetsItems"
            >
              <Link to={props.bucketError ? "/assets" : "/Digital-assets"} className="linksToDA" end>
                <img className="images-1" src={l1} alt="digital asset logo" />
                <p className="icon-name">{LOGOS_DIGITAL_ASSETS}</p>
              </Link>
            </div>
            {checkFeatureAccess(BRAND_STANDARDS, "view") && <div onClick={()=> {
              if(props.bucketError) {
                  props.setCallFetchBucket(); 
                  setFolderClicked(true);
                }
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'BRAND_STANDARDS'})
              }} 
              className="navlinksAssetsItems"
            >
              <Link to={props.bucketError ? "/assets" : "/Brand-Standards"} className="linksToDA">
                <img className="images" src={l2} alt="digital asset logo" />
                <p className="icon-name">{t("BRAND_STANDARDS")}</p>
              </Link>
            </div>}
            {checkFeatureAccess(SOCIAL_MEDIA_ASSETS, "view") && <div onClick={()=> {
              if(props.bucketError) {
                  props.setCallFetchBucket(); 
                  setFolderClicked(true);
                }
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'SOCIAL_MEDIA_ASSETS'})
              }} 
              className="navlinksAssetsItems"
            >
              <Link to={props.bucketError ? "/assets" : "/Social%20Media%20Assets"} className="linksToDA">
                <MdOutlineGroups className="images" size={40} />
                <p className="icon-name">{t("SOCIAL_MEDIA_ASSETS")}</p>
              </Link>
            </div>}
            {checkFeatureAccess(WORKSHOP_MATERIALS, "view") && <div onClick={()=> {
              if(props.bucketError) {
                  props.setCallFetchBucket(); 
                  setFolderClicked(true);
                }
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'WORKSHOP_MATERIALS'})
              }} 
              className="navlinksAssetsItems"
            >
              <Link to={props.bucketError ? "/assets" : "/Workshop-Materials"} className="linksToDA">
                <img className="images" src={l6} alt="digital asset logo" />
                <p className="icon-name">{t("WORKSHOP_SUMMIT_MATERIALS")}</p>
              </Link>
            </div>}
            {checkFeatureAccess(POP_SIGNAGE, "view") && <div className="navlinksAssetsItems"
              onClick={()=>{
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'POP_SIGNAGE'})
              }}
            >
              <Link to="/Page-under-construction" className="linksToDA">
                <img className="images" src={l3} alt="digital asset logo" />
                <p className="icon-name">{t("POP_SIGNAGE")}</p>
              </Link>
            </div>}
            {checkFeatureAccess(TRAINING_MATERIAL, "view") && <div className="navlinksAssetsItems"
              onClick={()=>{
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'TRAINING_MATERIAL'})
              }}
            >
              <Link to="/Page-under-construction" className="linksToDA">
                <img className="images" src={l7} alt="digital asset logo" />
                <p className="icon-name">{t("TRAINING_MATERIAL")}</p>
              </Link>
            </div>}
            {checkFeatureAccess(NEWSLETTER, "view") && <div onClick={()=> {
              if(props.bucketError) {
                  props.setCallFetchBucket(); 
                  setFolderClicked(true);
                }
                trackMixPanelEvent({module: 'DIGITAL_ASSETS', action: 'NEWSLETTER'})
              }} 
              className="navlinksAssetsItems"
            >
              <Link to={props.bucketError ? "/assets" : "/Newsletter"} className="linksToDA">
                <img className="images" src={l8} alt="digital asset logo" />
                <p className="icon-name">{NEWSLETTER}</p>
              </Link>
            </div>}
          </nav>
        </div>
      </div>
    );
  } else {
    dispatch({ type: "USER", payload: false });
    renderAssets = <h4 className="container1-h1">{t("Suspense_Loading")}</h4>;
  }

  return renderAssets;
}
export default Assets;
