import mixpanel from "mixpanel-browser";
import { getUserName, getReduxStateBySlice } from "../Utils/CommonUtils";
import { MIXPANEL_TRACKING } from "../Constants/Constant";
import { env, mixpanel_token } from '../config/environment'


mixpanel.init(mixpanel_token);

export const trackLoggedinUser = (userData) => {
  mixpanel.identify(userData.Username);
  setTimeout(() => {

    const emailVal =
      userData.UserAttributes.find((attr) => attr.Name === "email")?.Value ||
      "";
    mixpanel.people.set(userData.Username, {
      $email: emailVal,
    });

    trackMixPanelEvent({
      module: "USER_LOG",
      action: "USER_LOGGED_IN",
      metadata: { username: userData.Username, email: emailVal },
    });
  }, 2000);
};

export const trackUserLogout = () => {
  trackMixPanelEvent({
    module: 'USER_LOG',
    action: 'USER_LOGGED_OUT',
    metadata: { timestamp: new Date().toISOString() }
  });

  mixpanel.reset();
}

const getMessage = (module, action) => {
  if (action) {
    return MIXPANEL_TRACKING[module]?.action?.[action]?.message || 'Action Tracked';
  }
  return MIXPANEL_TRACKING[module]?.message || 'Event Tracked';
};

const getEventType = (module, action) => {
  if (action) {
    return MIXPANEL_TRACKING[module]?.action?.[action]?.type || 'ACTION';
  }
  return MIXPANEL_TRACKING[module]?.type || 'EVENT';
};


export const trackMixPanelEvent = ({module, action = null, metadata={} }) => {
  if (!MIXPANEL_TRACKING[module]?.tracked) return;

  if (action && !MIXPANEL_TRACKING[module]?.action?.[action]?.tracked) return;

  const eventType = getEventType(module, action);
  const message = getMessage(module, action);

  let userCountryCompany = {};
  if(getUserName() !== 'public') {
    const { userCountry, userCompany } = getReduxStateBySlice("userManagement");
    userCountryCompany = {
      userCountry,
      userCompany
    }
  }
  const mixpanelTrackPayload = {
    ...userCountryCompany,
    distinct_id: getUserName() || 'public',
    user_id: getUserName() || 'public',
    username: getUserName() || 'public',
    env: env,
    source: '7IN Website',
    eventType,
    ...metadata
  }
  mixpanel.track(message, mixpanelTrackPayload);
}

