import React, { useState, useEffect } from "react";
import LayoutComponent from "../Layout/LayoutComponent";
import "./Careers.scss";
import "../../components/Footer/ContactUs"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { AiFillDelete } from "react-icons/ai";
import { Button, Pagination, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { sanitizeContentToRender } from "../../Utils/CommonUtils";
import { add_career, delete_career, get_careers, update_career } from "../../config/environment";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import ContactUs from "../Assets/Common/ContactUs";
import { trackMixPanelEvent } from "../../mixpanel/mixpanel";
var axios = require("axios");
var qs = require("qs");

const Careers = () => {
  const [isValid, setIsValid] = useState(false);
  const [addJob, setAddJob] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [jobToDisplay, setJobToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [urlFocused, setUrlFocused] = useState(false);
  const [formData, setFormData] = useState({
    jobTitle: "",
    location: "",
    category: "",
    description: "",
    url: "",
  });

  // const add_career = process.env.REACT_APP_ADD_CAREER;
  // const delete_career = process.env.REACT_APP_DELETE_CAREER;
  // const get_careers = process.env.REACT_APP_GET_CAREERS;
  // const update_career = process.env.REACT_APP_UPDATE_CAREER;

  const checkAccess = localStorage.getItem("access_token");
  const jobsPerPage = 5;
  const totalPages = Math.ceil(fetchedData.length / jobsPerPage);

  const { isAdmin=false } = useSelector(state => state.userManagement);

  useEffect(() => {
    trackMixPanelEvent({module: 'CAREERS'})
    getJobs();
  }, []);

  useEffect(() => {
    var check = Validate();
    setIsValid(!check);
  }, [formData]);

  useEffect(() => {
    if (currentPage && fetchedData.length) {
      setJobToDisplay(
        fetchedData.slice(
          (currentPage - 1) * jobsPerPage,
          currentPage * jobsPerPage
        )
      );
    }
  }, [currentPage]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleUrlFocus = (e) => {
    setUrlFocused(true);
  };

  const Validate = () => {
    return (
      !formData.jobTitle?.replace(/\s/g, "").length ||
      !formData.location?.replace(/\s/g, "").length ||
      !formData.category?.replace(/\s/g, "").length ||
      !formData.url?.replace(/\s/g, "").length ||
      !formData.description?.replace(/\s/g, "").length
    );
  };

  const getJobs = async () => {
    let axiosData = qs.stringify({
      search: { flag: false },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: get_careers,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: axiosData,
    };
    axios
      .request(config)
      .then((response) => {
        const resp = response.data.body.message;
        setFetchedData(resp);
        const jobsToList = resp.slice(0, jobsPerPage);
        setJobToDisplay([...jobsToList]);
      })
      .catch((error) => {});
  };

  const handleSubmit = async () => {
    const arrayPayload = [formData];
    let currentUrl, data, axiosData;

    if (editJob) {
      data = formData;
      currentUrl = update_career;
      let objectId = formData._id;
      delete formData["_id"];
      axiosData = qs.stringify({
        objectId: objectId,
        updates: data,
        accessToken: checkAccess,
      });
    } else {
      data = arrayPayload;
      currentUrl = add_career;
      axiosData = qs.stringify({
        careers: data,
        accessToken: checkAccess,
      });
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: currentUrl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: axiosData,
    };
    axios
      .request(config)
      .then((response) => {
        getJobs();
      })
      .catch((error) => {});

    closeAddJobModal();
  };

  const handleDelete = (item) => {
    let axiosData = qs.stringify({
      objectId: item.item._id,
      accessToken: checkAccess,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: delete_career,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: axiosData,
    };
    axios
      .request(config)
      .then((response) => {
        getJobs();
      })
      .catch((error) => {});
  };

  const handleEdit = (item) => {
    setAddJob(true);
    setEditJob(true);
    setFormData({ ...item.item });
  };

  const handleApply = (url) => {
    window.open(url, "_blank");
  };

  const readMoreHandler = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const closeAddJobModal = (index) => {
    setIsValid(false);
    setAddJob(false);
    if (editJob) {
      setEditJob(false);
    }
    if (urlFocused) {
      setUrlFocused(false);
    }
    setFormData({
      jobTitle: "",
      location: "",
      category: "",
      description: "",
      url: "",
    });
  };

  function SubmitButton() {
    return (
      <Button
        variant="contained"
        className={isValid ? "submitBtn" : "submitBtn-disabled"}
        disabled={!isValid}
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        Submit
      </Button>
    );
  }

  const DeleteEditIcons = (item) => {
    if (isAdmin) {
      return (
        <div
          className="deleteEditContainer"
          style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
        >
          <Button
            variant="outlined"
            size="large"
            style={{ color: "#147350", borderColor: '#147350' }}
            onClick={() => {
              handleEdit(item);
            }}
          >
            Edit
          </Button>

          <Tooltip title="Delete" arrow>
            <IconButton
              size="large"
              onClick={() => {
                setDeleteModal(item);
              }}
            >
              <AiFillDelete
                style={{ fill: "#147350" }}
                className="deleteEditIcon"
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    } else {
      return <></>;
    }
  };
  

  const JobList = () => {
    if (!jobToDisplay.length) {
      return "";
    }
    return jobToDisplay.map((item, index) => (
      <div className="jobLi" key={`job-${index}`}>
        <div class="headingBtnWrapper">
          <h3 style={{ fontSize: "1.5rem", fontFamily: "Roboto Condensed" }}>
            {item.jobTitle}
          </h3>
          <div class="jobSubHeadingContainer">
            <p class="para">Location: {item.location}</p>
            <p class="para">Category: {item.category}</p>
          </div>
        </div>

        <div className="jobDescWrapper">
          {item.description.length > 215 ? (
            <>
              {!(expandedIndex === index) ? (
                <div className="iconWrapper">
                  <div className="text-wrapper">
                    <p class="para" style={{ width: "90%" }}>
                      {parse(sanitizeContentToRender(item.description))}
                    </p>
                    {/* <p
                      style={{ width: "90%" }}
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    /> */}
                  </div>
                  <Button
                    className="readMoreSpan"
                    variant="text"
                    onClick={() => {
                      readMoreHandler(index);
                    }}
                  >
                    SHOW MORE
                  </Button>
                </div>
              ) : (
                <div className="iconWrapper">
                  <p className="para" style={{ width: "90%" }}>
                    {parse(sanitizeContentToRender(item.description))}
                  </p>
                  {/* <p
                    style={{ width: "90%" }}
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  /> */}
                  <Button
                    className="readMoreSpan"
                    variant="text"
                    color="primary"
                    onClick={() => {
                      readMoreHandler(index);
                    }}
                  >
                    SHOW LESS
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="iconWrapper">
              <p className="para" style={{ width: "90%" }}>
                {parse(sanitizeContentToRender(item.description))}
              </p>
              {/* <p
                style={{ display: "inline-block", width: "90%" }}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              /> */}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            className="applyBtn"
            style={{marginLeft: 0}}
            onClick={() => handleApply(item.url)}
          >
            APPLY NOW
          </Button>

          <DeleteEditIcons item={{ ...item }} />
        </div>
      </div>
    ));
  };

  return (
    <LayoutComponent>
      <div className="careersWrapper">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <h1 style={{ textAlign: 'center' }}>Careers</h1>
        {isAdmin && (
          <Button
            style={{ alignSelf: 'flex-end' }}
            variant="contained"
            size="large"
            className="addJobBtn newJobBtn"
            onClick={() => {
              setAddJob(true);
            }}
          >
            CREATE NEW JOB
          </Button>
        )}
      </div>
        
        <div className="formListWrapper">
          <div className="listContainer">
            <JobList />
          </div>
        </div>

        <div
          className={`modalWrapper ${
            !(deleteModal === null) ? "showModal" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(null);
          }}
        >
          <div
            className="modalBody"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="iconWrapper">
              <div className="confirmationContainer">
                <div className="confirmationText">
                  <h2 className="confirmHeader">Are you sure?</h2>
                  <p className="confirmMessage para">
                    Do you really want to delete this record?
                  </p>
                  <p className="confirmMessage para">
                    This Process cannot be undone.
                  </p>
                </div>
                <div className="confirmationBtnContainer">
                  <Button
                    className="cancel confirmBtn"
                    onClick={() => {
                      setDeleteModal(null);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="confirmBtn"
                    onClick={() => {
                      setDeleteModal(null);
                      handleDelete(deleteModal);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  zIndex: "1",
                  top: "-7px",
                  right: "10px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setDeleteModal(null);
                }}
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8.75"
                  fill="white"
                  stroke="#D1D1D1"
                  strokeWidth="0.5"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <path
                  d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                  fill="#000000"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Create / Edit Modal */}
        <div
          className={`modalWrapper ${addJob ? "showModal" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            closeAddJobModal();
          }}
        >
          <div
            className="modalBody"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="iconWrapper">
              <div className="formWrapper">
                <div className="formContainer">
                  {addJob && !editJob ? <h3>Create Job</h3> : <h3>Edit Job</h3>}
                  <div class="twoFieldRow">
                    <div className="inputLabelWrapper">
                      <div className=" careerFormInput">
                        <TextField
                          variant="outlined"
                          label="Job Title"
                          type="text"
                          name="jobTitle"
                          placeholder="Job Title"
                          inputProps={{
                            pattern:"^(?! )[a-zA-Z_ ]*(?<! )$",
                            maxLength:"255"
                          }}
                          required
                          onChange={handleChange}
                          value={formData.jobTitle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="twoFieldRow">
                    <div className="inputLabelWrapper">
                      <div className="careerFormInput">
                        <TextField
                          variant="outlined"
                          label="Location"
                          type="text"
                          name="location"
                          inputProps={{
                            pattern: "^(?! )[a-zA-Z0-9, ]*(?<! )$",  // Updated regex to support commas, letters, and numbers
                            maxLength: "255"
                          }}
                          placeholder="Location"
                          required
                          onChange={handleChange}
                          value={formData.location || ""}
                          error={
                            formData.location && !/^(?! )[a-zA-Z0-9, ]*(?<! )$/.test(formData.location)  // Inline validation
                          }
                          helperText={
                            formData.location && !/^(?! )[a-zA-Z0-9, ]*(?<! )$/.test(formData.location)  // Display error message
                              ? 'Location must not start or end with spaces, and may contain letters, numbers, commas, and spaces.'
                              : ''
                          }
                          FormHelperTextProps={{
                            style: { textAlign: 'left', padding: 0, marginTop: '0.5rem', marginLeft: '10px' },  // Align helper text to the left
                          }}
                        />
                      </div>
                    </div>
                    <div className="inputLabelWrapper">
                      <div className="careerFormInput">
                        <TextField
                          type="text"
                          label="Category"
                          name="category"
                          placeholder="Category"
                          // pattern="^(?! )[a-zA-Z_ ]*(?<! )$"  // Regex pattern for category validation
                          maxLength="255"
                          required
                          onChange={handleChange}
                          value={formData.category || ""}
                          error={
                            formData.category && !/^(?! )[a-zA-Z_ ]*(?<! )$/.test(formData.category)  // Inline category validation
                          }
                          helperText={
                            formData.category && !/^(?! )[a-zA-Z_ ]*(?<! )$/.test(formData.category)  // Display error if invalid
                              ? 'Category must not start or end with spaces and only contain letters, underscores, or spaces.'
                              : ''
                          }
                          FormHelperTextProps={{
                            style: { textAlign: 'left', padding: 0, marginTop: '0.5rem', marginLeft: '10px' },  // Left-align helper text
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="twoFieldRow">
                    <div className="inputLabelWrapper">
                      <div className=" careerFormInput">
                        <TextField
                          variant="outlined"
                          label="Job URL"
                          type="text"
                          name="url"
                          placeholder="Job URL"
                          inputProps={{
                            pattern: '^(ftp|http|https):\/\/[^ "]+$',
                            maxLength: '255'
                          }}
                          required
                          onChange={handleChange}
                          onClick={handleUrlFocus}
                          focused={formData.url && urlFocused.toString()}
                          value={formData.url}
                          error={
                            formData.url && !/^(ftp|http|https):\/\/[^ "]+$/.test(formData.url)  // Inline URL validation
                          }
                          helperText={formData.url && !/^(ftp|http|https):\/\/[^ "]+$/.test(formData.url) ? 'url not valid' : ''}
                          FormHelperTextProps={{
                            style: { textAlign: 'left', padding: 0, marginTop: '0.5rem', marginLeft: '10px' } // Align the helper text to the left
                          }}                      
                        />
                      </div>
                    </div>
                  </div>

                  <div className="twoFieldRow">
                    <div className="inputLabelWrapper">
                      <div className="astrixWrapper descriptionAstrixWrapper">
                        <div>
                          <h6 className="inputLabel">Description<span className="astrix">*</span></h6>
                        </div>
                      </div>
                      <div className="careerFormInput">
                        {/* {isEditorReady && ( */}
                        <CKEditor
                          data={formData.description || ""}
                          editor={DecoupledEditor}
                          config={{
                            toolbar: {
                              items: [
                                "heading",
                                "|",
                                "alignment",
                                "|",
                                "bold",
                                "italic",
                                "strikethrough",
                                "underline",
                                "|",
                                "link",
                                "|",
                                "bulletedList",
                                "numberedList",
                                "|",
                                "fontFamily",
                                "fontSize",
                                "fontColor",
                                "fontBackgroundColor",
                              ],
                            },
                            heading: {
                              options: [
                                { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                                { model: "heading3", view: "h4", title: "Heading", class: "ck-heading_heading4" },
                              ],
                            },
                            link: {
                              addTargetToExternalLinks: true,
                            },
                          }}
                          onReady={(editor) => {
                            if (editor) {
                              editor?.ui
                                ?.getEditableElement()
                                ?.parentElement?.insertBefore(
                                  editor.ui.view?.toolbar?.element,
                                  editor.ui.getEditableElement()
                                );
                            }
                          }}
                          onChange={(event, editor) => {
                            const ckEditorData = editor.getData();
                            setFormData((prevState) => ({
                              ...prevState,
                              description: ckEditorData,
                            }));
                          }}
                        />
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  
                  <div className="twoFieldRow">
                    <div className="inputLabelWrapper">
                      <div className="btn-container" style={{ display:'flex', flexDirection:'row', justifyContent: 'flex-start' }}>
                        <SubmitButton/>
                        <Button className="cancelBtn"
                          style={{ marginLeft: '1rem', color: "#147350", borderColor: '#147350' }}
                          variant="outlined"
                          onClick={() => {
                            closeAddJobModal();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  zIndex: "1",
                  top: "10px",
                  right: "10px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  closeAddJobModal();
                }}
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8.75"
                  fill="white"
                  stroke="#D1D1D1"
                  strokeWidth="0.5"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <path
                  d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                  fill="#000000"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </svg>
            </div>
          </div>
        </div>

        <div>
          {jobToDisplay.length ? (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              // color="primary"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ContactUs />
    </LayoutComponent>
  );
};

export default Careers;
