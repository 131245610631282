import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./NewsFeed.css";
import CreatePost from "./SocialMediaPosts/CreatePost";
import Feed from "./SocialMediaPosts/NewsFeedPosts";
import { UserContext } from "../../App";
import { get_user } from "../../config/environment";
import { useDispatch } from "react-redux";
import { setUserCountryAndCompany, setPBNotification, setUserAccess, setUserAsAdmin,setUserFullName } from "./UserManagement/UserManagementSlice";
import { resValidation } from "../../Utils/CommonUtils";

var qs = require("qs");
var axios = require("axios");

const NewsFeed = (props) => {
  const { t } = useTranslation();

  const [curUsername, setCurUsername] = useState("");
  const [displayPic, setDisplayPic] = useState("");

  const { state, dispatch } = useContext(UserContext);
  const reduxDispatch = useDispatch();

  // const get_user = process.env.REACT_APP_GET_USER;

  let checkAccessToken = localStorage.getItem("access_token");

  const getUserDetails = () => {
    var data = qs.stringify({
      accessToken: checkAccessToken,
    });

    var config = {
      method: "post",
      url: get_user,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        let validatedRes = resValidation(response),
        respMsg = validatedRes.data.body.message;
        setCurUsername(respMsg.userName);
        setDisplayPic(
          respMsg.profilePic
            ? respMsg.profilePic
            : null
        );
        props.setUserEmail(respMsg.userEmail);
        reduxDispatch(setUserAccess(respMsg.accessControl));
        reduxDispatch(setUserCountryAndCompany({
          userCountry: respMsg.country,
          userCompany: respMsg.company
        }));
        reduxDispatch(setUserAsAdmin(respMsg.isAdmin));
        reduxDispatch(setUserFullName(response.data.body.message.fullName));
        reduxDispatch(setPBNotification(response.data.body.message.pbCountriesWithNewUploads));
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (checkAccessToken && state) getUserDetails();
  }, [state, props.userDetailUpdate]);

  const handlePendingPostReload = () => {
    props.handleReload();
  };
  const handleDeleteReload = () => {
    props.handleDeleteReload();
  };

  return (
    <div className="newsFeedBlock">
      <CreatePost
        userName={curUsername}
        displayPic={displayPic}
        handlePendingPostReload={handlePendingPostReload}
        setProfileClicked={props.setProfileClicked}
        setUserProfile={props.setUserProfile}
      />
      <Feed
        posts={props.posts}
        isLoading={props.isLoading}
        setScrolled={props.setScrolled}
        gettingBucket={props.gettingBucket}
        refresh={props.refresh}
        setProfileClicked={props.setProfileClicked}
        setUserProfile={props.setUserProfile}
        reloadComponent={props.reloadComponent}
        handleDeleteReload={handleDeleteReload}
      />
    </div>
  );
};

export default NewsFeed;
