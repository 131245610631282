import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import './RingLoader.css'

const RingLoader = () => {
  const { t } = useTranslation();
  const {PBNotification= []} = useSelector(state=> state.userManagement);

  return <div className='loader-container'><h4 className={PBNotification.length ? "container1-h1 badge-margin" : "container1-h1"}>{t("Suspense_Loading")}</h4></div>;
};

export default RingLoader;
