import axios from "axios";
import { get_user, mylist_save, pb_service } from "../../../config/environment";
import { encodeUtil } from "../../../Utils/CommonUtils";
const qs = require("qs");
const bmFilter = encodeUtil([
  { field: "flavourID", value: "2", operator: "equal" },
  { field: "itemHierarchyTypeCodeId", value: "2", operator: "equal" },
]);
const stringifiedAccTok = JSON.stringify({
  accessToken: localStorage.getItem("access_token"),
});

export const persistMyListForBM = async (itemList, timestamp) => {
  try {
    const url = `${mylist_save}`;
    const bm = itemList.reduce((acc, curr) => {
      acc[`${curr.itemMasterId}`] = curr.count;
      return acc;
    }, {});
    const checkAccessToken = localStorage.getItem("access_token");
    const data = JSON.stringify({
      accessToken: checkAccessToken,
      myCurrentList: {
        bm,
        bmTimestamp: new Date(timestamp).toISOString()
      },
    });
    const config = {
      method: 'POST',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      data
    }
    const res = await axios(config);
    return res.data;
  }
  catch(err) {
    console.error('Error persisting data for Branded-Merchandise My List ', err);
    return false;
  }
}
export const getMyListForBM = async () => {
  try {
    const url = `${get_user}`;
    const checkAccessToken = localStorage.getItem("access_token");
    const config = {
      method: 'post',
      url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        accessToken: checkAccessToken,
      }),
    }
    const res = await axios(config);
    
    const bmDetails = res.data.body.message.myList.bm || {};
    const bmTimestamp = res.data.body.message.myList.bmTimestamp;

    const result = Object.keys(bmDetails).reduce((acc, itemId) => {
      acc.push({
        id: `${itemId}`,
        count: bmDetails[itemId]
      })
      return acc;
    }, []);
    return {result, timestamp: bmTimestamp};
  }
  catch(err) {
    console.error('Error fetching data for Branded-Merchandise My List ', err);
    return [];
  }
}

export const getCollectionsData = async ({ rowsPerPage, page, searchFilter }) => {
  const searchurl = encodeUtil([
    { field: "flavourID", value: "2", operator: "equal" },
    { field: "description", value: `${searchFilter}`, operator: "iLike" }
  ]);
  const url = searchFilter && searchFilter.length > 0
    ? `${pb_service}/api/branded-merchandise/hierarchySearch?noOfRecords=${rowsPerPage}&pageNo=${page}&sortBy=createdDatetime&sortDirection=ASC&filters=${searchurl}`
    : `${pb_service}/api/branded-merchandise/hierarchySearch?noOfRecords=${rowsPerPage}&pageNo=${page}&sortBy=createdDatetime&sortDirection=ASC&filters=${bmFilter}`;

  var config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: stringifiedAccTok,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching collections data:", error);
    return [];
  }
};

export const getCollectionTableData = async ({ rowsPerPage, page }) => {
  var config = {
    method: "get",
    url: `${pb_service}/api/branded-merchandise/hierarchySearch?noOfRecords=${rowsPerPage}&pageNo=${page}&sortBy=createdDatetime&sortDirection=DESC&filters=${bmFilter}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: stringifiedAccTok,
  };

  return await axios(config);
};

export const getProductTableData = async ({
  rowsPerPage,
  page,
  collectionId,
  searchFilter,
}) => {

  let filter = collectionId
    ? encodeUtil([
        { field: "flavourID", value: "2", operator: "equal" },
        {
          field: "itemHierarchyCodeId",
          value: `${collectionId}`,
          operator: "equal",
        },
      ])
    : bmFilter;

  if (searchFilter) {
    filter = encodeUtil([
      { field: "flavourID", value: "2", operator: "equal" },
      { field: "shortDescription", value: searchFilter, operator: "iLike" },
    ]);
  }

  const config = {
    method: "get",
    url: `${pb_service}/api/branded-merchandise/productSearch?noOfRecords=${rowsPerPage}&pageNo=${page}&status=both&sortBy=itemOriginID&sortDirection=DESC&filters=${filter}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: stringifiedAccTok,
  };

  return await axios(config);
};

export const createCollection = async (collectionData) => {
	var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...collectionData
	});

	var config = {
		method: "POST",
		url: `${pb_service}/api/branded-merchandise/collection`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const deleteCollections = async (hierarchyCodeIDs) => {
  const data = JSON.stringify({
    accessToken: localStorage.getItem("access_token"),
    hierarchyCodeIDs,
  });
  const config = {
    method: "DELETE",
    url: `${pb_service}/api/branded-merchandise/collection`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };
  return axios(config);
};

export const deleteProducts = async (itemMasterIDs) => {
  const data = JSON.stringify({
    accessToken: localStorage.getItem("access_token"),
    itemMasterIDs,
  });
  const config = {
    method: "DELETE",
    url: `${pb_service}/api/branded-merchandise`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  }
  return axios(config);
}

export const EditCollection = async (collectionData) => {
	var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...collectionData
	});

	var config = {
		method: "PUT",
		url: `${pb_service}/api/branded-merchandise/collection`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const getProductDetails = async ({ productId }) => {
  const filter = productId
    ? encodeUtil([
        { field: "flavourID", value: "2", operator: "equal" },
        {
          field: "itemHierarchyCodeId",
          value: `${productId}`,
          operator: "equal",
        },
      ])
    : bmFilter;
  var config = {
    method: "get",
    url: `${pb_service}/api/branded-merchandise/masterID?itemMasterID=${productId}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: stringifiedAccTok,
  };

  return await axios(config);
};

export const getBMProductDetails = async ({
  rowsPerPage,
  page,
  productIds,
}) => {
  const filter = productIds
    ? encodeUtil([
        { field: "flavourID", value: "2", operator: "equal" },
        { field: "itemHierarchyTypeCodeId", value: "2", operator: "equal" },
        { field: "itemMasterID", value: productIds, operator: "in" },
      ])
    : bmFilter;
  var config = {
    method: "get",
    url: `${pb_service}/api/branded-merchandise/productSearch?noOfRecords=${rowsPerPage}&pageNo=${page}&status=both&sortBy=itemOriginID&sortDirection=DESC&filters=${filter}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: stringifiedAccTok,
  };

  return await axios(config);
};
export const addProduct = async (productData) => {
  var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...productData
	});
  var config = {
		method: "POST",
		url: `${pb_service}/api/branded-merchandise`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const editProduct = async (productData) => {
  var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...productData
	});
  var config = {
		method: "PUT",
		url: `${pb_service}/api/branded-merchandise`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const emailMe = async (productData) => {
  var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...productData
	});
  var config = {
		method: "POST",
		url: `${pb_service}/api/branded-merchandise/sendInterest`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return await axios(config);
};


export const updateProductOrder = async (productData) => {
  var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...productData
	});
  var config = {
		method: "PATCH",
		url: `${pb_service}/api/branded-merchandise/uirank`,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};