import DOMPurify from 'dompurify';
import validator from 'validator';
import { app_url } from '../config/environment';
import store from "../components/Assets/PrivateBrandCatalog/Store";
import { countryMapper } from '../Constants/Constant';
import CountryConfig from '../config/countryConfig.json'
import NoImage from '../components/Assets/PrivateBrandCatalog/assets/NoImage.png';

const axios = require("axios");
const qs = require("qs");

function getImageUrl(relativeUrl) {
  let imageUrl = NoImage;
  if(relativeUrl) {
    const isAbsoluteUrl = relativeUrl.startsWith('https://') || relativeUrl.startsWith('http://');

    if(window.location.origin.startsWith('http://localhost') && !isAbsoluteUrl) {
      imageUrl= `https://www.dev.7-11gt.com${relativeUrl}`
    }
    else imageUrl = relativeUrl;
  }
  return imageUrl;
}

const resValidation = (res) => {
    if(validator.isJSON(JSON.stringify(res))) {
        return res;
    }
    else {
        return null;
    }
}

const sanitizeContentToRender = content => {
	// Configure DOMPurify to allow specific tags and attributes
	const config = {
		ADD_ATTR: ["target", "url"]
	};
	return DOMPurify.sanitize(content, config);
};

const encodeUtil = (item) => {
    const str= JSON.stringify(item);
    const encodedStr= encodeURIComponent(str);
    return encodedStr;
}

const checkFeatureAccess = (feature, access) => {
    const accessToken = localStorage.getItem("access_token");
    const checkExpiry = localStorage.getItem("expiry_time");

    if(!accessToken || !checkExpiry){
        return false;
    }

    const {userAccess, isAdmin} = getReduxStateBySlice('userManagement');

    return isAdmin || (userAccess && userAccess[feature] && userAccess[feature][access]);
};

const getCountryDataByProperty = (countryId, key) => {
  let data = ""
  countryMapper.map(cm => {
    if(cm.countryId === +countryId) data =  cm[key]
  })
  return data
}

function getCountryConfig(countryId, keyName, placeholders) {
  const countryName = getCountryDataByProperty(countryId, "countryName");
  let response =
    (CountryConfig[countryName] || CountryConfig["DEFAULT"])[keyName] || "";
  
  if (Object.keys(placeholders || {}).length) {
    for (const pattern of Object.keys(placeholders)) {
      response = response.replace(`{{${pattern}}}`, placeholders[pattern]);
    }
  }
  return response;
}

/** takes a string with placeholders and returns an array of placeholders.
 * Example: "hey {{world}}, welcome to {{name}}"
 * Output: ['world', 'name']
 */
function extractPlaceholders(str) {
  const regex = /{{(.*?)}}/g;
  const matches = [];
  let match;
  while ((match = regex.exec(str)) !== null) {
    matches.push(match[1]);
  }
  return matches;
}



const getReduxStateBySlice = (sliceName) => {
    const appState = store.getState();
    return appState[sliceName];
};

const checkExpiryAndRedirect = () => {
    let isTokenActive = true;
    const expTime = localStorage.getItem('expiry_time');
    const protocol = app_url.includes('localhost:') ? 'http' : 'https';
    if (Date.now() / 1000 >= expTime) {
        localStorage.removeItem('expiry_time');
        localStorage.removeItem('auth_time');
        localStorage.removeItem('username');
        window.location.replace(`${protocol}://${app_url}/logout`);
        isTokenActive = false;
    }
    return isTokenActive;
};


const trimFileName = (fileName) => {
  const index = fileName.lastIndexOf(".");
  return (
    fileName.substring(0, index).replace(/[^a-zA-Z0-9]/g, "_") +
    fileName.substring(index)
  );
};

const uploadImageToS3 = async (presignedPutObjectUrl, imageFileUploaded, fileCategory, fileName, onUploadProgress) => {
  // Get Presigned URL for S3 Bucket
  if (!imageFileUploaded) {
    return;
  }
  // get presigned url to put object in s3 bucket
  const fileNameArr = imageFileUploaded.name.split(".");
  const fileExt = fileNameArr.at(fileNameArr.length - 1);

  const presignedUrlResponse = await axios.request({
    method: "GET",
    url: presignedPutObjectUrl,
    headers: {
      Authorization: localStorage.getItem("access_token"),
    },
    params: {
      fileName,
      fileCategory: fileCategory,
      fileType: fileExt,
    },
    data: qs.stringify({
      accessToken: localStorage.getItem("access_token"),
    }),
  });
  const { url, fileName: fileNameInAws } =
    presignedUrlResponse?.data?.data?.url;
  await axios.request({
    method: "PUT",
    url,
    headers: {
      "Content-Type": imageFileUploaded.type,
    },
    data: imageFileUploaded,
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
      return data;
    },
    onUploadProgress,
  });
  return fileNameInAws;
};

const getUserName = () => {
  let name = localStorage.getItem("username");
  if(name === null || name === undefined || name === 'undefined'){
    name = 'public'
  }
  return name;
}

export {
  getImageUrl,
  extractPlaceholders,
  getCountryConfig,
  uploadImageToS3,
  trimFileName,
  resValidation,
  sanitizeContentToRender,
  encodeUtil,
  checkFeatureAccess,
  getReduxStateBySlice,
  checkExpiryAndRedirect,
  getCountryDataByProperty,
  getUserName,
};
