import React, { useState, useEffect } from 'react';
import BMHeader from './BMHeader';
import LoadingIndicator from '../Common/LoadingIndicator';
import NoDataFallback from '../Common/NoDataFallback';
import { getCollectionsData } from './BMService';
import CategoryCard from '../Common/CategoryCard';
import './BrandedMerchandise.scss';
import CommonErrorFallback from '../Common/CommonErrorFallback';
import ResolutionDisclaimer from '../Common/ResolutionDisclaimer';
import { useNavigate } from 'react-router-dom';
import { trackMixPanelEvent } from '../../../mixpanel/mixpanel';
import { BRANDED_MERCHANDISE } from '../../../Constants/Constant';

const BrandedMerchandise = () => {
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    trackMixPanelEvent({module: 'BRANDED_MERCHANDISE'})
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      setIsLoading(true);
      const response = await getCollectionsData({ rowsPerPage: 500, page: 1 });
      setCollections(response.data.rows);
    } catch (error) {
      console.error('Error fetching collections data:', error);
      setApiFailReported(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (cardName, id) => {
    navigate(`/brandedMerchandise/collections/${id}`, { state: { collectionName: cardName, collectionId: id } });
  };
  
  const handleEditClick = (id) => {
    const editData = collections.find((item)=> item.id === id)
    navigate('/create-edit-collection', { state: { collectionObj: editData, returnUrl: '/brandedMerchandise' } })
  };

  return (
    <div className='layout-wrapper'>
      {isLoading && <LoadingIndicator />}
      <BMHeader />
      <div className='collection-layout'>
        {apiFailReported ? (
          <CommonErrorFallback />
        ) : (
          <div className='card-grid-wrapper'>
            {collections.length>0 && 
              collections.map((collection) => (
                <CategoryCard
                  key={collection.id}
                  itemId={collection.id}
                  cardName={collection.description}
                  imgSrc={collection.hierarchyUrl}
                  source={BRANDED_MERCHANDISE}
                  handleCardClick={handleCardClick}
                  handleEditClick={handleEditClick}
                />
              ))}    
          </div>
        )}
        {!isLoading && !collections.length && !apiFailReported && (
          <NoDataFallback/>
        )}
      </div>
      <ResolutionDisclaimer />
    </div>
  );
};

export default BrandedMerchandise;
