import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToBMList } from './BMCollectionSlice'; 
import './BrandedMerchandise.scss';
import { trackMixPanelEvent } from '../../../mixpanel/mixpanel';


const AddToListButton = ({ itemId, onRemoveItem }) => {
  const dispatch = useDispatch();
  const bmList = useSelector((state) => state.bmCollection.bmList);
  const [quantity, setQuantity] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    const item = bmList.find(item => `${item.id}` === `${itemId}`);
    setQuantity(item?.count || 0);
  }, [bmList, itemId]);

  const handleAddToListClick = (event) => {
    trackMixPanelEvent({ module: 'BRANDED_MERCHANDISE', action: 'ADD_TO_LIST', metadata: { itemId }})
    event.stopPropagation(); 
    if (quantity === 0) {
      dispatch(addItemToBMList({ id: itemId, count: 1 }));
    }
  };

  const handleIncrement = (event) => {
    event.stopPropagation(); 
    const newQuantity = quantity + 1;
    dispatch(addItemToBMList({ id: itemId, count: newQuantity }));
  };

  const handleDecrement = (event) => {
    event.stopPropagation(); 
    const newQuantity = Math.max(0, quantity - 1);
    dispatch(addItemToBMList({ id: itemId, count: newQuantity }));
    if(newQuantity === 0)
      onRemoveItem && onRemoveItem(itemId);
  };

  const handleQuantityChange = (event) => {
    const value = Math.max(0, parseInt(event.target.value, 10) || 0);
    setQuantity(value);
    debouncedDispatch(value);
    if(value === 0)
      onRemoveItem && onRemoveItem(itemId);
  };

  const debouncedDispatch = (newQuantity) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout); 
    }

    const timeout = setTimeout(() => {
      dispatch(addItemToBMList({ id: itemId, count: newQuantity }));
    }, 300); 

    setDebounceTimeout(timeout);
  };

  return quantity === 0 ? (
    <Button
      className="add-to-list-button"
      onClick={handleAddToListClick}
    >
      ADD TO LIST
    </Button>
  ) : (
    <div className="quantity-controls">
      <Button
        variant="outlined"
        className="quantity-button"
        onClick={handleDecrement}
      >
        -
      </Button>
      <input
        type="text"
        value={quantity}
        onChange={handleQuantityChange}
        className="quantity-input"
        onClick={(e) => e.stopPropagation()}
        min="0"
      />
      <Button
        className="quantity-button"
        onClick={handleIncrement}
      >
        +
      </Button>
    </div>
  );
};

export default AddToListButton;
