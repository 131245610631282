import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userFullName: "User",
	userAccess: "",
	userList: [],
	isAdmin: false,
	PBNotification:[],
	userCountry: '',
	userCompany: ''
};

const UserManagementSlice = createSlice({
	name: 'userManagement',
	initialState,
	reducers: {
		setUserCountryAndCompany(state, action) {
			state.userCompany = action.payload.userCompany;
			state.userCountry = action.payload.userCountry;
		},
		setUserAccess(state, action) {
			state.userAccess = action.payload;
		},
		clearUserAccess(state) { 
			state.userAccess = "";
		},
		setUserList(state, action) {
			state.userList = action.payload;
		},
		setUserAsAdmin (state, action) {
			state.isAdmin = action.payload;
		},
		setUserFullName (state, action) {
			state.userFullName = action.payload;
		},
		setPBNotification (state, action) {
			state.PBNotification = action.payload;
		},
		RemoveItemFromPB (state, action) {
			const { id } = action.payload;
			const stringId = String(id); 
			const index = state.PBNotification.findIndex(item => String(item) === stringId);
			if (index !== -1) {
				state.PBNotification.splice(index, 1);
			} else {
				console.error('ID not found in PBNotification');
			}
		}
	},
});

export const { setUserCountryAndCompany, setUserAccess, clearUserAccess, setUserList, setUserAsAdmin, setUserFullName, setPBNotification, RemoveItemFromPB } = UserManagementSlice.actions;

export default UserManagementSlice.reducer;