import { React, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PBCHeader from "./PBCHeader";
import CircularProgress from "@mui/material/CircularProgress";
import NoImage from "./assets/NoImage.png";
import { useDispatch, useSelector } from "react-redux";
import { pb_service } from "../../../config/environment";
import { getImageUrl, resValidation } from "../../../Utils/CommonUtils";
import NoDataFallback from "../Common/NoDataFallback";
import CategoryCard from "../Common/CategoryCard";
import CommonErrorFallback from "../Common/CommonErrorFallback";
import DisclaimerUtil from "../Common/DisclaimerUtil";
import axios from "axios";
import { encodeUtil } from "../../../Utils/CommonUtils";
import LoadingIndicator from "../Common/LoadingIndicator";
import ResolutionDisclaimer from "../Common/ResolutionDisclaimer";
import { trackMixPanelEvent } from "../../../mixpanel/mixpanel";
import { RemoveItemFromPB } from "../UserManagement/UserManagementSlice";

const Wrapper = styled.div`
  padding: 10rem 5rem 3rem 5rem;
  /* margin-bottom: 2rem; */
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media (max-width: 770px) {
    padding: 10rem 2rem 4rem 2rem;
  }
`;
const CategoryLayout = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7fafc;
  position: relative;
`;
const CardGridWrapper = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  flex-direction: row;
  align-content: flex-start;
  align-items: center; */

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19%, 1fr)); /* leaving some space for gap */
  gap: 3rem;

  @media (max-width: 770px) {
    grid-template-columns: repeat(auto-fill, minmax(38%, 1fr)); /* leaving some space for gap */
    gap: 3rem;
  }
`;

const pageType = "PsaList";

const PSALayout = (props) => {
    const navigate = useNavigate();
    const [allPSA, setAllPSA] = useState([]);
    const [categoryDetail, setCategoryDetail] = useState([]);
    const [isLoadingPSA, setIsLoadingPSA] = useState(false);
    const [apiFailReported, setApiFailReported] = useState(false);
    const { countryName } = useParams();
    const checkAccessToken = localStorage.getItem("access_token");
    const {PBNotification= []} = useSelector(state=> state.userManagement);   
    const dispatch = useDispatch();

    useEffect(() => {
      trackMixPanelEvent({module: 'PRIVATE_BRAND_CATALOG', action: 'COUNTRY_PAGE', metadata: {pbCountry: countryName}})
      const countryId = localStorage.getItem("country_id");
      fetchPBCatalogData(countryId);
      acknowledgePBNotification(Number(countryId));
    }, []);
    
    const acknowledgePBNotification = async (countryId) => {
      if (PBNotification.includes(countryId)) {
        try {
          const response = await axios.post(`${pb_service}/ackPbNotification`, {
            accessToken: checkAccessToken, 
            countryID: countryId,
          });
          if (response.data.statusCode === 200) {
            dispatch(RemoveItemFromPB({ id: countryId }));
          }
        } catch (error) {
          console.error('Failed to acknowledge PBNotification:', error);
        }
      }
    };

    const fetchPBCatalogData = (countryId) => {
      setIsLoadingPSA(true);
      const encodedFilter = encodeUtil([{"field": "parentItemHierarchyCodeId", "value":`${countryId}`,"operator":"equal"}])
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${pb_service}/api/itemmaster/hierarchycodes/hierarchySearch?noOfRecords=100&pageNo=1&sortBy=createdDatetime&sortDirection=ASC&filters=${encodedFilter}`,
      };
        
      axios.request(config)
      .then((response) => {
        let validatedRes = resValidation(response);
        setAllPSA(validatedRes.data.data.rows);
        setCategoryDetail([countryName]);
        setIsLoadingPSA(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingPSA(false);
        setApiFailReported(true);
      });
    
    };

    const handleCardClick = (psaName, id, itemIsAdded) => {
      localStorage.setItem("psa_id", id);
      const encodedString = encodeURIComponent(psaName);
      navigate(`/privateBrandCatalog/${countryName}/category/${encodedString}`);
    };
    
    return (
      <Wrapper>
		{isLoadingPSA && <LoadingIndicator />}
        <PBCHeader categoryDetail={categoryDetail} pageType={pageType} />
        <DisclaimerUtil></DisclaimerUtil>
        <CategoryLayout>
        {apiFailReported ?
          <CommonErrorFallback/> :
          <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
            {allPSA.length?
              <CardGridWrapper>
                {allPSA.map((psa) => {
                  return (
                    <CategoryCard
                      key={psa.id}
                      imgSrc={getImageUrl(psa.hierarchyUrl?.[0])}
                      cardName={psa.description}
                      itemId={psa.id}
                      handleCardClick={handleCardClick}
                    >
                    </CategoryCard>
                  )})
                }
              </CardGridWrapper> :
              (isLoadingPSA ? "" : <NoDataFallback/>)
            }
          </div>
        }
        </CategoryLayout>
        <ResolutionDisclaimer/>
      </Wrapper>
    );
};
export default PSALayout;
