import React, { useState, useEffect } from 'react';
import CommonGrid from '../../Common/CommonGrid/CommonGrid';
import { getCollectionTableData, getProductTableData } from '../BMService'; 
import '../BrandedMerchandise.scss';
import {
  collectionTableHeader,
  productTableHeader,
  rowsPerPageCount,
  defaultRowCount,
} from "../../../../Constants/Constant";
import NoImage from '../../PrivateBrandCatalog/assets/NoImage.png';
import LoadingIndicator from "../../Common/LoadingIndicator";
import NoDataFallback from "../../Common/NoDataFallback";
import { checkExpiryAndRedirect } from '../../../../Utils/CommonUtils';
import { useNavigate } from 'react-router-dom';
import BMDeletePopup from './BMDeletePopup'
import { trackMixPanelEvent } from '../../../../mixpanel/mixpanel';

const DataTable = ({ type }) => {
    const [data, setData] = useState([]);
    const [tableProductData, setTableProductData] = useState([]);
    const [tableCollectionData, setTableCollectionData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowCount);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const [showDeleteUtil, setShowDeleteUtil] = useState(false);
    const [deleteRow, setDeleteRow] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
      if (error) {
        setError(null);
      }
      resetGridRows();
      fetchData();
    }, [type]);

    useEffect(() => {
      trackMixPanelEvent({module: 'BRANDED_MERCHANDISE', action: 'BRANDED_MERCHANDISE_MANAGEMENT'})
      fetchData();
    }, [page, rowsPerPage]);

    const resetGridRows = () => {
      if (page != 1) {
        setPage(1);
      }
    };

    const fetchData = async () => {     
        try {
            const isTokenActive = checkExpiryAndRedirect();
			if (!isTokenActive) {
				return;
			}
            setLoading(true);
            let response;
            if (type === 'collections') {
                response = await getCollectionTableData({ rowsPerPage, page });
                setTableCollectionData(response.data.data.rows)
            } else if (type === 'products') {
                response = await getProductTableData({ rowsPerPage, page });
                setTableProductData(response.data.data.rows)
            }
            const modifiedData = response.data.data.rows.map(item => transformData(item));
            setTotalRows(response.data.data.count)
            setData(modifiedData);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    const transformData = (item) => {
        if (type === 'collections') {
          const [collectionImage = NoImage] = item.hierarchyUrl || [];
            return {
                id: item.id,
                collectionImage,
                collection: item.description,
                numberOfProducts: item.itemHierarchies.length || 0
            };
        } else if (type === 'products') {
          const [ productImage = NoImage ] = item.imageUrl || [];
            return {
                id: item.itemMasterID,
                productImage,
                productName: item.shortDescription,
                supplier: item.brandedMerchandiseSupplier || "-",
                style: item.brandedMerchandiseStyle || "-",
                minimumOrderQty: item.minimumOrderQty || "-",
                priceUSD: item.brandedMerchandisePrice || "-",
                srpUSD: item.minSrp || "-",
            };
        }
    };

    const getColumns = () => {
        if (type === 'collections') {
            return collectionTableHeader;
        } else if (type === 'products') {
            return productTableHeader;
        }
    };

    const handleEdit = (row) => {  
      if (type === 'collections') {
        const editData = tableCollectionData.find((item)=> item.id === row.id)
      navigate('/create-edit-collection', { state: { collectionObj: editData, returnUrl: '/manageCollections' } })
      } else if (type === 'products') {
        const editData = tableProductData.find((item)=> item.itemMasterID === row.id)
        navigate('/add-edit-product',{ state: {editData} })
      }
    };

    const handleDeleteConfirmation = async () => {
      try {
        setLoading(true);
        resetDeleteState();
        await fetchData();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const resetDeleteState = () => {
      setDeleteRow(null);
      setShowDeleteUtil(false);
    }

    const onPageChange = (e, newPage) => {
		setPage(newPage);
	};

    const onRowsPerPageChange = (event) => {
		const newRowsPerPage = event.target.value;
		setRowsPerPage(newRowsPerPage);
		setPage(1);
	};

  const handleDeleteUtil = (row) => {
    setDeleteRow(row);
    setShowDeleteUtil(true);
  }

    return (
      <div className="data-grid-header">
        {loading && <LoadingIndicator enableBackdrop={true}/>}
        {error ? (
          <NoDataFallback />
        ) : (
          <>
            <CommonGrid
              rows={data}
              columns={getColumns()}
              onEditClick={handleEdit}
              onDeleteClick={handleDeleteUtil}
              page={page}
              totalRows={totalRows}
              onRowsPerPageChange={onRowsPerPageChange}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              rowsPerPageCount={rowsPerPageCount}
            />
            {showDeleteUtil && (
              <BMDeletePopup row={deleteRow} type={type} onConfirm={handleDeleteConfirmation} onCancel={resetDeleteState} />
            )}
          </>
        )}
      </div>
    );
};

export default DataTable;
 