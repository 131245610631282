import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../App";
import { FcExpired } from "react-icons/fc";
import { app_url, client_id, logout_uri, logoutRevoke } from "../../config/environment";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { clearUserAccess } from "../Assets/UserManagement/UserManagementSlice";
import { trackUserLogout } from "../../mixpanel/mixpanel";
import { getUserName } from "../../Utils/CommonUtils";

var qs = require("qs");

const LogoutDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logout = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(UserContext);
  const [session, setSession] = useState(false);
  const reduxDispatch = useDispatch();

  // const client_id = process.env.REACT_APP_SICI;
  // const app_url = process.env.REACT_APP_URI;
  // var logoutUri = process.env.REACT_APP_LOGOUT_URI;
  const protocol = app_url.includes('localhost:') ? 'http':'https';
  const logoutUri = `${logout_uri}client_id=${client_id}&logout_uri=${protocol}://${app_url}`;
  // const logoutRevoke = process.env.REACT_APP_LOGOUT_REVOKE;

  const checkRefreshToken = localStorage.getItem("refresh_token");

  useEffect(() => {
    let username = getUserName()
    if(username !== 'public'){
      trackUserLogout()
    }
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    if (!session && Date.now() / 1000 >= expTime) {
      setSession(true);
    }
    logoutRevocation();
  }, [session, setSession]);

  const logoutRevocation = async () => {
    reduxDispatch(clearUserAccess());
    window.localStorage.clear();
    dispatch({ type: "USER", payload: false });
    try {
      const res = await fetch(logoutRevoke, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          refreshToken: checkRefreshToken,
        }),
      });
      window.location.replace(logoutUri);
    } catch (err) {}
  };

  const expTime = localStorage.getItem("expiry_time");

  return (
    <>
      <h4 className="container1-h1" style={{ marginTop: "2rem" }}>
        {session ? (
          <LogoutDiv>
            <div
              style={{
                width: "50%",
              }}
            >
              <div style={{ margin: "2rem 0" }}>
                <h4>Your session has expired.</h4>
                <h4>
                  <p>Please log in again.</p>
                </h4>
              </div>
              <div>
                <FcExpired style={{ scale: "1.7" }} />
              </div>
            </div>
          </LogoutDiv>
        ) : (
          t("logout_loading")
        )}
      </h4>
    </>
  );
};

export default Logout;
