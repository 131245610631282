import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '@mui/material/Button';
import './ProductDetailsPage'; 
import { emailMe } from './BMService';
// import { enqueueSnackbar } from 'notistack';
// import { brandedMerchandiseMsgs, BRANDED_MERCHANDISE } from '../../../Constants/Constant';
import { trackMixPanelEvent } from "../../../mixpanel/mixpanel";


const EmailListButton = ({ emailList, onMailSent }) => {
  const transformedList = emailList.map(item => ({
    itemMasterID: item.id,
    quantity: item.count
  }));
  const handleEmailClick = async () => {
    trackMixPanelEvent({ module: 'BRANDED_MERCHANDISE', action: 'EMAIL_MY_LIST'})
    try {
      const response = await emailMe({data: transformedList});
      onMailSent && onMailSent(null, response);
    } catch (error) {
      console.error("Error sending email:", error);
      onMailSent(error, null);
    }
  };

  return (
    <div className="email-list-wrapper"> 
      <Button
        variant="contained"
        size="large"
        startIcon={<MailOutlineIcon />}
        disabled={!emailList || emailList.length === 0} 
        onClick={handleEmailClick}
      >
        EMAIL LIST
      </Button>
    </div>
  );
};

export default EmailListButton;
