import { React, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import "./NewsFeedLayout.css";
import Assets from "./Assets";
import NewsFeed from "./NewsFeed";
import PendingPosts from "./SocialMediaPosts/PendingPosts";
import { UserContext } from "../../App";
import ProfileDetails from "./ProfileDetails";
import { Button } from "react-bootstrap";
import { GoMoveToTop } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { fetch_post, get_profile } from "../../config/environment";
import { useSelector } from "react-redux";

  const ArrowDiv = styled.div`
    scale: 1.5;
    padding: 3px 0 0 0;
    cursor: pointer;
    @media (min-width: 640px) {
      display: none !important;
    }
  `;

  const AssetsDiv = styled.div`
    @media (max-width: 640px) {
      position: relative;
      left: -2rem;
      top: 0.3rem;
      padding: 2rem;
      background-color: white;
      border: 1px solid #e0dede;
      border-radius: 10px;
    }
    @media (max-width: 640px) {
      padding: 2rem 1rem 2rem 4rem;
    }
    @media (max-width: 545px) {
      padding: 2rem 1rem 2rem 3rem;
    }
  `;
  let ScrollButton = styled.div`
    display: flex;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    font-size: 18px;
    background: #f7fafc;
    border: 1px solid #f7fafc;
    border-radius: 50%;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      background: #d5d5d5;
      border: 1px solid #d5d5d5;
    }
  `;

var qs = require("qs");
var axios = require("axios");

const NewsFeedLayout = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [complete, setComplete] = useState(false);
  const [fetchResponse, setFetchResponse] = useState([]);
  const [fetchAdminResponse, setFetchAdminResponse] = useState([]);
  const [fetchUserResponse, setFetchUserResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [postCreated, setPostCreated] = useState(false);
  const [approveAction, setApproveAction] = useState(false);
  const [profileClicked, setProfileClicked] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [profileData, setProfileData] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [assetBarOpen, setAssetBarOpen] = useState(false);
  const {PBNotification= []} = useSelector(state=> state.userManagement);
  const checkAccessToken = localStorage.getItem("access_token");
  // const fetch_post = process.env.REACT_APP_FETCH_POST;



  const toggleAssetBar = () => {
    setAssetBarOpen(!assetBarOpen);
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  let scrollButtonDiv = isVisible
    ? {
        position: "fixed",
        right: "-6rem",
        bottom: "2rem",
        width: "14rem",
      }
    : {
        display: "none",
      };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      setUserProfile("");
    };
  }, []);

  useEffect(() => {
    if (checkAccessToken && state) {
      props.setCallFetchBucket();
      setIsLoading(true);
      fetchPost();
    }
  }, [props.userDetailUpdate, checkAccessToken, state]);

  var data = qs.stringify({
    accessToken: checkAccessToken,
    pageNum: 1,
  });

  var config = {
    method: "post",
    url: fetch_post,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  const fetchPost = async () => {
    setIsLoading(true);
    axios(config)
      .then(function (response) {
        const resAll = response.data.body.message.allPosts;
        const resAdmin = response.data.body.message.adminPosts;
        if (resAll.length < 10) {
          setComplete(true);
        }
        return [resAll, resAdmin];
      })
      .then(function (res) {
        setFetchResponse(res[0]);
        setFetchAdminResponse(res[1]);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (profileClicked) {
      getUserProfile(userProfile);
    }
  });

  // const get_profile = process.env.REACT_APP_GET_PROFILE;

  const getUserProfile = (userProfile, mailId = userEmail) => {
    setOpenProfile(true);
    var axios = require("axios");
    var qs = require("qs");
    var data = qs.stringify({
      accessToken: checkAccessToken,
      objectId: userProfile,
      userEmail: mailId,
    });
    var config = {
      method: "post",
      url: get_profile,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setProfileData(response.data.body.message);
        setProfileClicked(false);
      })
      .catch(function (error) {
        setProfileClicked(false);
      });
  };

  const handlePendingPostReload = () => {
    setPostCreated(!postCreated);
  };
  const handleApproveActionReload = () => {
    setApproveAction(true);
    fetchPost();
  };
  const handleDeleteReload = () => {
    fetchPost();
    openProfile && getUserProfile("", profileData.userPosts[0].postedByEmail);
  };
  const onBackBtnHandler = (value) => {
    props.setGettingBucket(value);
    setOpenProfile(false);
    setUserProfile("");
  };

  return (
    <div className="assetsLayout">
      <div className={PBNotification.length ? "newsFeedLayout badge-padding" : "newsFeedLayout"}>
        <div className="assetsContainer">
          <Assets bucketError={props.bucketError} setCallFetchBucket={props.setCallFetchBucket}/>
        </div>
        <AssetsDiv>
          <ArrowDiv onClick={toggleAssetBar}>
            <BsArrowBarRight />
          </ArrowDiv>
          <div className={`asset-sidebar ${assetBarOpen ? "active" : ""}`}>
            <div className="asset-sd-header">
              <h4 className="mb-0"></h4>
              <div
                className="btn btn-primary"
                onClick={toggleAssetBar}
                style={{
                  background: "#f2f2f2",
                  padding: "0.5rem 1rem 0.5rem 1rem",
                  borderRadius: "5px",
                }}
              >
                <BsArrowBarLeft />
              </div>
            </div>
            <div className="asset-sd-body">
              <Assets setCallFetchBucket={props.setCallFetchBucket} />
            </div>
          </div>
        </AssetsDiv>
        <div
          className={`asset-sidebar-overlay ${assetBarOpen ? "active" : ""}`}
          onClick={toggleAssetBar}
        ></div>
        <div className="newsFeedContainer">
          {openProfile ? (
            <ProfileDetails
              profileInfo={profileData.profileInfo}
              profilePosts={profileData.userPosts}
              setGettingBucket={props.setGettingBucket}
              onBackBtnHandler={onBackBtnHandler}
              handleDeleteReload={handleDeleteReload}
            />
          ) : (
            <NewsFeed
              posts={fetchResponse}
              isLoading={isLoading}
              gettingBucket={props.gettingBucket}
              refresh={props.refresh}
              setProfileClicked={setProfileClicked}
              setUserProfile={setUserProfile}
              setUserEmail={setUserEmail}
              reloadComponent={approveAction}
              handleReload={handlePendingPostReload}
              handleDeleteReload={handleDeleteReload}
              userDetailUpdate={props.userDetailUpdate}
            />
          )}
        </div>
        <div className="pendingPostsContainer">
          <PendingPosts
            posts={fetchAdminResponse}
            isLoading={isLoading}
            gettingBucket={props.gettingBucket}
            refresh={props.refresh}
            setProfileClicked={setProfileClicked}
            setUserProfile={setUserProfile}
            reloadComponent={postCreated}
            handleActionReload={handleApproveActionReload}
          />
        </div>
      </div>
      <div style={scrollButtonDiv}>
        <ScrollButton
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <GoMoveToTop
            style={{
              textAlign: "center",
              scale: "1.5",
            }}
          />
        </ScrollButton>
      </div>
    </div>
  );
};

export default NewsFeedLayout;
