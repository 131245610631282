import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { debounce } from 'lodash';
import LoadingIndicator from '../Common/LoadingIndicator';
import BMHeader from './BMHeader';
import { getCollectionsData, getProductTableData, persistMyListForBM, updateProductOrder } from './BMService';
import CategoryCard from '../Common/CategoryCard';
import CategoryCollectionSection from './CategoryCollectionSection'; 
import './BrandedMerchandise.scss';
import { trackMixPanelEvent } from '../../../mixpanel/mixpanel';
import { Backdrop, CircularProgress, Typography } from '@mui/material';

const CollectionProductList = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const bmList = useSelector((state) => state.bmCollection.bmList);
  const [collections, setCollections] = useState([]);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(true);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(state?.collectionId || null);
  const [selectedCollectionName, setSelectedCollectionName] = useState(state?.collectionName || '');
  const [products, setProducts] = useState([]);
  const [isPersistingCart, setPersistingCart] = useState(false);
  
  const [loading, setLoading] = useState(false); 
  const [previousProducts, setPreviousProducts] = useState([]); 
  const isAdmin = useSelector((state) => state.userManagement.isAdmin);

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    trackMixPanelEvent({module: 'BRANDED_MERCHANDISE', action: 'COLLECTION_PAGE', metadata: {pageName: `Collection - ${selectedCollectionName}`}})
    if (selectedCollectionId !== null) {
      fetchProducts(selectedCollectionId);
    }
  }, [selectedCollectionId]);

  const debouncedPersistMyList = useCallback(
    debounce((list, timestampISOString) => {
      setPersistingCart(true);
      persistMyListForBM(
        list.map((listItem) => ({
          itemMasterId: listItem.id,
          count: listItem.count,
        })),
        timestampISOString
      )
        .then((res) => {
          setPersistingCart(false);
        })
        .catch((err) => {
          setPersistingCart(false);
        });
    }, 800),
    []
  );

  useEffect(() => {
    const timestampOfLocalChange = localStorage.getItem('bm_my_list_timestamp') || new Date().toISOString();
    const localDate = new Date(timestampOfLocalChange);
    const timestampOfRemoteChange = new Date(localDate.getTime() + 1000).toISOString();
    debouncedPersistMyList(bmList, timestampOfRemoteChange);
  }, [bmList, debouncedPersistMyList]);

  useEffect(() => {
    if (state?.collectionId) {
      setSelectedCollectionId(state.collectionId);
      setSelectedCollectionName(state.collectionName || '');
    } else {
      setSelectedCollectionId(null);
      setSelectedCollectionName('');
    }
  }, [state]);
  

  const fetchCollections = async () => {
    try {
      setIsCollectionsLoading(true);
      const response = await getCollectionsData({ rowsPerPage: 50, page: 1 });
      setCollections(response.data.rows);
    } catch (error) {
      console.error('Error fetching collections data:', error);
    } finally {
      setIsCollectionsLoading(false);
    }
  };

  const fetchProducts = async (collectionId) => {
    try {
      setIsProductsLoading(true);
      const response = await getProductTableData({ rowsPerPage: 15, page: 1, collectionId });
      setProducts(response?.data?.data?.rows || []);
    } catch (error) {
      console.error('Error fetching products data:', error);
    } finally {
      setIsProductsLoading(false);
    }
  };

  const handleCollectionChange = (event) => {
    const newCollectionId = parseInt(event.target.value, 10);
    const newCollection = collections.find(collection => collection.id === newCollectionId);

    if (newCollectionId !== selectedCollectionId) {
      setSelectedCollectionId(newCollectionId);
      setSelectedCollectionName(newCollection?.description || '');

      navigate(`/brandedMerchandise/collections/${newCollectionId}`, {
        state: { collectionId: newCollectionId, collectionName: newCollection?.description || '' }
      });
    }
  };

  const handleCardClick = (cardName, itemId) => {
    navigate(`/brandedMerchandise/collections/${selectedCollectionId}/${itemId}`, {
      state: { collectionName: selectedCollectionName, collectionId: selectedCollectionId }
    });}

    const debouncedUpdateProductOrder = useCallback(
      debounce(async (collectionId, itemRanks) => {
        try {
          setLoading(true);
          await updateProductOrder({ collectionId, itemRanks });
        } catch (error) {
          console.error('Error updating product order:', error);
    
          setProducts(previousProducts);

          if (collectionId) {
            try {
              setLoading(false);
              await fetchProducts(collectionId);
            } catch (fetchError) {
              console.error('Error refetching products:', fetchError);
            }
          }
        } finally {
          setLoading(false);
        }
      }, 2000),
      []
    );
    

  const moveProduct = (draggedId, hoverId) => {
    const draggedIndex = products.findIndex(product => product.itemMasterID === draggedId);
    const hoverIndex = products.findIndex(product => product.itemMasterID === hoverId);

    if (draggedIndex !== -1 && hoverIndex !== -1) {
      const updatedProducts = [...products];
      const [draggedProduct] = updatedProducts.splice(draggedIndex, 1);
      updatedProducts.splice(hoverIndex, 0, draggedProduct);

      setPreviousProducts(products);
      setProducts(updatedProducts);

      const itemRanks = updatedProducts.map((product, index) => ({
        uiRank: updatedProducts.length - index,
        itemMasterId: product.itemMasterID,
      }));

      debouncedUpdateProductOrder(selectedCollectionId, itemRanks);
    }
  };

  const ProductCard = ({ product }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'PRODUCT',
      item: { id: product.itemMasterID },
      canDrag: isAdmin,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: 'PRODUCT',
      hover: (draggedItem) => {
        if (isAdmin && draggedItem.id !== product.itemMasterID) {
          moveProduct(draggedItem.id, product.itemMasterID);
        }
      },
    });

    return (
      <div
        ref={(node) => drag(drop(node))}
        className={`product-card ${isDragging ? 'dragging' : ''}`}
      >
        <CategoryCard
          itemId={product.itemMasterID}
          cardName={product.shortDescription}
          imgSrc={product.imageUrl}
          price={product.brandedMerchandisePrice}  
          handleCardClick={handleCardClick} 
          srp={product.minSrp}
          minimumOrderQty={product.minimumOrderQty}
          source='Collection Product List'
        />
      </div>
    );
  };

  return (
    <div className='collection-product-list-wrapper'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <BMHeader collectionName={selectedCollectionName} collectionId={selectedCollectionId} />
      <div className="content-wrapper">
        <CategoryCollectionSection
          collections={collections}
          selectedCollectionId={selectedCollectionId}
          handleCollectionChange={handleCollectionChange}
        />
        {isPersistingCart && <LoadingIndicator enableBackdrop={true}/>}
        <div className='products-section'>
          {isProductsLoading ? (
            <LoadingIndicator />
          ) : products.length > 0 ? (
            <div className='product-cards'>
              {products.map((product) => (
                <ProductCard key={product.itemMasterID} product={product} />
              ))}
            </div>
          ) : (
            <Typography>No products found for this collection.</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionProductList;
 