import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import './CommonMyListButton.scss';
import { FLAVOUR_ID } from '../../../Constants/Constant';
import { getMyListForBM } from '../BrandedMerchandise/BMService';
import { useDispatch } from 'react-redux';
import { populateBMList } from '../BrandedMerchandise/BMCollectionSlice';

const CommonListButton = ({ list = [], navigateTo, flavourID = FLAVOUR_ID.PRIVATE_BRAND_CATALOG }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const handleButtonClick = () => {
    navigate(navigateTo);
  };

  // whenever component mounts, call API to fetch data
  useEffect(() => {
    if(flavourID === FLAVOUR_ID.BRANDED_MERCHANDISE) {
      fetchBrandedMerchandiseMylist();
    }
  }, [])

  const fetchBrandedMerchandiseMylist = async () => { 
    try {
      const { result: myList, timestamp } = await getMyListForBM();

      // only applying remote-changes if they are most recent, else prioritising the local storage change.
      const remoteTimestamp = new Date(timestamp);
      const localTimestamp = localStorage.getItem('bm_my_list_timestamp');
      if(localTimestamp && new Date(localTimestamp) > remoteTimestamp)
        throw new Error('Local changes for my-list are more recent compared to remote changes.');
      reduxDispatch(populateBMList(myList));
    }
    catch(err) {
      console.error('Failed to apply remote my-list changes ', err);
    }
  }

  const getTheListButton = () => (
    <Button
      variant="contained"
      size="large"
      startIcon={<PersonIcon className="icon" />}
      onClick={handleButtonClick}
      disabled={!list.length}
      className={`button ${list.length ? 'hasItems' : ''}`} 
    >
      MY LIST ({list.length})
    </Button>
  );

  return (
    <div className="buttonWrapper">
      {list.length ? (
        getTheListButton()
      ) : (
        <Tooltip title="No items added" arrow>
          <div>{getTheListButton()}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default CommonListButton;
