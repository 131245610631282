import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import CircularRotatingLines from "../../../Utils/CircularLoader";
import FileViewer from "react-file-viewer";
import Divider from "@mui/material/Divider";
import { formatDistanceToNowStrict, formatDistance } from "date-fns";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { approve_post, alert_post } from "../../../config/environment";
import { useSelector } from "react-redux";

var axios = require("axios");
var qs = require("qs");

const ToggleAlert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PendingItemWrapper = styled.div`
  /* height: 5rem; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem 0;
`;
const PostDetailContainer = styled.div`
  // width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const ApprovalBtnContainer = styled.div`
  // width: 45%;
  border-radius: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 0rem;
  margin-right: 0.5rem;
  position: relative;
  top: 8px;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;
const CustomBtn = styled.button`
  width: 2.8rem;
  padding: 0.1rem 0rem;
  height: 1.2rem;
  border-radius: 2rem;
  font-size: 0.6rem;
  cursor: pointer;
  margin: 1.7rem 0.5rem;
  margin-right: 0;
  position: relative;
  text-align: center;
  @media (max-width: 1350px) {
    margin: 10px 0 0 0.5rem;
  }
`;
const AlertBtn = styled(CustomBtn)`
  border: 1px solid #f36f21;
  background-color: #f36f21;
  color: white;
  box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
`;
const AlertBtnBig = styled(AlertBtn)`
  width: 4.5rem;
  height: 1.6rem;
  font-size: 0.8rem;
`;
const ApproveBtn = styled(CustomBtn)`
  font-size: 11px;
  width: 3.5rem;
  height: 1.2rem;
  border: 1px solid #147350;
  background-color: #147350;
  color: white;
  box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
`;
const ApproveBtnBig = styled(ApproveBtn)`
  width: 4.5rem;
  height: 1.6rem;
  font-size: 0.8rem;
`;
const RejectBtn = styled(CustomBtn)`
  font-size: 11px;
  width: 3.5rem;
  height: 1.2rem;
  border: 1px solid #c3c3c3;
  background-color: transparent;
  color: #7d7d7d;
  box-shadow: none;
`;
const RejectBtnBig = styled(RejectBtn)`
  width: 4.5rem;
  height: 1.6rem;
  font-size: 0.8rem;
`;
const AvatarContainer = styled.div`
  // width: 32%;
  margin: 0 0.3rem 0 0.5rem;
  cursor: pointer;
  @media (max-width: 1200px) {
    margin: 0 10px 0 0.5rem;
  }
`;
const PostContainer = styled.div`
  // width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const NameContainer = styled.div`
  margin: 1rem 0 0;
  @media (max-width: 1350px) {
    margin: 1rem 0 0 0;
  }
`;
const UserName = styled.p`
  font-weight: 550;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
`;
const PreviewContainer = styled.div`
  // margin-top: 0.6rem;
  margin: 0.6rem 0.5rem 0 0;
  cursor: pointer;

  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
const PostPreview = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
`;
const DateContainer = styled.div`
  margin: 0.3rem 0;
`;
const PostDate = styled.p`
  font-size: 13px;
  line-height: 12px;
  color: #979797;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 16px;
  padding-top: 2px;
  max-width: 40rem;
  max-height: 30rem;
  width: 60%;
  overflow-y: scroll;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;

  .ck-content.ck-editor__editable {
    max-height: 10rem;
  }
`;
const RejectModalBody = styled(ModalBody)`
  width: 38rem;
  top: 20rem;
`;
const AlertModalBody = styled(ModalBody)`
  width: 38rem;
  top: 20rem;
`;
const ModalWrapperEP = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBodyEP = styled(RejectModalBody)`
  background-color: rgb(1, 1, 1);
  border-radius: 0px;
  padding: 0px 16px;
  padding-top: 2px;
  width: 45rem;
  max-height: 30rem;
  height: ${(props) => (props.imageAvailable ? "30rem" : "auto")};
  overflow-y: scroll;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
`;
const AvatarPostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;
const PostDetailComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem;
  padding-bottom: 1rem;
  width: 90%;
  max-height: 30rem;
  height: auto;
`;
const UserDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
const PostContentComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const PostTextComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8rem;
  padding-right: 0.7rem;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  word-break: break-word;
  white-space: pre-line;
`;
const PostImageComponent = styled.div`
  width: 100%;
  padding-right: 0.7rem;
  margin: 0 0rem 1rem;
  display: flex;
  flex-direction: column;
`;
const PostFileComponent = styled(PostImageComponent)`
  width: 97%;
  margin-bottom: 0.2rem;

  ${FileViewer}.pg-viewer-wrapper {
    width: 98%;
    height: 95%;
    border: 1px solid rgb(241, 238, 238);
    border-radius: 0.4rem;
    padding: 0.6rem;
    display: flex;
    justify-content: center;
  }
  ${FileViewer}.pg-viewer {
    width: 100%;
  }
  ${FileViewer}.document-container {
    width: 95%;
    height: 95%;
  }
  ${FileViewer}.pdf-canvas {
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
  }
`;
const PostPreview1stRow = styled.div`
  width: 100%;
  height: 60%;
`;
const PostPreview2ndRow = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
`;
const PostPreview2ndRowEl = styled.div`
  width: 50%;
  height: 10rem;
  display: flex;
`;
const PostPreviewLastEl = styled(PostPreview2ndRowEl)`
  position: absolute;
`;
const PostNumBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  color: white;
  font-size: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const ExtraPreviewFile = styled(PostFileComponent)`
  margin-bottom: -0.15rem;
  max-height: 22rem;
  ${FileViewer}.pg-viewer-wrapper {
    border: none;
  }
  ${FileViewer}.pdf-viewer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ${FileViewer}.pdf-canvas {
    width: 100%;
  }
`;
const PreviewVid = styled.video`
  width: 100%;
  height: auto;
`;
const PreviewVidLast = styled(PreviewVid)`
  position: absolute;
  width: 42.3%;
`;
const PreviewImg = styled.img`
  width: 100%;
  height: auto;
`;
const PreviewImgLast = styled(PreviewImg)`
  position: absolute;
  width: 42.3%;
`;
const ExtraPreviewContainer = styled(PostPreview1stRow)`
  width: 80%;
  height: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
`;
const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
`;
const ArrowIcon = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;
const ReadMoreSpan = styled.div`
  display: inline-block;
  color: #215bbe;
  cursor: pointer;
`;
const CloseBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ModalTextInput = styled.textarea`
  width: 95%;
  border: ${(props) =>
    props.wrongInputMsg ? "1px solid #ea1b2c" : "1px solid #b5b5b5"};
  border-radius: 8px;
  padding: 0.8rem;
  resize: none;
  max-height: 100%;
  min-height: 7rem;
  color: grey;
  height: 7rem;
  &: focus-visible {
    outline-width: 0;
  }
`;
const AlertModalTextInput = styled.textarea`
  width: 95%;
  border: ${(props) =>
    props.wrongInputSubject ? "1px solid #ea1b2c" : "1px solid #b5b5b5"};
  border-radius: 8px;
  padding: 0.8rem;
  max-height: 2rem;
  resize: none;
  color: grey;
  height: 1rem;
  &: focus-visible {
    outline-width: 0;
  }
`;
const ConfirmMsgBtn = styled.button`
  width: 16%;
  padding: 0.2rem;
  margin-right: 0.3rem;
  height: 1.9rem;
  border: none;
  border-radius: 2rem;
  font-size: small;
  background-color: ${(props) =>
    props.disabled ? "rgb(220 217 217)" : "#147350"};
  color: ${(props) => (props.disabled ? "grey" : "white")};
  cursor: pointer;
  margin: 0.5rem 1rem;
  margin-right: 0.2rem;
  position: relative;
  text-align: center;
  float: right;
`;
const HeadingContainer = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
  color: #595555;
`;
const CompanyName = styled.p`
  font-size: 0.9rem;
  letter-spacing: 0px;
  opacity: 0.75;
  margin-bottom: -8px;
`;
const CompanyNameModal = styled.p`
  font-size: 0.9rem;
  letter-spacing: 0px;
  opacity: 0.75;
  margin-bottom: 5px;
`;

const PendingItem = ({
  item,
  setProfileClicked,
  setUserProfile,
  handleApproveActionReload,
}) => {
  const [apiCalled, setApiCalled] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const [readMoreModal, setReadMoreModal] = useState(true);
  const [rejectModal, setRejectModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [ExtraPreviewModal, setExtraPreviewModal] = useState(false);
  const [rejectMsg, setRejectMsg] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSubject, setAlertSubject] = useState("");
  const [currentFileIndex, setCurrentFileIndex] = useState(2);
  const [wrongInputDialog, setWrongInputDialog] = useState(false);
  const [wrongInputSubject, setWrongInputSubject] = useState(true);
  const [wrongInputMsg, setWrongInputMsg] = useState(true);
  const [approveModal, setApproveModal] = useState(false);
  const [approveMsg, setApproveMsg] = useState("");
  const videoRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const checkAccessToken = localStorage.getItem("access_token");
  // const approve_post = process.env.REACT_APP_APPROVE_POST;
  // const alert_post = process.env.REACT_APP_ALERT_POST;

  const { isAdmin=false } = useSelector(state => state.userManagement);

  const DialogPopup = (props) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={wrongInputDialog}
        autoHideDuration={3000}
        onClose={() => {
          setWrongInputDialog(false);
        }}
        key={"top" + "right"}
      >
        <ToggleAlert
          onClose={() => {
            setWrongInputDialog(false);
          }}
          severity={props.submit ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {props.dialogContentText}
        </ToggleAlert>
      </Snackbar>
    );
  };

  const readMoreModalHandler = () => {
    setReadMoreModal((readMoreModal) => !readMoreModal);
  };

  const UserNameProcessing = (username) => {
    username = username.trim();
    let wordsArray;
    if (username.includes(" ")) {
      wordsArray = username?.split(" ");
    } else {
      wordsArray = [username];
    }
    let outputString = "";
    for (let i = 0; i < wordsArray.length; i++) {
      let userNamePart =
        wordsArray[i][0].toUpperCase() + wordsArray[i].slice(1).toLowerCase();
      outputString = outputString + " " + userNamePart;
    }
    return outputString;
  };

  const goToNextFile = () => {
    setCurrentFileIndex(
      (prevIndex) => (prevIndex + 1) % item.postFileKeys.length
    );
  };

  const goToPrevFile = () => {
    setCurrentFileIndex(
      (prevIndex) =>
        (prevIndex - 1 + item.postFileKeys.length) % item.postFileKeys.length
    );
  };

  useEffect(() => {
    if (!ExtraPreviewModal) return;
    const videoElement = videoRef.current;
    if (
      item.postFileKeys[currentFileIndex]?.s3FileType?.substr(0, 5) === "video"
    ) {
      videoElement.load();
      videoElement.play();
    }
  }, [currentFileIndex, item.postFileKeys]);

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name.includes(" ")
          ? name?.split(" ")[0][0] + "" + name?.split(" ")[1][0]
          : name[0][0]
      }`,
    };
  }

  const alertPost = (alertPost) => {
    setApiCalled(true);
    var data = qs.stringify(alertPost);
    var config = {
      method: "post",
      url: alert_post,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        axios(config)
          .then(function (response) {
            handleApproveActionReload();

            setApiCalled(false);
          })
          .catch((err) => {
            setApiCalled(false);
          });
      })
      .catch(function (error) {
        setApiCalled(false);
      });
  };

  const approvePost = (approvedPost) => {
    setApiCalled(true);
    var data = qs.stringify(approvedPost);
    var config = {
      method: "post",
      url: approve_post,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        handleApproveActionReload();
        setApiCalled(false);
      })
      .catch(function (error) {
        if (error?.response?.status === 505) {
          handleApproveActionReload();
        }
        setApiCalled(false);
      });
  };

  const rejectPost = (rejectedPost) => {
    setApiCalled(true);
    var data = qs.stringify(rejectedPost);
    var config = {
      method: "post",
      url: approve_post,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        handleApproveActionReload();
        setApiCalled(false);
      })
      .catch(function (error) {
        if (error?.response?.status === 505) {
          handleApproveActionReload();
        }
        setApiCalled(false);
      });
  };

  document.addEventListener("click", function (e) {
    if (
      document.getElementById("showPostPopup") &&
      !document.getElementById("showPostPopup").contains(e.target)
    ) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = setTimeout(() => {
        setShowPost(false);
      }, 300);
    }

    if (
      document.getElementById("showRejectModal") &&
      !document.getElementById("showRejectModal").contains(e.target)
    ) {
      closeRejectApproveModal();
    }

    if (
      document.getElementById("extraPreviewModal") &&
      !document.getElementById("extraPreviewModal").contains(e.target)
    ) {
      setExtraPreviewModal(false);
      setCurrentFileIndex(2);
    }
  });
  const closeRejectApproveModal = () => {
    if (rejectModal) {
      setRejectMsg("");
      setRejectModal(false);
    } else {
      setApproveMsg("");
      setApproveModal(false);
    }
    if(document.getElementById("inputMsg")){
      document.getElementById("inputMsg").value = "";
    }
  };

  let uploadBtnOptions;
  uploadBtnOptions = (
    <>
      <ApproveBtn
        id="showRejectModal"
        onClick={(e) => {
          e.stopPropagation();
          if (showPost) setShowPost(false);
          setApproveModal(true);
        }}
      >
        Approve
      </ApproveBtn>
      <RejectBtn
        id="showRejectModal"
        onClick={(e) => {
          e.stopPropagation();
          if (showPost) setShowPost(false);
          setRejectModal(true);
        }}
      >
        Reject
      </RejectBtn>
    </>
  );

  let uploadBtnOptions2;
  uploadBtnOptions2 = (
    <>
      <AlertBtnBig
        onClick={(e) => {
          e.stopPropagation();
          if (showPost) setShowPost(false);
          setAlertModal(true);
        }}
      >
        Alert
      </AlertBtnBig>
      <ApproveBtnBig
        onClick={(e) => {
          e.stopPropagation();
          if (showPost) setShowPost(false);
          setApproveModal(true);
        }}
      >
        Approve
      </ApproveBtnBig>
      <RejectBtnBig
        onClick={(e) => {
          e.stopPropagation();
          if (showPost) setShowPost(false);
          setRejectModal(true);
        }}
      >
        Reject
      </RejectBtnBig>
    </>
  );

  let counter = 0;

  return (
    <>
      <PendingItemWrapper key={item._id}>
        <PostDetailContainer>
          <AvatarContainer>
            {item.userProfileDetails.profilePicture ? (
              item.userProfileDetails?.profilePicture?.split("//").length <=
              2 ? (
                <Avatar
                  alt="Profile Picture"
                  src={item.userProfileDetails?.profilePicture}
                  style={{
                    padding: "0rem",
                    margin: "0 0 0 0",
                    top: "4px",
                    scale: "0.7",
                    width: "3.5rem",
                    height: "3.5rem",
                    border: "1px solid #dedede",
                  }}
                  onClick={() => {
                    setUserProfile(item._id);
                    setProfileClicked(true);
                  }}
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    String(
                      item.userProfileDetails?.fullName
                        ? item.userProfileDetails?.fullName.trim()
                        : item.postedByName.trim()
                    ).toUpperCase()
                  )}
                  style={{
                    padding: ".1rem",
                    margin: "0 0 0 0",
                    top: "5px",
                    scale: "0.9",
                  }}
                  onClick={() => {
                    setUserProfile(item._id);
                    setProfileClicked(true);
                  }}
                />
              )
            ) : (
              <Avatar
                {...stringAvatar(
                  String(
                    item.userProfileDetails?.fullName
                      ? item.userProfileDetails?.fullName.trim()
                      : item.postedByName.trim()
                  ).toUpperCase()
                )}
                style={{
                  padding: ".1rem",
                  margin: "0 0 0 0",
                  top: "5px",
                  scale: "0.9",
                }}
                onClick={() => {
                  setUserProfile(item._id);
                  setProfileClicked(true);
                }}
              />
            )}
          </AvatarContainer>
          <div>
            <NameContainer>
              <UserName
                onClick={() => {
                  setUserProfile(item._id);
                  setProfileClicked(true);
                }}
              >
                {String(
                  item.userProfileDetails?.fullName
                    ? UserNameProcessing(item.userProfileDetails.fullName)
                    : UserNameProcessing(item.postedByName)
                )}
              </UserName>
              <CompanyName>{item.userProfileDetails?.company}</CompanyName>
            </NameContainer>
            <PostContainer
              id="showPostPopup"
              onClick={(e) => {
                e.stopPropagation();
                setShowPost(true);
              }}
            >
              <PreviewContainer>
                {item.postText?.length ? (
                  <PostPreview>
                    <div dangerouslySetInnerHTML={{ __html: item.postText }} />
                  </PostPreview>
                ) : (
                  item.postText.length === 0 && (
                    <PostPreview>
                      <ReadMoreSpan>See Files.</ReadMoreSpan>
                    </PostPreview>
                  )
                )}
              </PreviewContainer>
              <DateContainer>
                <PostDate>
                  {new Date(Date.now()) - new Date(item.postedDate) < 2505600000
                    ? formatDistanceToNowStrict(new Date(item.postedDate)) +
                      " ago"
                    : new Date(item.postedDate).getDate() +
                      " " +
                      [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ][new Date(item.postedDate).getMonth()] +
                      " " +
                      new Date(item.postedDate).getFullYear()}
                </PostDate>
              </DateContainer>
            </PostContainer>
          </div>
        </PostDetailContainer>
        <ApprovalBtnContainer>
          {isAdmin ? uploadBtnOptions : ""}
        </ApprovalBtnContainer>
      </PendingItemWrapper>
      <Divider variant="middle" />
      <ModalWrapper show={showPost}>
        <ModalBody
          imageAvailable={item.postFileKeys.length > 0}
          id="showPostPopup"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AvatarPostWrapper>
            {item.userProfileDetails.profilePicture ? (
              item.userProfileDetails?.profilePicture?.split("//").length <=
              2 ? (
                <Avatar
                  alt="Profile Picture"
                  src={item.userProfileDetails?.profilePicture}
                  style={{
                    padding: "0rem",
                    margin: "0.5rem",
                    top: "4px",
                    scale: "0.7",
                    width: "3.5rem",
                    height: "3.5rem",
                    border: "1px solid #dedede",
                  }}
                  onClick={() => {
                    setUserProfile(item._id);
                    setProfileClicked(true);
                  }}
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    String(
                      item.userProfileDetails?.fullName
                        ? item.userProfileDetails?.fullName.trim()
                        : item.postedByName.trim()
                    ).toUpperCase()
                  )}
                  style={{
                    padding: ".1rem",
                    margin: "0.5rem",
                    top: "5px",
                    scale: "0.9",
                  }}
                  onClick={() => {
                    setUserProfile(item._id);
                    setProfileClicked(true);
                  }}
                />
              )
            ) : (
              <Avatar
                {...stringAvatar(
                  String(
                    item.userProfileDetails?.fullName
                      ? item.userProfileDetails?.fullName.trim()
                      : item.postedByName.trim()
                  ).toUpperCase()
                )}
                style={{
                  padding: ".1rem",
                  margin: "0 0 0 0",
                  top: "5px",
                  scale: "0.9",
                }}
                onClick={() => {
                  setUserProfile(item._id);
                  setProfileClicked(true);
                }}
              />
            )}
            <PostDetailComponent>
              <UserDetails>
                <div style={{ marginTop: "0.2rem" }}>
                  <NameContainer>
                    <UserName>
                      {String(
                        item.userProfileDetails?.fullName
                          ? UserNameProcessing(item.userProfileDetails.fullName)
                          : UserNameProcessing(item.postedByName)
                      )}
                    </UserName>
                    <CompanyNameModal>
                      {item.userProfileDetails?.company}
                    </CompanyNameModal>
                  </NameContainer>
                  <DateContainer>
                    <PostDate>
                      {new Date(Date.now()) - new Date(item.postedDate) <
                      2505600000
                        ? formatDistanceToNowStrict(new Date(item.postedDate)) +
                          " ago"
                        : new Date(item.postedDate).getDate() +
                          " " +
                          [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                          ][new Date(item.postedDate).getMonth()] +
                          " " +
                          new Date(item.postedDate).getFullYear()}
                    </PostDate>
                  </DateContainer>
                </div>
                <div style={{ marginTop: "-1.4rem" }}>
                  {isAdmin ? uploadBtnOptions2 : ""}
                </div>
              </UserDetails>
              <PostContentComponent>
                {item.postText.length > 250 && item.postFileKeys.length > 0 ? (
                  readMoreModal ? (
                    <PostTextComponent>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.postText.substr(0, 250),
                        }}
                      />
                      <ReadMoreSpan onClick={readMoreModalHandler}>
                        See more.
                      </ReadMoreSpan>
                    </PostTextComponent>
                  ) : (
                    <PostTextComponent>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.postText }}
                      />
                      <ReadMoreSpan onClick={readMoreModalHandler}>
                        See less.
                      </ReadMoreSpan>
                    </PostTextComponent>
                  )
                ) : (
                  <PostTextComponent>
                    <div dangerouslySetInnerHTML={{ __html: item.postText }} />
                  </PostTextComponent>
                )}
                {item.postFileKeys.length > 0 && (
                  <PostImageComponent>
                    {item.postFileKeys.map((fileData, index) => {
                      counter++;
                      return (
                        <div key={`pendingItem_${index}`}>
                          {(fileData?.s3FileType?.substr(0, 5) === "video" ||
                            fileData?.s3FileType?.substr(0, 5) === "image") && (
                            <>
                              {item.postFileKeys.indexOf(fileData) === 0 && (
                                <PostPreview1stRow
                                  style={{ marginBottom: "-0.15rem" }}
                                >
                                  {fileData?.s3FileType?.substr(0, 5) ===
                                    "video" && (
                                    <PreviewVid controls>
                                      <source
                                        src={fileData.s3FileSignedUrl}
                                        type="video/mp4"
                                      />
                                    </PreviewVid>
                                  )}
                                  {fileData?.s3FileType?.substr(0, 5) ===
                                    "image" && (
                                    <PreviewImg
                                      src={fileData.s3FileSignedUrl}
                                      alt="image"
                                    />
                                  )}
                                </PostPreview1stRow>
                              )}
                              {item.postFileKeys.length === 2 &&
                                item.postFileKeys.indexOf(fileData) === 1 && (
                                  <PostPreview2ndRow>
                                    {fileData?.s3FileType?.substr(0, 5) ===
                                      "video" && (
                                      <PreviewVid controls>
                                        <source
                                          src={fileData.s3FileSignedUrl}
                                          type="video/mp4"
                                        />
                                      </PreviewVid>
                                    )}
                                    {fileData?.s3FileType?.substr(0, 5) ===
                                      "image" && (
                                      <PreviewImg
                                        src={fileData.s3FileSignedUrl}
                                        alt="image"
                                      />
                                    )}
                                  </PostPreview2ndRow>
                                )}

                              {item.postFileKeys.length >= 3 &&
                                counter === 3 && (
                                  <PostPreview2ndRow>
                                    <PostPreview2ndRowEl
                                      style={{ marginRight: "0.3rem" }}
                                    >
                                      {item.postFileKeys[1]?.s3FileType?.substr(
                                        0,
                                        5
                                      ) === "video" && (
                                        <PreviewVid
                                          controls
                                          style={{ width: "100%" }}
                                        >
                                          <source
                                            src={
                                              item.postFileKeys[1]
                                                .s3FileSignedUrl
                                            }
                                            type="video/mp4"
                                          />
                                        </PreviewVid>
                                      )}
                                      {item.postFileKeys[1]?.s3FileType?.substr(
                                        0,
                                        5
                                      ) === "image" && (
                                        <PreviewImg
                                          src={
                                            item.postFileKeys[1].s3FileSignedUrl
                                          }
                                          alt="image"
                                        />
                                      )}
                                    </PostPreview2ndRowEl>
                                    <PostPreview2ndRowEl
                                      style={{ position: "relative" }}
                                    >
                                      {item.postFileKeys[2]?.s3FileType?.substr(
                                        0,
                                        5
                                      ) === "video" && (
                                        <PreviewVidLast
                                          controls
                                          style={{ width: "100%" }}
                                        >
                                          <source
                                            src={
                                              item.postFileKeys[2]
                                                ?.s3FileSignedUrl
                                            }
                                            type="video/mp4"
                                          />
                                        </PreviewVidLast>
                                      )}
                                      {item.postFileKeys[2]?.s3FileType?.substr(
                                        0,
                                        5
                                      ) === "image" && (
                                        <PreviewImg
                                          src={
                                            item.postFileKeys[2].s3FileSignedUrl
                                          }
                                          alt="image"
                                        />
                                      )}

                                      {item.postFileKeys.length > 3 && (
                                        <PostNumBackdrop
                                          id="extraPreviewModal"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowPost(false);
                                            setExtraPreviewModal(true);
                                          }}
                                        >
                                          <p>+{item.postFileKeys.length - 3}</p>
                                        </PostNumBackdrop>
                                      )}
                                    </PostPreview2ndRowEl>
                                  </PostPreview2ndRow>
                                )}
                            </>
                          )}
                          {fileData?.s3FileType?.substr(0, 5) === "appli" && (
                            <PostFileComponent>
                              {item.postFileKeys.indexOf(fileData) === 0 && (
                                <PostPreview1stRow
                                  style={{
                                    marginBottom: "0.3rem",
                                    height: "22rem",
                                  }}
                                >
                                  <FileViewer
                                    key={Math.random()}
                                    fileType={fileData?.s3FileKey.substr(
                                      fileData?.s3FileKey.lastIndexOf(".") + 1,
                                      fileData?.s3FileKey.length
                                    )}
                                    filePath={fileData?.s3FileSignedUrl}
                                    style={{
                                      width: "95%",
                                      border: "2px solid black",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                </PostPreview1stRow>
                              )}
                              {item.postFileKeys.length === 2 &&
                                item.postFileKeys.indexOf(fileData) === 1 && (
                                  <PostPreview1stRow
                                    style={{
                                      marginBottom: "0.3rem",
                                      height: "22rem",
                                    }}
                                  >
                                    <FileViewer
                                      key={Math.random()}
                                      fileType={fileData?.s3FileKey.substr(
                                        fileData?.s3FileKey.lastIndexOf(".") +
                                          1,
                                        fileData?.s3FileKey.length
                                      )}
                                      filePath={fileData?.s3FileSignedUrl}
                                      style={{
                                        width: "95%",
                                        border: "2px solid black",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                  </PostPreview1stRow>
                                )}
                              {item.postFileKeys.length >= 3 &&
                                counter === 3 && (
                                  <PostPreview2ndRow
                                    style={{
                                      width: "102%",
                                      marginBottom: "0.6rem",
                                    }}
                                  >
                                    {item.postFileKeys[1]?.s3FileType?.substr(
                                      0,
                                      5
                                    ) === "appli" && (
                                      <PostPreview2ndRowEl
                                        style={{ marginRight: "0.3rem" }}
                                      >
                                        <FileViewer
                                          key={Math.random()}
                                          fileType={item.postFileKeys[1]?.s3FileKey.substr(
                                            item.postFileKeys[1]?.s3FileKey.lastIndexOf(
                                              "."
                                            ) + 1,
                                            item.postFileKeys[1]?.s3FileKey
                                              .length
                                          )}
                                          filePath={
                                            item.postFileKeys[1]
                                              ?.s3FileSignedUrl
                                          }
                                          style={{
                                            width: "95%",
                                            border: "2px solid black",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        />
                                      </PostPreview2ndRowEl>
                                    )}
                                    <PostPreview2ndRowEl
                                      style={{ position: "relative" }}
                                    >
                                      <FileViewer
                                        key={Math.random()}
                                        fileType={item.postFileKeys[2]?.s3FileKey.substr(
                                          item.postFileKeys[2]?.s3FileKey.lastIndexOf(
                                            "."
                                          ) + 1,
                                          item.postFileKeys[2]?.s3FileKey.length
                                        )}
                                        filePath={
                                          item.postFileKeys[2]?.s3FileSignedUrl
                                        }
                                        style={{
                                          width: "95%",
                                          border: "2px solid black",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      />

                                      {item.postFileKeys.length > 3 && (
                                        <PostNumBackdrop
                                          id="extraPreviewModal"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowPost(false);
                                            setExtraPreviewModal(true);
                                          }}
                                        >
                                          <p>+{item.postFileKeys.length - 3}</p>
                                        </PostNumBackdrop>
                                      )}
                                    </PostPreview2ndRowEl>
                                  </PostPreview2ndRow>
                                )}
                            </PostFileComponent>
                          )}
                        </div>
                      );
                    })}
                  </PostImageComponent>
                )}
              </PostContentComponent>
            </PostDetailComponent>
          </AvatarPostWrapper>
        </ModalBody>
      </ModalWrapper>
      <ModalWrapperEP show={ExtraPreviewModal}>
        <ModalBodyEP
          id="extraPreviewModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <ExtraPreviewContainer>
              <ArrowContainer>
                <ArrowIcon
                  onClick={() => {
                    goToPrevFile();
                  }}
                >
                  <FaArrowLeft />
                </ArrowIcon>
                <ArrowIcon
                  onClick={() => {
                    goToNextFile();
                  }}
                >
                  <FaArrowRight />
                </ArrowIcon>
              </ArrowContainer>
              {currentFileIndex >= 0 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {item.postFileKeys[currentFileIndex]?.s3FileType?.substr(
                    0,
                    5
                  ) === "video" ? (
                    <PreviewVid ref={videoRef} controls>
                      <source
                        id="source_video"
                        src={
                          item.postFileKeys[currentFileIndex]?.s3FileSignedUrl
                        }
                        type="video/mp4"
                      />
                    </PreviewVid>
                  ) : item.postFileKeys[currentFileIndex]?.s3FileType?.substr(
                      0,
                      5
                    ) === "image" ? (
                    <PreviewImg
                      src={item.postFileKeys[currentFileIndex]?.s3FileSignedUrl}
                      alt="image"
                    />
                  ) : (
                    item.postFileKeys[currentFileIndex]?.s3FileType?.substr(
                      0,
                      5
                    ) === "appli" && (
                      <ExtraPreviewFile>
                        <FileViewer
                          key={Math.random()}
                          fileType={item.postFileKeys[
                            currentFileIndex
                          ]?.s3FileKey.substr(
                            item.postFileKeys[
                              currentFileIndex
                            ]?.s3FileKey.lastIndexOf(".") + 1,
                            item.postFileKeys[currentFileIndex]?.s3FileKey
                              .length
                          )}
                          filePath={
                            item.postFileKeys[currentFileIndex]?.s3FileSignedUrl
                          }
                          style={{
                            width: "95%",
                            border: "2px solid black",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </ExtraPreviewFile>
                    )
                  )}
                </div>
              )}
            </ExtraPreviewContainer>
          </div>
        </ModalBodyEP>
      </ModalWrapperEP>
      <ModalWrapper show={rejectModal}>
        <RejectModalBody
          id="showRejectModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CloseBtnContainer
            onClick={() => {
              closeRejectApproveModal();
            }}
          >
            <svg
              width="50"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              style={{
                color: "#ffffff",
                marginRight: "-1.5rem",
                marginTop: "0.5rem",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9"
                cy="9"
                r="8.75"
                fill="white"
                stroke="#D1D1D1"
                strokeWidth="0.5"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {}}
              />
              <path
                d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                fill="#FF0000"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {}}
              />
            </svg>
          </CloseBtnContainer>
          <HeadingContainer>Reason to reject</HeadingContainer>
          {/* <ModalTextInput
            placeholder="Type here..."
            id="inputMsg"
            onChange={(e) => {
              if (e.target.value.trim().length === 0) {
                setRejectMsg("");
              } else {
                setRejectMsg(e.target.value);
              }
            }}
          /> */}
          <CKEditor
            id="inputMsg"
            data={rejectMsg || ""}
            editor={DecoupledEditor}
            config={{
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "alignment",
                  "|",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "|",
                  "link",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "fontFamily",
                  "fontSize",
                  "fontColor",
                  "fontBackgroundColor",
                ],
              },
              heading: {
                options: [
                  { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                  { model: "heading3", view: "h4", title: "Heading", class: "ck-heading_heading4" },
                ],
              },
              link: {
                addTargetToExternalLinks: true,
              },
            }}
            onReady={(editor) => {
              if (editor) {
                editor?.ui
                  ?.getEditableElement()
                  ?.parentElement?.insertBefore(
                    editor.ui.view?.toolbar?.element,
                    editor.ui.getEditableElement()
                  );
              }
            }}
            onChange={(event, editor) => {
              const ckEditorData = editor.getData();
              if (ckEditorData.trim().length === 0) {
                setRejectMsg("");
              } else {
                setRejectMsg(ckEditorData);
              }
            }}
          />
          <ConfirmMsgBtn
            disabled={!rejectMsg}
            onClick={() => {
              setRejectModal(false);
              rejectPost({
                accessToken: checkAccessToken,
                postedByName: item.postedByName,
                postedByEmail: item.postedByEmail,
                postFileKeys: item.postFileKeys,
                postText: item.postText,
                status: "Rejected",
                statusMessage: rejectMsg,
                date: item.postedDate,
                objectId: item._id,
              });
              document.getElementById("inputMsg").value = "";
            }}
          >
            Send Email
          </ConfirmMsgBtn>
        </RejectModalBody>
      </ModalWrapper>
      <ModalWrapper show={approveModal}>
        <RejectModalBody
          id="showRejectModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CloseBtnContainer
            onClick={() => {
              closeRejectApproveModal();
            }}
          >
            <svg
              width="50"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              style={{
                color: "#ffffff",
                marginRight: "-1.5rem",
                marginTop: "0.5rem",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9"
                cy="9"
                r="8.75"
                fill="white"
                stroke="#D1D1D1"
                strokeWidth="0.5"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {}}
              />
              <path
                d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                fill="#FF0000"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {}}
              />
            </svg>
          </CloseBtnContainer>
          <HeadingContainer>Enter your message</HeadingContainer>
          {/* <ModalTextInput
            placeholder="Type here..."
            id="inputMsg"
            onChange={(e) => {
              if (e.target.value.trim().length === 0) {
                setApproveMsg("");
              } else {
                setApproveMsg(e.target.value);
              }
            }}
          /> */}
          <CKEditor
            id="inputMsg"
            data={approveMsg || ""}
            editor={DecoupledEditor}
            config={{
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "alignment",
                  "|",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "|",
                  "link",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "fontFamily",
                  "fontSize",
                  "fontColor",
                  "fontBackgroundColor",
                ],
              },
              heading: {
                options: [
                  { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                  { model: "heading3", view: "h4", title: "Heading", class: "ck-heading_heading4" },
                ],
              },
              link: {
                addTargetToExternalLinks: true,
              },
            }}
            onReady={(editor) => {
              if (editor) {
                editor?.ui
                  ?.getEditableElement()
                  ?.parentElement?.insertBefore(
                    editor.ui.view?.toolbar?.element,
                    editor.ui.getEditableElement()
                  );
              }
            }}
            onChange={(event, editor) => {
              const ckEditorData = editor.getData();
              if (ckEditorData.trim().length === 0) {
                setApproveMsg("");
              } else {
                setApproveMsg(ckEditorData);
              }
            }}
          />
          <ConfirmMsgBtn
            onClick={() => {
              setApproveModal(false);
              approvePost({
                accessToken: checkAccessToken,
                postedByName: item.postedByName,
                postedByEmail: item.postedByEmail,
                postText: item.postText,
                status: "Approved",
                statusMessage: approveMsg,
                date: item.postedDate,
                objectId: item._id,
              });
              document.getElementById("inputMsg").value = "";
            }}
          >
            Send Email
          </ConfirmMsgBtn>
        </RejectModalBody>
      </ModalWrapper>
      <ModalWrapper show={alertModal}>
        <AlertModalBody
          id="showAlertModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CloseBtnContainer
            onClick={() => {
              setAlertSubject("");
              setAlertMsg("");
              setAlertModal(false);
              document.getElementById("inputMsg").value = "";
            }}
          >
            <svg
              width="50"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              style={{
                color: "#ffffff",
                marginRight: "-1.5rem",
                marginTop: "0.5rem",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9"
                cy="9"
                r="8.75"
                fill="white"
                stroke="#D1D1D1"
                strokeWidth="0.5"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {}}
              />
              <path
                d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                fill="#FF0000"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {}}
              />
            </svg>
          </CloseBtnContainer>
          <HeadingContainer>Email Subject</HeadingContainer>
          <AlertModalTextInput
            // wrongInputSubject={wrongInputSubject}
            placeholder="Type here..."
            id="inputMsg"
            onChange={(e) => {
              /* if (alertSubject.length <= e.target.value.length - 1) {
                if (e.target.value.trim().length === 0) {
                  setWrongInputDialog(true);
                } else {
                  setWrongInputSubject(false);
                  setWrongInputDialog(false);
                  setAlertSubject(e.target.value);
                }
              } else {
                if (e.target.value.trim().length === 0) {
                  setWrongInputSubject(true);
                  setWrongInputDialog(true);
                } else {
                  setWrongInputSubject(false);
                  setWrongInputDialog(false);
                }
                setAlertSubject(e.target.value);
              } */
              if (e.target.value.trim().length === 0) {
                setAlertSubject("");
              } else {
                setAlertSubject(e.target.value);
              }
            }}
          />
          <DialogPopup dialogContentText="Email subject cannot be empty." />
          <HeadingContainer>Notification Message</HeadingContainer>
          {/* <ModalTextInput
            wrongInputMsg={wrongInputMsg}
            placeholder="Type here..."
            id="inputMsg"
            onChange={(e) => {
              if (alertSubject.length <= e.target.value.length - 1) {
                if (e.target.value.trim().length === 0) {
                  setWrongInputDialog(true);
                } else {
                  setWrongInputMsg(false);
                  setWrongInputDialog(false);
                  setAlertMsg(e.target.value);
                }
              } else {
                if (e.target.value.trim().length === 0) {
                  setWrongInputMsg(true);
                  setWrongInputDialog(true);
                } else {
                  setWrongInputMsg(false);
                  setWrongInputDialog(false);
                }
                setAlertMsg(e.target.value);
              }
            }}
          /> */}
          <CKEditor
            id="inputMsg"
            data={approveMsg || ""}
            editor={DecoupledEditor}
            config={{
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "alignment",
                  "|",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "|",
                  "link",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "fontFamily",
                  "fontSize",
                  "fontColor",
                  "fontBackgroundColor",
                ],
              },
              heading: {
                options: [
                  { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                  { model: "heading3", view: "h4", title: "Heading", class: "ck-heading_heading4" },
                ],
              },
              link: {
                addTargetToExternalLinks: true,
              },
            }}
            onReady={(editor) => {
              if (editor) {
                editor?.ui
                  ?.getEditableElement()
                  ?.parentElement?.insertBefore(
                    editor.ui.view?.toolbar?.element,
                    editor.ui.getEditableElement()
                  );
              }
            }}
            onChange={(event, editor) => {
              const ckEditorData = editor.getData();
              if (ckEditorData.trim().length === 0) {
                setAlertMsg("");
              } else {
                setAlertMsg(ckEditorData);
              }
            }}
          />
          <DialogPopup dialogContentText="Email content cannot be empty." />
          <ConfirmMsgBtn
            disabled={!alertMsg || !alertSubject}
            onClick={() => {
              setApiCalled(true);
              approvePost({
                accessToken: checkAccessToken,
                postedByName: item.postedByName,
                postedByEmail: item.postedByEmail,
                postText: item.postText,
                status: "Approved",
                statusMessage: "",
                date: item.postedDate,
                objectId: item._id,
              });

              alertPost({
                accessToken: checkAccessToken,
                postedByName: item.postedByName,
                postedByEmail: item.postedByEmail,
                postText: item.postText,
                emailBody: alertMsg,
                emailSubject: alertSubject,
                status: "Approved",
                statusMessage: "",
                objectId: item._id,
                date: item.postedDate,
              });
              document.getElementById("inputMsg").value = "";
              setAlertModal(false);
            }}
          >
            Send Alert
          </ConfirmMsgBtn>
        </AlertModalBody>
      </ModalWrapper>
      <ModalWrapper show={apiCalled}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularRotatingLines />
        </div>
      </ModalWrapper>
    </>
  );
};

export default PendingItem;
