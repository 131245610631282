import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import getCountryName from "../../../Utils/GetCountryName";
import { extractPlaceholders, getCountryConfig, getCountryDataByProperty } from "../../../Utils/CommonUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1E795E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: 150,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1E795E66",
    color: "black",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#8BB8AB4D",
    color: "black",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories) {
  return { name, calories };
}

const PSAItemHierarchyTypeCodeId = 1;
const CatItemHierarchyTypeCodeId = 2;
function getHierarchyDesc(data, codeId) {
  let descResult = '';
  if (data.itemHierarchies) {
    data.itemHierarchies.forEach(itemHierarchy => {
      if (itemHierarchy.itemHierarchyCode && itemHierarchy.itemHierarchyCode.itemHierarchyTypeCodeId == codeId) {
        descResult =  itemHierarchy.itemHierarchyCode.description;
      }
    });
  }

  // return default empty string
  return descResult;
}

export default function CustomizedTables({ data }) {
  // const month=data.shelfLife?.substr(0,2);
  // const day=data.shelfLife?.substr(3,2);
  // month==="00"? day+` day`: day==="00"? month+` month` : month+` month `+day+` day`
  const countryHierarchyInfo = data?.itemHierarchies?.find(hierarchy => hierarchy.itemHierarchyCode.itemHierarchyTypeCodeId === 3);
  const countryId = countryHierarchyInfo?.itemHierarchyCodeId;
  const countryOfOrigin= data.countryOfOrigin? getCountryName(data.countryOfOrigin): "";
  
  const psa= getHierarchyDesc(data, PSAItemHierarchyTypeCodeId);
  const category= getHierarchyDesc(data, CatItemHierarchyTypeCodeId);
  const ldu= data.lowestDeliverableUnit ? data.lowestDeliverableUnit : "";

  const decimalPrecision = getCountryConfig(countryId, 'PRICE_SUPPORT_DECIMAL')
  const srp = data.minSrp
    ? data.maxSrp == undefined || data.minSrp === data.maxSrp
      ? `${data.minSrp.toFixed(decimalPrecision)} (*)`
      : `${data.minSrp.toFixed(decimalPrecision)} - ${data.maxSrp.toFixed(
          decimalPrecision
        )} (*)`
    : "-";
  
  const payload = {
    ...data,
    countryOfOrigin,
    psa,
    category,
    ldu,
    srp
  }
  const rows = getCountryConfig(countryId, "PRODUCT_TABLE_FIELDS").map(
    (tableField) => {
      let value = tableField.valueProperty;
      const placeholders = extractPlaceholders(value);
      placeholders.forEach(placeholder => {
        value = value.replace(`{{${placeholder}}}`, payload[placeholder] || "").replaceAll("\\n", ' ')
      })
      return createData(tableField.fieldName, value?.trim())
    }
  );
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "1px solid white",
                width: "35%",
                fontFamily: "Roboto",
                fontWeight: "500",
                wordWrap: "break-word",
                padding: "0.5rem 1rem",
              }}
            >
              Product Number
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                wordWrap: "break-word",
                padding: "0.5rem 1rem",
              }}
            >
              {data.licenseeSystemItemID}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            if(row.calories === null || row.calories === undefined || row.calories === ""){
              return
            }
            return(
            <StyledTableRow key={row.name}>
              <StyledTableCell
                style={{
                  borderRight: "1px solid white",
                  width: "35%",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  padding: "0.5rem 1rem",
                }}
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  padding: "0.5rem 1rem",
                }}
              >
                {row.calories}
              </StyledTableCell>
            </StyledTableRow>
)})}
        </TableBody>
      </Table>
     {
      <div>
        <p style={{ margin: "0.5rem 0.8rem", color: "rgb(93, 93, 93)", fontWeight: 500, fontSize: "0.8rem"}}>
          {getCountryConfig(countryId, 'ADDITIONAL_FOOTNOTES')}
        </p>
      </div>
     }
    </TableContainer> 
  );
}
