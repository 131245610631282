import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './CategoryCard.scss'; 
import NoImage from '../PrivateBrandCatalog/assets/NoImage.png';
import AddToListButton from '../BrandedMerchandise/AddToListButton';
import { BRANDED_MERCHANDISE } from '../../../Constants/Constant';
import { useSelector } from 'react-redux';
import Badge from './BadgeComponent';

const CategoryCard = (props) => {
  const {
    itemId,
    cardName,
    imgSrc,
    source,
    handleEditClick,
    price,
    srp,
    minimumOrderQty,
    index,
    moveCard,
    handleCardClick,
  } = props;
  const { isAdmin = false } = useSelector((state) => state.userManagement);
  const ItemType = 'PRODUCT';
  const {PBNotification= []} = useSelector(state=> state.userManagement);
  const [{ isDragging }, drag] = useDrag(
    () =>
      ({
        type: ItemType,
        item: { id: itemId, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        canDrag: isAdmin, 
      }),
    [isAdmin]
  );

  const [, drop] = useDrop(
    () =>
      ({
        accept: ItemType,
        hover: (item) => {
          if (!isAdmin) return; 
          const draggedIndex = item.index;
          const hoverIndex = index;

          if (draggedIndex === hoverIndex) return;

          moveCard(draggedIndex, hoverIndex);
          item.index = hoverIndex;
        },
      }),
    [isAdmin]
  );

  const ref = (node) => {
    if (isAdmin && source === 'Collection Product List') {
      drag(drop(node)); 
    }
  };

  return (
    <div
      ref={ref}
      className={`card-wrapper ${isDragging ? 'dragging' : ''}`}
      onClick={() => handleCardClick(cardName, itemId)}
    >
      <div className="category-card-img">
        {imgSrc?.length ? (
          <>
          {PBNotification.length > 0 && source === 'CountryList' && PBNotification.includes(itemId) &&(
            <div className="Badge">
              <Badge label="NEW UPDATES" />
            </div>
           )}
          <img className="card-img" alt="card image" src={imgSrc} style={{objectFit:`${source==='CountryList'? 'cover': 'content'}`}}/>
          </>
        ) : (
          <img className="card-img" alt="fallback image" src={NoImage} />
        )}
      </div>
      <div className="category-card-name-container">
        <div className="category-card-name">{cardName}</div>

        {source === 'Collection Product List' &&(
          <div className="category-card-price-container">
            <div className="category-card-price">Price USD: ${`${price ? price.toFixed(2) :'-'}`}</div>
            <div className="category-card-srp">SRP USD: ${`${srp ? srp.toFixed(2) :'-'}`}</div>
            <div className="category-card-srp">Minimum Order Quantity: {minimumOrderQty ? minimumOrderQty: '0'} </div>
              <AddToListButton itemId={itemId} />
            </div>
        )}
        
        {isAdmin && source === BRANDED_MERCHANDISE && (
          <button
            className="edit-button"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(itemId);
            }}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
