import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserManagement.scss';
import styled from 'styled-components';
import { addUser, editUser, deleteUser, updateUserStatus, resetUser } from '../../../service/userManagementService';
import { logoDASubFolder, userManagementCountryList, userManagementMsgs, accessFeatureList, PRIVATE_BRAND_CATALOG, LOGOS_DIGITAL_ASSETS, BRAND_STANDARDS, SOCIAL_MEDIA_ASSETS, WORKSHOP_MATERIALS, POP_SIGNAGE, TRAINING_MATERIAL, NEWSLETTER } from '../../../Constants/Constant';
import CustomConfirmationPopup from '../Common/CustomConfirmationPopup';
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
	FormControl,
	Divider,
	IconButton
} from '@mui/material';
import { ArrowBackIos} from '@mui/icons-material';
import { useSnackbar } from "notistack";
import LoadingIndicator from '../Common/LoadingIndicator';
import { checkExpiryAndRedirect } from '../../../Utils/CommonUtils';
import { Autocomplete } from '@mui/material';
import { trackMixPanelEvent } from '../../../mixpanel/mixpanel';

const MAX_CHAR_LIMIT = 50;

const Wrapper = styled.div`
	padding: 10rem 5rem 0rem 5rem;
	min-height: 80vh;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: #f7fafc;
	position: relative;

	.MuiFormControl-root {
		min-height: 5rem;
	}

	@media (min-width: 1521px) {
		padding-left: 10vw;
		padding-right: 10vw;
	}
	@media (max-width: 770px) {
		padding: 10rem 2rem 4rem 2rem;
	}
`;
const BreadcrumbContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	cursor: pointer;
`;

let isAccessControlChanged = false;
let isDataUpdated = false;
const sortedCountryList = userManagementCountryList.sort();

const AddUser = ({ closeBtnHandler }) => {
	const {state = {}} = useLocation();
	const editUserObj = state.userObj || {};
	const filterObj = state.filterObj || null;
	let rowsPerPage = state.rowsPerPage ;
	let page = state.page ;
	const isEditUser = !!Object.keys(editUserObj).length;
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		fullName: isEditUser ? editUserObj.fullName : '',
		emailId: isEditUser ? editUserObj.userEmail : '',
		country: isEditUser ? editUserObj.country : '',
		company: isEditUser ? editUserObj.company : '',
		userAbout: isEditUser ? editUserObj.userAbout : '',
		userStatus: isEditUser ? editUserObj.userStatus : '',
		enabled: isEditUser ? editUserObj.enabled : false,
		isAdmin: isEditUser ? editUserObj.isAdmin : false,
		cognitoUserName: isEditUser ? editUserObj.cognitoUserName : ''
	});
	const [checkboxesChecked, setCheckboxesChecked] = useState(isEditUser ? editUserObj.accessString : []);
	const [emailError, setEmailError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [titleError, setTitleError] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [companyError, setCompanyError] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState('');
	const [requiredFieldError, setRequiredFieldError] = useState({});	

	const navigate = useNavigate();

	const { enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		return () => {
			isAccessControlChanged = false;
			isDataUpdated = false;
		};
	}, []);

	const userStatusObj = {
		"FORCE_CHANGE_PASSWORD": "Force change password",
		"CONFIRMED": "Confirmed"
	};

	const navigateUserList = () => {
		navigate('/userManagement', {
			state: {
				 rowsPerPage,
				 page,
				 filterObj,
			}
		});
	};

	const handleDeleteClick = () => {
		setDisplayConfirmDialog('delete');
	};

	const handleConfirmDialog = async () => {
		try {
			const isTokenActive = checkExpiryAndRedirect();
			if (!isTokenActive) {
				return;
			}
			setLoading(true);
			const action = displayConfirmDialog;
			setDisplayConfirmDialog('');
			if (action === 'delete') {
				deleteUser({ emailId: editUserObj.userEmail, userName: editUserObj.cognitoUserName })
					.then(() => {
						displayToastMsg({ message: userManagementMsgs.deleteUserSuccess, variant: "success" });
						navigateUserList();
					})
					.catch(err => {
						displayToastMsg({ message: userManagementMsgs.deleteUserFailure, variant: "error" });
					})
					.finally(() => {
						setLoading(false);
					});
			} else if (action === 'userStatus') {
				updateUserStatus({ userName: formData.cognitoUserName, deactivate: formData.enabled })
					.then(res => {
						const toastMsg = formData.enabled ? userManagementMsgs.deactivateUserSuccess : userManagementMsgs.activateUserSuccess;
						displayToastMsg({
							message: toastMsg,
							variant: 'success'
						});
						/* setFormData(prevState => ({
							...prevState,
							enabled: !formData.enabled
						})); */
						navigateUserList();
					})
					.catch(err => {
						displayToastMsg({
							message: userManagementMsgs.activateUserFailure,
							variant: 'error'
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}
		} catch (error) {
			console.error('Error deleting user:', error);
		}
	};

	const handleCancelDialog = () => {
		setDisplayConfirmDialog('');
	};

	const handleChange = (e ) => {
		const { name, value, type, checked } = e.target;
		isDataUpdated = isEditUser;
		if (type === 'checkbox') {
			if (name === 'isAdmin') {
				setCheckboxesChecked(() => {
					return checked ? [
						'7-Eleven Master Brand Logo',
						'Slurpee',
						'Private Brand',
						'7Now',
						'7Rewards',
						'Miscellaneous',
						BRAND_STANDARDS,
						SOCIAL_MEDIA_ASSETS,
						WORKSHOP_MATERIALS,
						POP_SIGNAGE,
						TRAINING_MATERIAL,
						PRIVATE_BRAND_CATALOG,
						NEWSLETTER
					] : []
				});
				setFormData(prevState => ({
					...prevState,
					isAdmin: checked
				}));
			} else {
				setCheckboxesChecked(prevState => {
					if (checked) {
						return [
							...prevState,
							name
						];
					}
					return prevState.filter(option => option !== name);
				});
			}
			isAccessControlChanged = true;
		} else {
			const { name, value } = e.target;
			setFormData((prev) => ({
			...prev,
			[name]: value
			}));

			
			const errors = { ...requiredFieldError };

			
			if (value.trim() === '') {
			errors[name] = 'This field is required and cannot be left empty';
			} else {
			errors[name] = '';
			}

			
			setRequiredFieldError(errors);

			
			switch (name) {
			case 'fullName':
				if (value.trim() !== '') {
				if (value.length > MAX_CHAR_LIMIT) {
					setNameError('Maximum character limit exceeded');
				} else if (!validateName(value)) {
					setNameError('Name should not contain numbers or special characters');
				} else {
					setNameError(''); 
				}
				} else {
				setNameError('');  
				}
				break;

			// case 'company':
			// 	if(value.trim() !== ''){
			// 		setCountryError('');
			// 	}

			case 'emailId':
				if (value.trim() !== '') {
				if (value.length > MAX_CHAR_LIMIT) {
					setEmailError('Maximum character limit exceeded');
				} else if (!validateEmail(value)) {
					setEmailError('Invalid email address');
				} else {
					setEmailError(''); 
				}
				} else {
				setEmailError('');  
				}
				break;

			case 'userAbout':
				if (value.trim() !== '') {
				if (value.length > MAX_CHAR_LIMIT) {
					setTitleError('Maximum character limit exceeded');
				} else if (!validateTitle(value)) {
					setTitleError('Please enter a valid title');
				} else {
					setTitleError('');  
				}
				} else {
				setTitleError('');  
				}
				break;

			case 'company':
				if (value.trim() !== '') {
				if (value.length > MAX_CHAR_LIMIT) {
					setCompanyError('Maximum character limit exceeded');
				} else if (!validateCompany(value)) {
					setCompanyError('Company should not contain special characters');
				} else {
					setCompanyError('');  
				}
				} else {
				setCompanyError('');  
				}
				break;

			default:
				break;
			}
		}}
	const validateCountry = (country) => {
		return country !== ''; 
	};

	const validateCompany = (company) => {
		const regex = /^[a-zA-Z0-9-. ]+$/;
		return regex.test(company) && company.trim().length > 0;
	};

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const validateTitle = (userAbout) => {
		const regex = /^[a-zA-Z0-9-.,!@#$%^&*()_+={}\[\]:;"'<>,.?/|\\ ]+$/;
		return regex.test(userAbout) && userAbout.trim().length > 0;
	};


	const validateName = (name) => {
		const nameRegex = /^[a-zA-Z\s\(\)\.]+$/;
		return nameRegex.test(name) && name.trim().length > 0;
	};

	const displayToastMsg = (toastObj) => {
		enqueueSnackbar(toastObj.message, {
			variant: toastObj.variant,
			preventDuplicate: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
		});
	};

	const constructPayload = () => {
		const initialData = {
			fullName: editUserObj.fullName || '',
			emailId: editUserObj.userEmail || '',
			country: editUserObj.country || '',
			company: editUserObj.company || '',
			userAbout: editUserObj.userAbout || '',
			userStatus: editUserObj.userStatus || '',
			enabled: editUserObj.enabled || false,
			isAdmin: editUserObj.isAdmin || false,
			cognitoUserName: editUserObj.cognitoUserName || ''
		};
		const payload = {};
		for (const key in formData) {
			if (formData[key] !== initialData[key]) {
				payload[key] = formData[key];
			}
		}
		return payload;
	};

	const isAccessControlEqual = (origAccControlArr, formDataAccControlArr) => {
		if (origAccControlArr.length !== formDataAccControlArr.length) {
			return false;
		}

		const sortedArr1 = origAccControlArr.slice().sort();
		const sortedArr2 = formDataAccControlArr.slice().sort();

		for (let i = 0; i < sortedArr1.length; i++) {
			if (sortedArr1[i] !== sortedArr2[i]) {
				return false;
			}
		}

		return true;
	}

	const handleSubmit = async (e) => {
		const isTokenActive = checkExpiryAndRedirect();
		if (!isTokenActive) {
			return;
		}
		e.preventDefault();

		const isEmailValid = validateEmail(formData.emailId);
		const isNameValid = validateName(formData.fullName);
		const isTitleValid = validateTitle(formData.userAbout);
		const isCompanyValid = validateCompany(formData.company);
	
		if (!isEmailValid || !isNameValid || !isTitleValid || !isCompanyValid) {
			setEmailError(!isEmailValid ? 'Invalid email address ' : '');
			setNameError(!isNameValid ? 'Name should not contain numbers or special characters' : '');
			setTitleError(!isTitleValid ? 'Title should not contain numbers or special characters' : '');
			setCompanyError(!isCompanyValid ? 'Please enter company field' : '');
			return;
		} 
		else {
			setEmailError('');
			setNameError('');
			setTitleError('');
			setCompanyError('');
			setCountryError('');
		}

		setLoading(true);

		const formPayload = constructPayload();

		if (isAccessControlChanged) {
			if (!formPayload.accessControl) {
				formPayload.accessControl = {};
			}
			checkboxesChecked.forEach(feature => {
				formPayload.accessControl[feature] = {
					create: formData.isAdmin,
					edit: formData.isAdmin,
					delete: formData.isAdmin,
					view: true,
				};
			});
		}

		try {
			if (isEditUser) {
				editUser({...formPayload, userName : editUserObj.cognitoUserName, userEmail: editUserObj.userEmail})
					.then(res => {
						displayToastMsg({
							message: userManagementMsgs.editUserSuccess,
							variant: 'success'
						});
						navigateUserList();
					})
					.catch(err => {
						const toastMsg = JSON.parse(err.response.data?.body || '{}')?.message || userManagementMsgs.editUserFailure;
						displayToastMsg({
							message: toastMsg,
							variant: 'error'
						});
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				addUser(formPayload)
					.then(res => {
						displayToastMsg({
							message: userManagementMsgs.addUserSuccess,
							variant: 'success'
						});
						navigateUserList();
						trackMixPanelEvent({
							module: 'USER_LOG',
							action: 'USER_ADDED',
							metadata: {
								distinct_id: formPayload.emailId,
								user_id: formPayload.emailId,
								username: formPayload.emailId,							
								userAdded: formPayload.emailId,
								userCountry: formPayload.country,
								userCompany: formPayload.company
							}
						})
					})
					.catch(err => {
						const toastMsg = JSON.parse(err.response.data?.body || '{}')?.message || userManagementMsgs.addUserFailure;
						displayToastMsg({
							message: toastMsg,
							variant: 'error'
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	const handleUserStatus = () => {
		setDisplayConfirmDialog('userStatus');
	};

	const handleResetUser = () => {
		const isTokenActive = checkExpiryAndRedirect();
		if (!isTokenActive) {
			return;
		}
		setLoading(true);
		resetUser({ userName: formData.cognitoUserName })
			.then(res => {
				displayToastMsg({
					message: userManagementMsgs.resetUserSuccess,
					variant: 'success'
				});
				navigateUserList();
			})
			.catch(err => {
				displayToastMsg({
					message: userManagementMsgs.resetUserFailure,
					variant: 'error'
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const isConfirmBtnDisabled = () => {
		if (isEditUser) {
			if (isDataUpdated) {
				const formPayload = constructPayload();
				return !Object.keys(formPayload).length && isAccessControlEqual(editUserObj.accessString, checkboxesChecked);
			}
			return true;
		}

		const isEmailValid = validateEmail(formData.emailId);
		const isNameValid = validateName(formData.fullName);
		const isTitleValid = validateTitle(formData.userAbout);
		const isCountryValid = validateCountry(formData.country);
        const isCompanyValid = validateCompany(formData.company);
		return ![isEmailValid, isNameValid, isTitleValid, isCountryValid, isCompanyValid].every(Boolean)
	}
	const displayConfirmationDialog = () => {
		let content = {}, buttons = [];
		if (displayConfirmDialog === "delete") {
			content = {
				title: `Are you sure ?`,
				message: `Do you really want to delete ${editUserObj?.fullName} ?`,
				additionalMsg: `This Process cannot be undone.`,
			};
			buttons = [
				{ text: "Cancel", onClick: handleCancelDialog, variant: 'secondary'},
				{ text: "Delete", onClick: handleConfirmDialog, variant: 'primary'},
			];
		} else if (displayConfirmDialog === "userStatus") {
			const userSts = formData.enabled ? `deactive` : `activate`;
			content = {
				title: `Are you sure ?`,
				message: `Do you really want to ${userSts} the user ${editUserObj?.fullName} ?`,
				additionalMsg: `This Process cannot be undone.`,
			};
			buttons = [
				{ text: "Cancel", onClick: handleCancelDialog, variant: 'secondary'},
				{ text: "Confirm", onClick: handleConfirmDialog, variant: 'primary'},
			];
		}
		return <CustomConfirmationPopup
			isVisible={displayConfirmDialog}
			content={ content }
			closeDialog={handleCancelDialog}
			buttons={buttons}
		/>
	};

	return (
    <>
      {loading && <LoadingIndicator />}
      <Wrapper className="add-edit-user-container">
        <div className="breadcrum-container">
          <div>
            <BreadcrumbContainer onClick={navigateUserList}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </BreadcrumbContainer>
          </div>
          <div>
            <h2>{isEditUser ? "Edit User" : "Add User"}</h2>
          </div>
        </div>

        <Box
          sx={{ backgroundColor: "white", marginBottom: "20px", width: "80%" }}
        >
          <Box component="form" sx={{ margin: "40px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Full Name (English Name) *"
                  variant="outlined"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  error={!!requiredFieldError.fullName || !!nameError}
                  helperText={requiredFieldError.fullName || nameError}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "green",
                      },
                    },
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          formData.fullName.length > MAX_CHAR_LIMIT
                            ? "red"
                            : "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "green",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 8px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email ID *"
                  variant="outlined"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  error={!!requiredFieldError.emailId || !!emailError}
                  helperText={requiredFieldError.emailId || emailError}
                  disabled={isEditUser}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "green",
                      },
                    },
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          !!emailError ||
                          formData.emailId.length > MAX_CHAR_LIMIT
                            ? "red"
                            : "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "green",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 8px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="country-autocomplete"
                    options={sortedCountryList}
                    value={formData.country}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: { name: "country", value: newValue || "" },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country/Region *"
                        variant="outlined"
                        error={!!requiredFieldError.country || !!countryError}
                        helperText={requiredFieldError.country || countryError}
                        size="medium"
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 8px",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "green",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "green",
                            },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "green",
                          },
                        }}
                      />
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: 280,
                      },
                    }}
                    clearOnEscape
                    autoHighlight
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Company *"
                  variant="outlined"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  error={!!requiredFieldError.company || !!companyError}
                  helperText={requiredFieldError.company || companyError}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "green",
                      },
                    },
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          !!companyError ||
                          formData.company.length > MAX_CHAR_LIMIT
                            ? "red"
                            : "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "green",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 8px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Title/Designation *"
                  variant="outlined"
                  name="userAbout"
                  value={formData.userAbout}
                  onChange={handleChange}
                  error={!!requiredFieldError.userAbout || !!titleError}
                  helperText={requiredFieldError.userAbout || titleError}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "green",
                      },
                    },
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          !!titleError ||
                          formData.userAbout?.length > MAX_CHAR_LIMIT
                            ? "red"
                            : "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "green",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 8px",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h5" sx={{ marginBottom: 2 }}>
                Access Controls
              </Typography>
              <Typography variant="h6" sx={{ paddingBottom: "12px" }}>
                {LOGOS_DIGITAL_ASSETS}
              </Typography>
              {logoDASubFolder.map((subfolder) => {
                return <FormControlLabel
                  control={
                    <Checkbox
                      className="custom-checkbox"
                      name={subfolder}
                      checked={checkboxesChecked.includes(
                        subfolder
                      )}
                      onChange={handleChange}
                      disabled={formData.isAdmin}
                    />
                  }
                  label={subfolder}
                />
              })}
              <Typography
                variant="h6"
                sx={{ paddingBottom: "12px", paddingTop: "20px" }}
              >
                Features
              </Typography>
			  {
				accessFeatureList.map((accessFeature)=>{
					return <FormControlLabel
					control={
					  <Checkbox
						className="custom-checkbox"
						name={accessFeature}
						checked={checkboxesChecked.includes(accessFeature)}
						onChange={handleChange}
						disabled={formData.isAdmin}
					  />
					}
					label={accessFeature}
				  />
				})
			  }
              <Divider sx={{ my: 2 }} />
            </Box>
            {isEditUser ? (
              <>
                <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    User Status : {formData.enabled ? `Active` : "Inactive"} /{" "}
                    {userStatusObj[formData.userStatus]}
                  </Typography>
                  <div className="user-status-container">
                    <div className="user-status-controls">
                      {!editUserObj.isAdmin && (
                        <button
                          className="deactivate-user"
                          type="button"
                          onClick={handleUserStatus}
                        >
                          {formData.enabled
                            ? `DEACTIVATE USER`
                            : `ACTIVATE USER`}
                        </button>
                      )}
                      <button
                        className="reset-password"
                        type="button"
                        onClick={handleResetUser}
                      >
                        RESET PASSWORD
                      </button>
                      <FormControlLabel
                        control={
                          <Switch
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "#147350",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 128, 0, 0.08)",
                                },
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "#147350",
                                },
                            }}
                            name="isAdmin"
                            checked={formData.isAdmin}
                            onChange={handleChange}
                          />
                        }
                        label="Admin"
                      />
                      {!editUserObj.isAdmin && (
                        <button
                          className="delete-user"
                          type="button"
                          onClick={handleDeleteClick}
                        >
                          DELETE USER
                        </button>
                      )}
                    </div>
                  </div>
                  <Divider sx={{ my: 2 }} />
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    User Status
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#147350",
                            "&:hover": {
                              backgroundColor: "rgba(0, 128, 0, 0.08)",
                            },
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#147350",
                            },
                        }}
                        name="isAdmin"
                        checked={formData.isAdmin}
                        onChange={handleChange}
                      />
                    }
                    label="Admin"
                  />
                  <Divider sx={{ my: 2 }} />
                </Box>
              </>
            )}
            <div className="btns-container">
              <button
                className="confirm-btn"
                onClick={handleSubmit}
                disabled={isConfirmBtnDisabled()}
              >
                CONFIRM
              </button>
              <button className="cancel-btn" onClick={navigateUserList}>
                CANCEL
              </button>
            </div>
          </Box>
        </Box>
        {displayConfirmDialog && displayConfirmationDialog()}
      </Wrapper>
    </>
  );
};

export default AddUser;