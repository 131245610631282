import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Headers.scss";
import logo from "../../assets/images/7Eleven-header.png";
import NavItems from "./Navbar/NavItems";
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";
import PersonIcon from "@mui/icons-material/Person";
import {
  redirect_uri,
  login_domain,
  client_id,
} from "../../config/environment";
import { checkFeatureAccess } from "../../Utils/CommonUtils";
import { UserContext, NameContext } from "../../App";
import UserProfile from "../Content/UserProfile";
import { ABOUT_US, CAREERS, CONTACT_US, INTERNATIONAL_PARTNERSHIP, loggedInNavLinks, SUPPLIERS, SUSTAINABILITY } from "../../Constants/Constant";
import Badge from "../Assets/Common/BadgeComponent";
import { useSelector } from "react-redux";

const Header = (props) => {
  const [hamburgerFlag, setHamburgerFlag] = useState(true);
  const { state, dispatch } = useContext(UserContext);

  const redirectProtocol = redirect_uri.includes('localhost:') ? 'http' : 'https';
  const login_redirect = `https://${login_domain}/login?client_id=${client_id}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectProtocol}://${redirect_uri}/assets`;
  const checkAccess = localStorage.getItem("access_token");
  const checkExpiry = localStorage.getItem("expiry_time");
  const {PBNotification= []} = useSelector(state=> state.userManagement);

  if (checkAccess && checkExpiry) {
    dispatch({ type: "USER", payload: true });
  }

  const openHamburger = (
    <AiOutlineMenu
      className="Hamburger"
      size="40px"
      color="black"
      onClick={() => setHamburgerFlag(true)}
    />
  );

  const closeHamburger = (
    <AiOutlineCloseCircle
      className="Hamburger"
      size="40px"
      color="black"
      onClick={() => setHamburgerFlag(false)}
    />
  );

  const handleUserDetailUpdate = () => {
    props.handleUserDetailUpdate();
  };

  const closeHamMenu = () => {
    setHamburgerFlag(false);
  };

  const handleRedirect = (redirectLink) => {
    
    window.location.href = redirectLink;
  };

  return (
    <div className="header-wrapper">
      <div className="top-header">
        <div className="logo-link-wrapper">
          <div className="logo-container" onClick={() => handleRedirect("/")}>
            <img src={logo} alt="Logo" />
          </div>
          <div className="link-container">
            <NavLink to="/about" className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
              {ABOUT_US}
            </NavLink>
            <NavLink to="/int-partnerships" className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
              {INTERNATIONAL_PARTNERSHIP}
            </NavLink>
            <NavLink to="/sustainability" className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
              {SUSTAINABILITY}
            </NavLink>
            <NavLink to="/careers" className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
              {CAREERS}
            </NavLink>
            <NavLink to="/suppliers" className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
              {SUPPLIERS}
            </NavLink>
            <NavLink to="/contact-us" className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
              {CONTACT_US}
            </NavLink>
          </div>
        </div>
        <div className="login-mobnav-wrapper">
          {state ? (
            <UserProfile
              state={state}
              handleUserDetailUpdate={handleUserDetailUpdate}
            />
          ) : (
            <div
              className="login-icon-wrapper hide-MV"
              onClick={() => handleRedirect(login_redirect)}
            >
              <PersonIcon />
              Sign In
            </div>
          )}
          <div className="mobile-navigation">
            {hamburgerFlag ? closeHamburger : openHamburger}
          </div>
        </div>
      </div>

      {state && (
        <div className={PBNotification.length > 0 ? "badge-Padding" : "bottom-header hide-MV"}>
          {loggedInNavLinks.map((link, index) => {
            const displayLink =
              !link.requiresAccessCheck ||
              checkFeatureAccess(link.feature, link.access);
            if (displayLink) {
              return (
                <div className="showBadge">
                {PBNotification.length > 0 && link.text === 'Private Brand Catalog' && (
                  <div className="Badge">
                    <Badge label="New" />
                  </div>
                )}
                <NavLink key={index} to={link.url} className={({ isActive }) => `header-link hide-MV ${isActive ? 'active' : ''}`}>
                  {link.text}
                </NavLink>
                </div>
              );
            }
          })}
        </div>
      )}
      {hamburgerFlag && <NavItems hamClose={closeHamMenu} />}
    </div>
  );
};

export default Header;
